<app-dialog-title displayTitle="Daily Portfolios Estimates" [loading]="loading()" />

<div mat-dialog-content>
  <div class="tw-flex tw-h-full tw-flex-col">
    <div class="tw-mb-1">Select the portfolios to be included in the Daily Portfolios Estimates report</div>

    <app-portfolio-select [control]="portfolioSelectControl" subscriptSizing="dynamic" />

    <div class="abs-scroll-auto-y tw-flex-1">
      @for (portfolios of groupedConfiguration() | keyvalue: sortGroups; track portfolios.key) {
        <div class="abs-subheader tw-mb-1 tw-mt-2 tw-font-medium">{{ portfolios.key }}</div>

        <div cdkDropList (cdkDropListDropped)="drop($event, portfolios.value)" class="drag-list">
          @for (portfolio of portfolios.value; track portfolio.idtPortfolio) {
            <div class="draggable-item tw-flex tw-items-center" cdkDrag @slideIn @shrinkAndFadeOut>
              <div class="tw-w-[10%]">
                <button mat-icon-button (click)="remove(portfolio.idtPortfolio)"><mat-icon fontIcon="mdi-close" /></button>
              </div>
              <div class="tw-w-[50%]">
                <app-portfolio-context-menu [id]="portfolio.idtPortfolio" [displayText]="portfolio.portfolio" class="tw-font-medium" />
              </div>
              <div class="tw-w-[30%]">{{ portfolio.defaultClass }}</div>
              <div class="tw-w-[10%] tw-text-right">
                <mat-icon fontIcon="mdi-drag-horizontal-variant" />
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>
