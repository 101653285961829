import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';

/**
 * Component to show a loading skeleton for lists.
 */
@Component({
    selector: 'app-list-skeleton-loader',
    templateUrl: './list-skeleton-loader.component.html',
    styleUrls: ['./list-skeleton-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListSkeletonLoaderComponent {
  /**
   * The number of lines to show.
   */
  @Input()
  lines: number = 1;

  /**
   * Whether to show a circle (avatar) before the lines.
   */
  @Input()
  showAvatar: boolean = false;

  /**
   * The height of
   */
  @Input()
  height: number = 36;

  /**
   * How many items to replicate.
   */
  items = input(1);

  /**
   * Whether to actually show this, only shows while loading.
   */
  loading = input.required<boolean>();
}
