import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Input, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LogService } from 'app/modules/common/business/log/services/log.service';
import { PortfolioAllocation } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { requiredIfValidator } from 'app/modules/common/framework/validations/validation-utils';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { OpportunityTO } from '../../../model/opportunity.model';

const customPortfolioEntity: PortfolioAllocation = {
  idtPortfolio: 0,
  shortName: 'Custom portfolio',
} as PortfolioAllocation;

@Component({
    selector: 'app-opportunity-select-portfolio',
    templateUrl: './opportunity-select-portfolio.component.html',
    styleUrls: ['./opportunity-select-portfolio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OpportunitySelectPortfolioComponent implements OnInit {
  idtLog = input<number | null>();

  /**
   * List of portfolios with the AUM.
   */
  portfolios = signal<PortfolioAllocation[]>([]);

  /**
   * The selected portfolio.
   */
  selectedPortfolio = signal<PortfolioAllocation | null>(null);

  /**
   * Form group to hold selected values.
   */
  formGroup = this.fb.group({
    idtPortfolio: new FormControl<number | null>(null, Validators.required),
    researchApproval: new FormControl<boolean | null>(
      null,
      requiredIfValidator(() => this.showResearchApproval()),
    ),
    customPortfolio: new FormControl<boolean | null>(null),
  });

  @Output()
  selected = new EventEmitter<boolean>();

  /**
   * The existing opportunity for editions.
   */
  opportunity = input<OpportunityTO>();

  /**
   * Getter to see if currently editing existing opportunity.
   */
  edition = computed(() => {
    return !!this.opportunity();
  });

  /**
   * The selected contact for the opportunity.
   */
  @Input()
  set contact(value: number | null) {
    this.showResearchApproval.set(!value);
    this.formGroup.controls.researchApproval.updateValueAndValidity();
  }

  /**
   * Whether to show the research approval question.
   */
  showResearchApproval = signal(false);

  private allPortfolios = signal<PortfolioAllocation[]>([]);

  constructor(
    private portfolioService: PortfolioService,
    private logService: LogService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.portfolioService
      .findAllocations(DateTime.now(), true)
      .pipe(
        tap((portfolios) => {
          const allPortfolios = portfolios;
          allPortfolios.push(customPortfolioEntity);
          this.allPortfolios.set(allPortfolios);

          // If edition, select the portfolio referenced in the opportunity entity
          if (this.opportunity()) {
            if (this.opportunity()!.customPortfolio) {
              this.selectPortfolio(customPortfolioEntity);
            } else {
              const p = this.allPortfolios().find((pp) => pp.idtPortfolio === this.opportunity()?.idtPortfolio);

              if (p) {
                this.selectPortfolio(p);
              }
            }
          }
        }),
        mergeMap(() => {
          if (this.idtLog()) {
            return this.logService.getDetails(this.idtLog()!);
          }

          return of(null);
        }),
        map((log) => {
          // Filter to show only the portfolios referenced in the log
          if (log && (log.portfolios?.length || log.customPortfolio)) {
            const displayedPortfolios = this.allPortfolios().filter(
              (p) => log!.portfolios?.some((idtPortfolio) => p.idtPortfolio === idtPortfolio) || p.idtPortfolio === this.opportunity()?.idtPortfolio,
            );

            if (log.customPortfolio || this.opportunity()?.customPortfolio) {
              displayedPortfolios.push(customPortfolioEntity);
            }

            return displayedPortfolios;
          }

          return this.allPortfolios();
        }),
        tap((portfolios) => {
          // If only one portfolio referenced in the log, already select it
          if (portfolios.length === 1 && !this.selectedPortfolio) {
            this.selectPortfolio(portfolios[0]);
          }

          this.portfolios.set(portfolios);
        }),
      )
      .subscribe();

    this.formGroup.valueChanges.subscribe(() => {
      this.selected.emit(!this.formGroup.valid);
    });

    if (this.opportunity()) {
      this.formGroup.patchValue(this.opportunity()!);

      if (this.opportunity()!.customPortfolio) {
        this.formGroup.controls.idtPortfolio.setValue(0);
      }
    }
  }

  /**
   * Select the provided portfolio.
   *
   * @param portfolio the portfolio to be selected
   */
  selectPortfolio(portfolio: PortfolioAllocation): void {
    this.selectedPortfolio.set(portfolio);
    this.formGroup.controls.idtPortfolio.setValue(portfolio.idtPortfolio);
  }

  /**
   * Trigger reselecting the portfolio.
   */
  changePortfolio(): void {
    this.selectedPortfolio.set(null);
    this.formGroup.controls.idtPortfolio.setValue(null);
  }

  /**
   * Verifies if the current selected values are valid.
   *
   * @returns true, if the form is valid
   */
  isValid(): boolean {
    return this.formGroup.valid;
  }

  /**
   * Get the values to
   * @returns the currently selected values
   */
  getValues(): any {
    const value = this.formGroup.value;

    if (value.idtPortfolio === 0) {
      value.customPortfolio = true;
      value.idtPortfolio = null;
    }

    return value;
  }
}
