import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileService } from 'app/modules/common/framework/services/file.service';
import { environment } from 'environments/environment';
import { PortfolioDpeConfigurationTO, PortfolioDpeConfigurationViewTO } from '../model/portfolio-dpe.model';

/**
 * Service for DPE related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class PortfolioDpeService {
  constructor(
    private http: HttpClient,
    private fileService: FileService,
  ) {}

  /**
   * Get the current DPE configuration.
   *
   * @returns observable that emits the save configuration
   */
  getDpeConfiguration() {
    return this.http.get<PortfolioDpeConfigurationViewTO[]>(`${environment.apiUrl}/portfolio/dpe`);
  }

  /**
   * Save a new DPE configuration.
   *
   * @param configuration the configuration to save
   * @returns an observable that emits the newly saved configuration
   */
  updateDpeConfiguration(configuration: PortfolioDpeConfigurationTO[]) {
    return this.http.put<PortfolioDpeConfigurationViewTO[]>(`${environment.apiUrl}/portfolio/dpe`, configuration);
  }

  /**
   * Manually trigger DPE email.
   *
   * @returns observable that emits void when the server responds
   */
  triggerDpeEmail() {
    return this.http.post<void>(`${environment.apiUrl}/portfolio/dpe/trigger/email`, {});
  }

  /**
   * Download DPE report file.
   */
  downloadDpeFile() {
    this.http.get<Blob>(`${environment.apiUrl}/portfolio/dpe/file`, { responseType: 'blob' as 'json' }).subscribe((response) => {
      this.fileService.openFile(response, 'preview.pdf');
    });
  }
}
