import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

declare const CKEDITOR: any;

@Component({
    selector: 'app-log-text-editor',
    templateUrl: './log-text-editor.component.html',
    styleUrl: './log-text-editor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LogTextEditorComponent {
  /**
   * The form control with the field value.
   */
  control = input.required<FormControl<string>>();

  /**
   * The editor custom configurations.
   */
  config = {
    allowedContent: true,
    title: false,
    extraPlugins: 'divarea',
    fullPage: false,
    removeButtons:
      'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,Maximize,ShowBlocks,About',
    colorButton_colors: 'fdfd77,63f963,fc7999,72cdfd',
    forcePasteAsPlainText: true,
    disableNativeSpellChecker: false,
    removePlugins: 'contextmenu,liststyle,tabletools,tableselection,tableresize',

    // Redefine the toolbar button groups to avoid splitting lines when unecessary
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'others' },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'blocks', 'align', 'bidi'] },
      { name: 'styles', groups: ['format'] },
      { name: 'colors' },
    ],
    // Disable warning that the version is not the most recent one (it is the most recent open source one)
    versionCheck: false,
  };

  onReady(): void {
    // Hack to make sure allowContent is true globally and wont cause the HTML to be sanitized incorrectly
    CKEDITOR.config.allowedContent = true;

    // HACK to fix conflict with email text editor component
    CKEDITOR.config.removePlugins = 'contextmenu,liststyle,tabletools,tableselection,tableresize';
  }
}
