import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientIcons, ClientTransactionViewTO } from '../../../client/model/client.model';

@Component({
    selector: 'app-transaction-list-item',
    templateUrl: './transaction-list-item.component.html',
    styleUrls: ['./transaction-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionListItemComponent {
  /**
   * The transaction data.
   */
  @Input()
  transaction!: ClientTransactionViewTO;

  /**
   * Whether this is the last item of the list.
   */
  @Input()
  last = false;

  /**
   * Whether to allow selecting items.
   */
  @Input()
  selectable = false;

  /**
   * Emits events when the selectbox is checked/unchecked.
   */
  @Output()
  selectedChange = new EventEmitter<boolean>();

  /**
   * Whether the item is selected.
   */
  @Input()
  selected = false;

  clientIcons = ClientIcons;
}
