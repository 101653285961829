import { Component, Input } from '@angular/core';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { EmailGroupDetailsViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ContactService } from '../../../contact/services/contact.service';
import { MailingEventEnum } from '../../MailingEvent.enum';
import { MailingService } from '../../services/mailing.service';

/**
 * Component to show a list of distribution groups for the given contact, and buttons to add/remove it.
 */
@Component({
    selector: 'app-add-group-list',
    templateUrl: './add-group-list.component.html',
    styleUrls: ['./add-group-list.component.scss'],
    standalone: false
})
export class AddGroupListComponent {
  /**
   * The groups to be displayed.
   */
  @Input()
  groups!: EmailGroupDetailsViewTO[];

  /**
   * The contact for the add/remove actions.
   */
  private _idtContact!: number;

  @Input()
  set idtContact(value: number) {
    this._idtContact = value;

    this.loadContactGroups();
  }

  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * List of groups ids the contact is part of.
   */
  contactGroups: number[] = [];

  /**
   * Groups the contact was removed from.
   */
  oldContactGroups: number[] = [];

  loading = false;

  constructor(
    private mailingService: MailingService,
    private dialogService: DialogService,
    private contactService: ContactService,
    private eventQueueService: EventQueueService
  ) {}

  /**
   * Load the contact groups.
   */
  loadContactGroups(): void {
    const filter = new DataFilter().equals('archived', false, 'boolean');

    this.contactService.findMailingGroupsByIdtContact(this.idtContact, '', filter).subscribe((groups) => {
      this.contactGroups = groups.filter((g) => !g.deleted).map((g) => g.idtEmailGroup);
      this.oldContactGroups = groups
        .filter((g) => g.deleted)
        .map((g) => g.idtEmailGroup)
        .filter((idtEmailGroup) => !this.contactGroups.includes(idtEmailGroup));
    });
  }

  /**
   * Verifies if the current contact is in the provided group.
   *
   * @param idtEmailGroup the group id
   * @returns true, if the contact is a member of the group
   */
  isInGroup(idtEmailGroup: number): boolean {
    return this.contactGroups.includes(idtEmailGroup);
  }

  /**
   * Verifies if the current contact was in the provided group and was later on removed.
   *
   * @param idtEmailGroup the group id
   * @returns true, if the contact was a member of the group
   */
  wasInGroup(idtEmailGroup: number): boolean {
    return this.oldContactGroups.includes(idtEmailGroup);
  }

  /**
   * Verifies if the contact was in any of the shown groups, and was later on removed.
   *
   * @returns true, if the contact was once in any of the shown groups
   */
  wasInAnyGroup(): boolean {
    return !!this.groups?.some((g) => this.oldContactGroups.includes(g.idtEmailGroup));
  }

  /**
   * Add the contact to the provided group.
   *
   * @param idtEmailGroup the group id
   */
  addToGroup(idtEmailGroup: number): void {
    this.loading = true;

    this.mailingService
      .manageContacts(idtEmailGroup, [this.idtContact], [])
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error adding contact to group');
          return throwError(err);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.loadContactGroups();
        this.eventQueueService.dispatch(MailingEventEnum.groupsUpdated);
      });
  }

  /**
   * Remove the contact from the provided group.
   *
   * @param idtEmailGroup the group id
   */
  removeFromGroup(idtEmailGroup: number): void {
    this.loading = true;

    this.mailingService
      .manageContacts(idtEmailGroup, [], [this.idtContact])
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error removing contact from group');
          return throwError(err);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.loadContactGroups();
        this.eventQueueService.dispatch(MailingEventEnum.groupsUpdated);
      });
  }
}
