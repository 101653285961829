import { KeyValue } from '@angular/common';
import { Component, computed, DestroyRef, ElementRef, Input, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { groupBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BasePortfolio, customPortfolioEntity, PortfolioTO } from '../../model/portfolio.model';
import { PortfolioService } from '../../services/portfolio.service';
import { compareProductLine } from '../../utils/portfolio.utils';

/**
 * Component for a portfolio autocomplete form field.
 */
@Component({
    selector: 'app-portfolio-autocomplete',
    templateUrl: './portfolio-autocomplete.component.html',
    styleUrls: ['./portfolio-autocomplete.component.scss'],
    standalone: false
})
export class PortfolioAutocompleteComponent implements OnInit {
  /**
   * The form control holding the selected values.
   */
  @Input()
  control!: FormControl<PortfolioTO[]>;

  /**
   * Whether to include "custom portfolio" option.
   */
  @Input()
  includeCustomPortfolio = false;

  /**
   * Form control for the search input.
   */
  searchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The list of portfolios options.
   */
  portfolios$?: Observable<BasePortfolio[]>;

  /**
   * Element reference to the input field.
   */
  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;

  /**
   * All available portfolios.
   */
  private allPortfolios = signal<PortfolioTO[]>([]);

  /**
   * The portfolios after filtering by the search term.
   */
  filteredPortfolios = signal<PortfolioTO[]>([]);

  /**
   * The portfolios grouped by product line.
   */
  groupedPortfolios = computed(() => {
    return groupBy(this.filteredPortfolios(), (p) => p.productLine);
  });

  constructor(
    private portfolioService: PortfolioService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.portfolioService
      .findAll('shortName,ASC', new DataFilter().equals('inactive', false, 'boolean'))
      .pipe(
        switchMap((allPortfolios) => {
          if (this.includeCustomPortfolio) {
            allPortfolios.push(customPortfolioEntity as PortfolioTO);
          }

          this.allPortfolios.set(allPortfolios);

          return this.searchControl.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            startWith(''),
            debounceTime(500),
            map((searchTerm) => {
              return searchTerm
                ? this.allPortfolios().filter((p) => p.shortName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                : allPortfolios;
            }),
          );
        }),
        tap((ps) => {
          this.filteredPortfolios.set(ps);
        }),
      )
      .subscribe();
  }

  /**
   * Remove a portfolio from the selected list.
   *
   * @param portfolio the portfolio to be removed
   */
  remove(portfolio: PortfolioTO): void {
    this.control.setValue(this.control.value.filter((p) => p.idtPortfolio !== portfolio.idtPortfolio));
  }

  /**
   * Adds a portfolio to the selected list when an option is selected.
   *
   * @param event the event that triggered the selection
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.input.nativeElement.value = '';
    this.searchControl.setValue('');

    // Hack to keep the autocomplete panel open after selecting a tag
    setTimeout(() => {
      this.input.nativeElement.blur();
      this.input.nativeElement.focus();
    });

    if (!this.control.value.some((p) => p.idtPortfolio === event.option.value.idtPortfolio)) {
      this.control.setValue([...this.control.value, event.option.value]);
    }
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioTO[]>, b: KeyValue<string, PortfolioTO[]>): number {
    return compareProductLine(a, b);
  }
}
