import { ChangeDetectionStrategy, Component, Inject, OnInit, effect, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { BasicContact, ContactTypeEnum } from '../../../model/contact.model';
import { NewContact } from '../contact-new-search-existing/contact-new-search-existing.component';

/**
 * Possible steps for contact creation process.
 */
enum CreationState {
  searchExisting,
  basicData,
  associateEmployees,
}

/**
 * Dialog input data object.
 */
interface DialogData {
  name?: string;
  type?: ContactTypeEnum;
  idtMainCompany?: number;
  email?: string;
}

/**
 * The data sent when the dialog is closed.
 */
export interface ContactNewDialogResponse {
  idtContact: number;
  new: boolean;
}

/**
 * Component for dialog to create a new contact.
 */
@Component({
    selector: 'app-contact-new',
    templateUrl: './contact-new.component.html',
    styleUrls: ['./contact-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactNewComponent implements OnInit {
  /**
   * Whether loading/processing data.
   */
  loading = signal(false);

  /**
   * Options for the current state of the dialog.
   */
  CreationState = CreationState;

  /**
   * The current step in the process.
   */
  currentStep = signal<CreationState>(CreationState.searchExisting);

  /**
   * Effect on step changes to send analytics events.
   */
  private stepEffect = effect(() => {
    this.trackerService.event('contact_new', 'step_change', { step: this.currentStep() });
  });

  /**
   * Selected contact type.
   */
  type?: ContactTypeEnum;

  /**
   * The searched name to pre-fill the contact's name.
   */
  initialName: string = '';

  initialEmail?: string;

  /**
   * The id of the main company.
   */
  idtMainCompany?: number;

  /**
   * The created contact data.
   */
  createdContact = signal<BasicContact | null>(null);

  constructor(
    private trackerService: TrackerService,
    private dialogRef: MatDialogRef<ContactNewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
  ) {}

  ngOnInit(): void {
    this.initialName = this.data.name || '';
    this.initialEmail = this.data.email;
    this.type = this.data.type;
    this.idtMainCompany = this.data.idtMainCompany;
  }

  /**
   * After confirming wanting to create a new contact, proceed to next step.
   */
  createNew(data: NewContact): void {
    this.type = data.type;
    this.initialName = data.name;
    this.currentStep.set(CreationState.basicData);
  }

  /**
   * After the contact is created, proceed to next step according to the contact's type.
   *
   * @param contact the saved contact
   */
  afterSaved(contact: BasicContact): void {
    this.createdContact.set(contact);

    if (this.type === ContactTypeEnum.PERSON) {
      this.dialogRef.close({
        idtContact: this.createdContact()?.idtContact,
        new: true,
      });
    } else if (this.type === ContactTypeEnum.COMPANY) {
      this.currentStep.set(CreationState.associateEmployees);
    }
  }

  /**
   * Close the dialog and return the created contact id (if any).
   */
  close() {
    this.dialogRef.close({
      idtContact: this.createdContact()?.idtContact,
      new: !!this.createdContact()?.idtContact,
    });
  }
}
