import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

const entriesMap = new WeakMap();

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (entriesMap.has(entry.target)) {
      const comp = entriesMap.get(entry.target);
      comp.resizeCallback(entry);
    }
  }
});

/**
 * Resize Oberver directive
 */
@Directive({
    selector: '[appResizeObserver]',
    standalone: false
})
export class ResizeObserverDirective implements OnDestroy {
  @Output()
  sizeChanged = new EventEmitter<ResizeObserverEntry>();

  constructor(private el: ElementRef) {
    const target = this.el.nativeElement;
    entriesMap.set(target, this);
    resizeObserver.observe(target);
  }

  /**
   * Emits the new resize entry
   * @param entry
   */
  resizeCallback(entry: ResizeObserverEntry) {
    this.sizeChanged.emit(entry);
  }

  ngOnDestroy() {
    const target = this.el.nativeElement;
    resizeObserver.unobserve(target);
    entriesMap.delete(target);
  }
}
