import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { FileService } from 'app/modules/common/framework/services/file.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { AccountBalanceTO } from '../../../client/model/client-balance.model';
import { AccountBalanceGroupBy } from '../contact-account-balance-list/contact-account-balance-list.component';

/**
 * Component to list the balances of clients related to the contact.
 */
@Component({
    selector: 'app-contact-balance-list',
    templateUrl: './contact-balance-list.component.html',
    styleUrls: ['./contact-balance-list.component.scss'],
    standalone: false
})
export class ContactBalanceListComponent {
  /**
   * The contact id.
   */
  private _idtContact!: number;

  /**
   * Getter for Contact id.
   */
  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * Setter for Contact id.
   * Reset date filter and reload balances.
   */
  @Input()
  set idtContact(value: number) {
    this._idtContact = value;
    this.resetReportingMonth();
    this.loadBalance();
  }

  private _idtClient?: number;

  @Input()
  set idtClient(value: number | undefined) {
    this._idtClient = value;
  }

  get idtClient(): number | undefined {
    return this._idtClient;
  }

  /**
   * Whether the contact to display data from is a consultant.
   */
  private _isConsultant: boolean = false;

  @Input()
  set isConsultant(value: boolean) {
    this._isConsultant = value;

    this.groupBy = this.isConsultant ? AccountBalanceGroupBy.contactName : AccountBalanceGroupBy.portfolioTicker;
  }

  get isConsultant(): boolean {
    return this._isConsultant;
  }

  /**
   * Event emitted when loading data from server.
   */
  @Output()
  loading = new EventEmitter<boolean>(true);

  /**
   * The balance list of accounts.
   */
  balanceData$?: Observable<AccountBalanceTO[]>;

  /**
   * The date of balances.
   */
  reportingMonthDate!: DateTime;

  /**
   * Max allowed date to be selected.
   */
  maxReportingMonthDate = DateTime.now().endOf('month');

  /**
   * The total of balances
   */
  totalBalance?: number;

  /**
   * Indicates which grouping to use.
   */
  groupBy?: AccountBalanceGroupBy;

  /**
   * Whether to include inactive accounts.
   */
  includeInactive = false;

  /**
   * Whether showing in a sidenav.
   */
  @Input()
  sidenav = false;

  /**
   * Whether the data contains estimates.
   */
  hasEstimates = false;

  /**
   * Whether there are missing estimates so the total aum is unreliable.
   */
  missingEstimates = false;

  constructor(
    private contactService: ContactService,
    public authService: AuthService,
    private trackerService: TrackerService,
    private router: Router,
    private fileService: FileService,
    private dialogService: DialogService,
  ) {
    this.resetReportingMonth();
  }

  /**
   * Reset the selected reporting month to the current month.
   * Notice we only change the month after the 5th business day
   */
  private resetReportingMonth(): void {
    this.reportingMonthDate = DateTime.now().minus({ days: 7 }).endOf('month');
  }

  /**
   * Load balance list optionally filtered by active accounts
   */
  private loadBalance(): void {
    this.loading.next(true);
    this.totalBalance = undefined;
    this.missingEstimates = false;

    this.balanceData$ = this.contactService.findClientBalance(this.idtContact, this.reportingMonthDate, this.includeInactive).pipe(
      tap((data) => {
        this.setTotalBalance(data);
        this.hasEstimates = data.some((a) => a.estimated);
        this.missingEstimates = data.some((a) => a.missingEstimate);
      }),
      finalize(() => this.loading.next(false)),
    );
  }

  /**
   * Export the data to an Excel file.
   */
  exportBalanceData(): void {
    this.loading.next(true);
    this.trackerService.event('connected_accounts', 'export');

    this.contactService
      .exportContactBalance(this.idtContact, this.reportingMonthDate, this.includeInactive)
      .pipe(
        finalize(() => {
          this.loading.next(false);
        }),
      )
      .subscribe({
        next: (response: Blob) => {
          this.fileService.openFile(response, this.fileService.generateName('ConnectedAccounts', 'xlsx'));
        },
        error: async (err) => {
          const message = JSON.parse(await err.error.text())?.message;
          this.dialogService.showError(message || 'Error exporting data');
        },
      });
  }

  /**
   * Sets the reporting month date sent from field on header component and load balance related to this date
   * @param date the reporting month date
   */
  handleReportingMonthDate(date: DateTime): void {
    this.trackerService.event('contact', 'change_balance_month', { date: date?.toISODate() });
    this.reportingMonthDate = date;
    this.loadBalance();
  }

  private setTotalBalance(clientBalance: AccountBalanceTO[]): void {
    if (clientBalance?.length) {
      this.totalBalance = clientBalance.map((data) => data.endBalance).reduce((total, currentValue) => total + currentValue, 0);
    }
  }

  /**
   * Toggle displaying inactive accounts.
   */
  toggleInactive(): void {
    this.includeInactive = !this.includeInactive;
    this.loadBalance();
  }

  /**
   * Redirects the user to create a new account.
   */
  createAccount(): void {
    this.trackerService.event('contact', 'create_account');

    if (this.idtClient) {
      this.router.navigate(['', { outlets: { sidenav: ['account', this.idtClient, 'new'] } }]);
    } else {
      this.router.navigate(['/client', 'new'], { queryParams: { idtContact: this.idtContact } });
    }
  }

  /**
   * Show all related accounts in the sidebar.
   */
  expandAccounts(): void {
    this.trackerService.event('contact', 'expand_accounts');
    this.router.navigate(['', { outlets: { sidenav: ['contact', this.idtContact, 'accounts'] } }]);
  }
}
