import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive to add a listener for the scroll and an event when the scroll is reacing the end.
 */
@Directive({
    selector: '[appScrollEnding]',
    standalone: false
})
export class ScrollEndingDirective {
  /**
   * Emits when the scroll is reaching the end.
   */
  @Output()
  scrollEnding = new EventEmitter();

  /**
   * Listener for scroll event, calculates if the scroll is reaching the end and emits if so.
   *
   * @param e the event trieggered
   */
  @HostListener('scroll', ['$event'])
  onScroll(e: any): void {
    const tableViewHeight = e.target.offsetHeight; // container height
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;

    if (scrollLocation > limit) {
      this.scrollEnding.emit();
    }
  }
}
