import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform numbers into its value in millions.
 */
@Pipe({
    name: 'millions',
    standalone: false
})
export class MillionsPipe implements PipeTransform {
  transform(value: number): number;
  transform(value: null | undefined): null;
  transform(value: number | null | undefined): number | null {
    if (value == null) {
      return null;
    }

    return value / 1000000;
  }
}
