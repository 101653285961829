<app-custom-list-item [selected]="selected">
  <div class="tw-flex tw-max-w-full tw-flex-1">
    <div class="column-name tw-flex tw-max-w-[70%] tw-flex-1 tw-flex-col">
      <span>
        <div class="abs-bold tw-flex">
          @if (opportunity.idtContact) {
            <app-contact-context-menu class="abs-text-overflow-ellipsis" [displayText]="opportunity.contactName" [id]="opportunity.idtContact" />
          } @else {
            <span>-</span>
          }
        </div>
      </span>
      <span>
        <div class="abs-color-faded tw-flex">
          @if (opportunity.idtConsultantCompany) {
            <app-contact-context-menu
              class="abs-text-overflow-ellipsis"
              [displayText]="opportunity.consultantCompanyName"
              [id]="opportunity.idtConsultantCompany"
            />
          } @else {
            <span>-</span>
          }
        </div>
      </span>
    </div>
    <div class="tw-flex tw-max-w-[30%] tw-flex-1 tw-flex-col tw-justify-center">
      <div class="tw-flex tw-justify-end">
        <span class="abs-bold">{{ opportunity.size | nearUnitNumber: '0.0-0' }} </span>
      </div>

      <div class="tw-flex tw-justify-end">
        <span class="abs-color-faded abs-text-overflow-ellipsis">
          {{ opportunity.portfolio }}
        </span>
      </div>
    </div>

    <div class="status tw-flex tw-max-w-[60px] tw-flex-1 tw-items-center tw-justify-end">
      <app-opportunity-status [status]="opportunity.status" />
    </div>
  </div>
  <mat-divider />
</app-custom-list-item>
