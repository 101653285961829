import { ChangeDetectionStrategy, Component, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { OutletAwareComponent } from 'app/modules/common/framework/route/outlet-aware.component';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { OpportunityTO } from '../../model/opportunity.model';
import { OpportunityService } from '../../services/opportunity.service';
import { OpportunityProbabilityComponent } from './opportunity-probability/opportunity-probability.component';
import { OpportunityRfpComponent } from './opportunity-rfp/opportunity-rfp.component';
import { OpportunitySelectClientComponent } from './opportunity-select-client/opportunity-select-client.component';
import { OpportunitySelectPortfolioComponent } from './opportunity-select-portfolio/opportunity-select-portfolio.component';

/**
 * Component for adding or editing an opportunity.
 */
@Component({
    selector: 'app-add-or-edit-opportunity',
    templateUrl: './add-or-edit-opportunity.component.html',
    styleUrls: ['./add-or-edit-opportunity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddOrEditOpportunityComponent extends OutletAwareComponent {
  /**
   * The id of the log.
   */
  idtLog = signal<number | null>(null);

  /**
   * Whether it is loading data.
   */
  loading = signal(true);

  /**
   * Whether currently saving.
   */
  saving = signal(false);

  /**
   * Reference to the client selection component.
   */
  @ViewChild('selectClient', { static: false })
  selectClient!: OpportunitySelectClientComponent;

  /**
   * Reference to the probability form component.
   */
  @ViewChild('probability', { static: false })
  probability!: OpportunityProbabilityComponent;

  /**
   * The portfolio section component.
   */
  @ViewChild('portfolioSection', { static: false })
  portfolioSection!: OpportunitySelectPortfolioComponent;

  /**
   * Reference to the RFP section component.
   */
  @ViewChild('rfpComponent', { static: false })
  rfpComponent!: OpportunityRfpComponent;

  /**
   * Flag if currently selecting the client data.
   */
  selectingClient = signal<boolean>(true);

  /**
   * Flag if currently selecting the portfolio data.
   */
  selectingPortfolio = signal<boolean>(true);

  /**
   * The id of existing opportunity for editions.
   */
  idtOpportunity?: number;

  /**
   * The existing opportunity for editions.
   */
  opportunity?: OpportunityTO;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    private opportunityService: OpportunityService,
    private dialogService: DialogService,
  ) {
    super(route);

    this.route.paramMap.subscribe((params) => {
      const idtLogString = params.get('idtLog');
      this.idtLog.set(idtLogString ? Number(idtLogString) : null);

      const idtOpportunityString = params.get('idtOpportunity');

      if (idtOpportunityString) {
        this.idtOpportunity = Number(idtOpportunityString);
        this.loadOpportunity();
      } else {
        this.loading.set(false);
      }
    });
  }

  /**
   * Load an existing opportunity for edition.
   */
  loadOpportunity(): void {
    if (this.idtOpportunity) {
      this.opportunityService.getOne(this.idtOpportunity).subscribe((opp) => {
        this.opportunity = opp;
        this.loading.set(false);
      });
    }
  }

  /**
   * Go back to the opportunities list.
   */
  goBack(): void {
    if (this.idtLog() != null) {
      this.router.navigate(['../../post'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  /**
   * Saves the opportunity. If closing the opportunity show confirmation dialog before.
   */
  save(): void {
    if (!this.saving() && this.portfolioSection.isValid() && this.probability.isValid()) {
      this.saving.set(true);

      let obs: Observable<OpportunityTO>;

      if (this.idtOpportunity) {
        obs = this.opportunityService.update(this.idtOpportunity, {
          idtLog: this.idtLog(),
          idtOpportunity: this.opportunity?.idtOpportunity,
          ...this.portfolioSection.getValues(),
          ...this.selectClient.getValues(),
          ...this.probability.getValues(),
          ...this.rfpComponent.getValues(),
        });
      } else {
        obs = this.opportunityService.create({
          idtLog: this.idtLog(),
          ...this.portfolioSection.getValues(),
          ...this.selectClient.getValues(),
          ...this.probability.getValues(),
          ...this.rfpComponent.getValues(),
        });
      }

      obs
        .pipe(
          finalize(() => {
            this.saving.set(false);
          }),
          catchError((err) => {
            this.dialogService.showError(err.error?.message || 'Error saving the opportunity');
            return throwError(() => err);
          }),
          tap(() => {
            this.dialogService.showSuccess('Opportunity saved successfully');
            this.goBack();
          }),
        )
        .subscribe();
    }
  }
}
