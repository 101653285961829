import { Pipe, PipeTransform } from '@angular/core';

/**
 * When the value is empty it returns 'N/A'
 */
@Pipe({
    name: 'emptyValue',
    standalone: false
})
export class EmptyValuePipe implements PipeTransform {
  transform(value?: string | number | null, defaultValue: string = 'N/A'): string | number {
    if (value != null && value !== '') {
      return value;
    }

    return defaultValue;
  }
}
