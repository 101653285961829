@if (hasEstimates) {
  <app-alert-box [showIcon]="false">Contains estimated returns, denoted with an *</app-alert-box>
}

<div class="header tw-flex !tw-pr-[6px]" matSort (matSortChange)="sortChange($event)" [ngClass]="{ grouped: !!groupBy }">
  <div class="abs-container-gt-xs tw-flex tw-flex-1"></div>
  <div class="data tw-flex tw-items-end tw-justify-start">
    <div class="date" mat-sort-header="referenceDate">AS OF</div>
    <div class="balance" mat-sort-header="endBalance" arrowPosition="before">AUM</div>
    <div class="xtd tw-flex-1" mat-sort-header="mtd" arrowPosition="before">MTD</div>
    <div class="xtd tw-flex-1" mat-sort-header="qtd" arrowPosition="before">QTD</div>
    <div class="xtd tw-flex-1" mat-sort-header="ytd" arrowPosition="before">YTD</div>
    <div class="xtd tw-flex-1" matTooltip="Inception to date (Annualized)" mat-sort-header="itd" arrowPosition="before">
      <span>ITD</span>
      <sup>A</sup>
    </div>
    <div class="xtd tw-flex-1" matTooltip="Inception to date (Cumulative)" mat-sort-header="itdCumulative" arrowPosition="before">
      <span>ITD</span>
      <sup>C</sup>
    </div>
    <div class="tw-w-[18px]"></div>
  </div>
</div>

<mat-divider />

@if (groupBy) {
  <app-scrollable class="tw-flex tw-flex-1">
    <mat-accordion multi class="tw-flex-1" displayMode="flat" #accordion="matAccordion">
      @for (accounts of groupedAccounts | keyvalue: sortGroups; track accounts.key) {
        <mat-expansion-panel class="abs-nopadding-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="abs-overflow-hidden">
              <div class="abs-bold abs-overflow-hidden tw-flex tw-flex-1">
                @if (groupBy === 'contactName') {
                  <app-contact-context-menu [displayText]="accounts.key" [id]="accounts.value.accounts[0].idtContact" />
                } @else if (groupBy === 'portfolioTicker') {
                  <app-portfolio-context-menu [displayText]="accounts.key" [id]="accounts.value.accounts[0].idtPortfolio" />
                }
              </div>
              <div>
                <app-positive-negative [value]="accounts.value.balance">
                  @if (!isMissingEstimates(accounts.value)) {
                    <span class="abs-container-xs">{{ accounts.value.balance | nearUnitNumber: '0.0-0' }}</span>
                    <span class="abs-container-gt-xs">{{ accounts.value.balance | number: '0.0-0' }}</span>
                    @if (accounts.value.hasEstimates) {
                      <span>*</span>
                    }
                  } @else {
                    <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing estimates in ABSolute" />
                  }
                </app-positive-negative>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="abs-margin-double-bottom">
              @for (account of accounts.value.accounts; track account.idtAccount) {
                <app-account-performance-list-item [groupBy]="groupBy" [account]="account" [refDate]="refDate" />
              }
            </div>
          </ng-template>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </app-scrollable>
} @else {
  <app-scrollable class="tw-flex tw-flex-1">
    @for (account of accounts; track trackAccount($index, account)) {
      <app-account-performance-list-item [account]="account" [refDate]="refDate" [isConsultant]="isConsultant" />
    }
  </app-scrollable>
}
