import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProgressBarComponent {
  /**
   * Whether is it currently procesisng and should show the progress bar indicator.
   */
  loading = input<boolean>(false);
}
