/**
 * Represents an user in the system.
 */
export interface User extends BasicUser {
  inactive: boolean;
}

export interface BasicUser {
  idtUser: number;
  initials: string;
  name: string;
}

export interface UserPreferenceTO {
  idtUser: number;
  dpeDeliveryOption: DpeDeliveryOptionsEnum;
}

export enum DpeDeliveryOptionsEnum {
  EMAIL = 'EMAIL',
  NONE = 'NONE',
}
