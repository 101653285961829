import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailHistoryContactDetails } from 'app/modules/routes/mailing/model/mailing.model';
import { EmailAttachmentService } from 'app/modules/routes/mailing/services/email-attachment.service';
import { EmailHistoryService } from 'app/modules/routes/mailing/services/email-history.service';
import { Observable } from 'rxjs';

/**
 * Component to display a message.
 */
@Component({
    selector: 'app-mail-history-contact-details',
    templateUrl: './mail-history-contact-details.component.html',
    styleUrls: ['./mail-history-contact-details.component.scss'],
    standalone: false
})
export class MailHistoryContactDetailsComponent {
  /**
   * The id of the history contact entry.
   */
  @Input()
  set idtEmailHistoryContact(value: number) {
    this.details$ = this.emailHistoryService.getContactDetails(value);
  }

  /**
   * The details of the email wrapped in an observable.
   */
  details$?: Observable<EmailHistoryContactDetails>;

  constructor(
    private route: ActivatedRoute,
    private emailHistoryService: EmailHistoryService,
    private emailAttachmentService: EmailAttachmentService
  ) {}

  /**
   * Download an attachment file.
   *
   * @param idtEmailAttachment the id of the attachment
   */
  download(idtEmailAttachment: number): void {
    this.emailAttachmentService.download(idtEmailAttachment);
  }
}
