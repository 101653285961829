import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PortfolioAllocation } from '../../../portfolio/model/portfolio-allocation.model';
import { OpportunitiesByConsultantTO } from '../../model/opportunity.model';
import { OpportunityService } from '../../services/opportunity.service';

/**
 * Component for the list of opportunities per consultant.
 */
@Component({
    selector: 'app-opportunity-list-consultant',
    templateUrl: './opportunity-list-consultant.component.html',
    styleUrls: ['./opportunity-list-consultant.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OpportunityListConsultantComponent implements OnInit {
  /**
   * The selected portfolio to filter for.
   */
  private _portfolio?: PortfolioAllocation;

  get portfolio(): PortfolioAllocation | undefined {
    return this._portfolio;
  }

  @Input()
  set portfolio(value: PortfolioAllocation | undefined) {
    this._portfolio = value;
    this.getOpportunities();
  }

  loading = signal(false);

  /**
   * Whehter to show selection filter chips.
   */
  @Input()
  showChip = false;

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  portfolioRemoved = new EventEmitter<void>();

  opportunities$?: Observable<OpportunitiesByConsultantTO[]>;

  /**
   * The selected opportunity.
   */
  @Input()
  selectedConsultant?: OpportunitiesByConsultantTO;

  /**
   * Emit event when an opportunity is selected.
   */
  @Output()
  selectConsultant = new EventEmitter<OpportunitiesByConsultantTO>();

  constructor(private opportunityService: OpportunityService) {}

  ngOnInit(): void {
    this.getOpportunities();
  }

  /**
   * Get the list of opportunities by consultant.
   */
  private getOpportunities(): void {
    this.loading.set(true);

    this.opportunities$ = this.opportunityService.getByConsultant(this.portfolio?.idtPortfolio).pipe(
      finalize(() => {
        this.loading.set(false);
      })
    );
  }

  /**
   * Remove a portfolio selection.
   */
  removePortfolio(): void {
    this.portfolioRemoved.emit();
  }

  /**
   * Select an oppotunity consultant.
   *
   * @param opportunity the selected opportunity
   */
  select(opportunity?: OpportunitiesByConsultantTO): void {
    if (opportunity?.idtContact === this.selectedConsultant?.idtContact) {
      this.selectConsultant.emit(undefined);
    } else {
      this.selectConsultant.emit(opportunity);
    }
  }
}
