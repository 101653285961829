import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TrackerService } from './tracker.service';

/**
 * Tracks service worker update events.
 * Based on snippet from https://angular.io/guide/service-worker-communications
 */
@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerTrackerService {
  /**
   * Local storage key to save/retrieve the current version of the app.
   */
  static readonly APP_VERSION_KEY = 'sw_version';

  constructor(
    private swUpdate: SwUpdate,
    private trackerService: TrackerService,
    private snackBar: MatSnackBar,
  ) {}

  start(): void {
    if (!this.swUpdate.isEnabled) {
      this.trackerService.event('service_worker', 'disabled');
    } else {
      this.swUpdate.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'NO_NEW_VERSION_DETECTED':
            console.log(`No new version detected, running version: ${evt.version.hash}`);
            localStorage.setItem(ServiceWorkerTrackerService.APP_VERSION_KEY, evt.version.hash);
            break;
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            this.trackerService.event('service_worker', 'version_detected', { version: evt.version.hash });
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.trackerService.event('service_worker', 'version_ready', {
              current: evt.currentVersion.hash,
              version: evt.latestVersion.hash,
            });

            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            this.trackerService.event('service_worker', 'version_installation_failed', {
              hash: evt.version.hash,
              error: evt.error,
            });
            break;
        }
      });

      // Check for verison updates
      this.checkForUpdates();

      // Whenever the application gain focus, check if a new version is available, if yes, show snackbar to inform and allow to refresh the page.
      window.addEventListener('focus', () => {
        this.checkForUpdates();
      });
    }
  }

  /**
   * Check for a new app version.
   */
  private async checkForUpdates() {
    const newVersion = await this.swUpdate.checkForUpdate();

    if (newVersion) {
      // Open snackbar to notify the user to reload the page
      this.snackBar
        .open('A new version of ARMS is available. Refresh to update', 'Refresh')
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });
    }
  }
}
