import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Observable, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { ContactIndexedDTO, ContactTypeEnum } from '../../../model/contact.model';
import { ContactService } from '../../../services/contact.service';
import { ContactNewComponent } from '../contact-new/contact-new.component';

export interface NewContact {
  name: string;
  type: ContactTypeEnum;
}

@Component({
    selector: 'app-contact-new-search-existing',
    templateUrl: './contact-new-search-existing.component.html',
    styleUrl: './contact-new-search-existing.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactNewSearchExistingComponent implements OnInit, AfterViewInit {
  /**
   * The contact lists to be displayed as options to avoid creating duplicated ones.
   */
  contactData$?: Observable<ContactIndexedDTO[]>;

  /**
   * Field used to search for conctact
   */
  searchField = new FormControl<string>('', { nonNullable: true });

  /**
   * Options for contact types.
   */
  ContactTypeEnum = ContactTypeEnum;

  /**
   * Emits true, when the server is processing a request.
   */
  @Output()
  processing = new EventEmitter<boolean>();

  /**
   * Emits event when a new contact option is selected, emitting the type of contact to be created.
   */
  @Output()
  newSelected = new EventEmitter<NewContact>();

  /**
   * Initial value to search for.
   */
  initialSearch = input<string>();

  /**
   * Optional type to only allow creating a person or company.
   */
  type = input<ContactTypeEnum>();

  /**
   * Whether to show buttons to create new contacts.
   */
  showNew = input(true);

  /**
   * Id to ignore in the results.
   */
  ignoreId = input<number | null>(null);

  constructor(
    private contactService: ContactService,
    private destroyRef: DestroyRef,
    private trackerService: TrackerService,
    private dialogRef: MatDialogRef<ContactNewComponent>,
  ) {}

  ngOnInit(): void {
    this.contactData$ = this.searchField.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => {
        this.processing.emit(true);
      }),
      switchMap((term) => {
        if (term.length > 2) {
          this.trackerService.event('contact_new', 'search_existing', { term });
          return this.contactService.searchIndexedContacts(term, this.type());
        }

        return of([]);
      }),
      map((contacts) => {
        return contacts.filter((c) => c.idtContact !== this.ignoreId());
      }),
      tap(() => {
        this.processing.emit(false);
      }),
    );
  }

  /**
   * Angular lifecycle hook method called after the view is completely initialized.
   * Handles initial searhc field value, set the value so it triggers the search.
   */
  ngAfterViewInit(): void {
    if (this.initialSearch()) {
      this.searchField.setValue(this.initialSearch()!);
    }
  }

  /**
   * After confirming wanting to create a new contact, proceed to next step.
   */
  createNew(type: ContactTypeEnum): void {
    this.trackerService.event('contact_new', 'click_new', { type });

    this.processing.emit(false);
    this.newSelected.emit({
      type,
      name: this.searchField.value,
    });
  }

  /**
   * Close the dialog returning the provided contact id.
   *
   * @param idtContact the selected contact
   */
  closeDialog(idtContact: number) {
    this.dialogRef.close({
      idtContact: idtContact,
      new: false,
    });
  }
}
