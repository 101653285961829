<div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
  <div class="image-spacer">
    @if (image$ | async; as url) {
      <img matListItemAvatar [src]="url" (click)="hasImage && openZoomDialog($event, url)" [ngClass]="{ 'abs-clickable': hasImage }" />
    }
  </div>

  @if (showEdit) {
    <input #pictureInput type="file" style="display: none" (change)="selectPicture($event)" accept="image/*" />
    <button mat-icon-button color="primary" (click)="pictureInput.click()">
      <mat-icon fontIcon="mdi-pencil" />
    </button>
  }
</div>
