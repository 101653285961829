import { Component, HostBinding } from '@angular/core';

/**
 * Component to add a scrollable div with good performance.
 */
@Component({
    selector: 'app-scrollable',
    templateUrl: './scrollable.component.html',
    styleUrls: ['./scrollable.component.scss'],
    standalone: false
})
export class ScrollableComponent {
  /**
   * Add scrollable classes to the host element.
   */
  @HostBinding('class')
  clazz = 'cdk-virtual-scroll-orientation-vertical';
}
