<app-custom-list-item [selected]="idtPortfolio() === portfolio().idtPortfolio" (click)="select(portfolio())">
  <div class="tw-flex tw-flex-1" [ngClass]="{ 'tw-pl-1': !ungrouped() }">
    <div class="tw-flex tw-flex-1 tw-flex-col">
      <app-portfolio-context-menu class="abs-bold" [id]="portfolio().idtPortfolio" [displayText]="portfolio().shortName" />
      <span class="abs-color-faded" [ngPlural]="portfolio().portfolioClients">
        <ng-template ngPluralCase="=1">1 client</ng-template>
        <ng-template ngPluralCase="other">{{ portfolio().portfolioClients }} clients</ng-template>
      </span>
    </div>
    <div class="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
      @if (portfolio().missingReturns) {
        <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing returns in ABSolute" />
      } @else {
        <span> {{ portfolio().totalPortfolio | currency }}{{ portfolio().estimated ? '*' : '' }} </span>
        <span class="abs-color-faded">{{ portfolio().portfolioAllocation / 100 | percent: '.2' }}</span>
      }
    </div>
  </div>
  <mat-divider />
</app-custom-list-item>
