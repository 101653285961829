import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, OIDC_DEFAULT_SCOPES, PublicClientApplication } from '@azure/msal-browser';
import { NavigationHistoryService } from 'app/modules/common/framework/services/navigation-history.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { ApplicationUser } from '../../../../routes/login/model/application-user';
import { TrackerService } from '../../tracker/services/tracker.service';

const LOGIN_TYPE = 'login_type';
const LOGIN_TYPE_EMAIL = 'email';
const LOGIN_TYPE_AAD = 'aad';
const ACCESS_TOKEN = 'access_token';

export interface LoginResult {
  token: string;
}

/**
 * Service for handling authentication functions.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Local storage key to store used AAD during login.
   */
  private static readonly LOGIN_TYPE_STORAGE_KEY = 'aad_login_source';

  baseUrl = `${environment.apiUrl}/auth`;

  /**
   * The logged in user.
   */
  private user: ApplicationUser | null = null;

  private userSubject = new BehaviorSubject<ApplicationUser | null>(null);

  user$: Observable<ApplicationUser | null> = this.userSubject.asObservable();

  /**
   * Observable to identify if user is logged in.
   */
  loggedIn$: Observable<boolean> = this.user$.pipe(map((user) => !!user));

  /**
   * Instance of the AAD service for ABS login.
   */
  private mainMsalInstance = this.msalAuthService.instance;

  /**
   * Instance of the AAD service for Inno login.
   */
  private innoMsalInstance = new PublicClientApplication({
    auth: {
      clientId: environment.innoAadClientId,
      authority: `https://login.microsoftonline.com/${environment.innoAadTenantId}`,
      redirectUri: environment.aadRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  });

  constructor(
    private router: Router,
    private http: HttpClient,
    private msalAuthService: MsalService,
    private navigationHistoryService: NavigationHistoryService,
    private trackerService: TrackerService,
  ) {}

  /**
   * Returns basic logged user info from server
   */
  getUserInfo(): Observable<ApplicationUser> {
    if (this.user) {
      return of(this.user);
    }

    return this.http.get<ApplicationUser>(this.baseUrl + '/me', { withCredentials: true }).pipe(tap((user) => (this.user = user)));
  }

  /**
   * Check if user has a permission.
   * @param name permission name.
   * @returns true if user has permission.
   */
  hasPermission(name: string): boolean {
    return !!this.user?.permissions.includes(name);
  }

  /**
   * Login user in the API. Get the access token from API server
   * and set the token in the local storage and the user data.
   *
   * @param username
   * @param password
   */
  login(username: string, password: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(this.baseUrl, { username, password }).pipe(
      map((login) => {
        localStorage.setItem(ACCESS_TOKEN, login.token);
        localStorage.setItem(LOGIN_TYPE, LOGIN_TYPE_EMAIL);
        this.getUserInfo().subscribe((user) => this.userSubject.next(user));
        return login;
      }),
    );
  }

  /**
   * Initializes the login using Azure active directory.
   *
   * @returns observable that emits when the login proccess finishes
   */
  initializeLoginAAD(inno: boolean): Observable<LoginResult> {
    localStorage.setItem(AuthService.LOGIN_TYPE_STORAGE_KEY, inno ? 'inno' : 'abs');
    this.setInstanceFromLoginType();

    return this.msalAuthService.initialize().pipe(
      switchMap(() => {
        return this.msalAuthService.loginPopup({
          scopes: OIDC_DEFAULT_SCOPES,
        });
      }),
      switchMap((result) => {
        this.checkAndSetActiveAccount();

        const { idToken, accessToken, account } = result;
        const email = account.username;
        return this.loginAAD(email, idToken, accessToken, inno);
      }),
    );
  }

  /**
   * Sets the active account for the msal service (used when refreshing the token).
   */
  private checkAndSetActiveAccount() {
    const activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * Set the proper instance to use in the msalAuthService depending if login was performed using Inno or ABS.
   */
  private setInstanceFromLoginType() {
    const loginType = localStorage.getItem(AuthService.LOGIN_TYPE_STORAGE_KEY);
    this.msalAuthService.instance = loginType === 'inno' ? this.innoMsalInstance : this.mainMsalInstance;
  }

  /**
   * Refreshes the user's token.
   *
   * @returns observable that emits when the login proccess finishes
   */
  refreshLogin(): Observable<LoginResult> {
    this.setInstanceFromLoginType();
    this.checkAndSetActiveAccount();

    return this.msalAuthService.initialize().pipe(
      switchMap(() => {
        return this.msalAuthService.acquireTokenSilent({
          scopes: OIDC_DEFAULT_SCOPES,
        });
      }),
      catchError((error) => {
        // Track unexpected errors
        this.trackerService.event('login', 'refresh_error', {
          message: error.errorMessage,
        });

        //Acquire token silent failure, send an interactive request
        return this.msalAuthService.acquireTokenPopup({
          scopes: OIDC_DEFAULT_SCOPES,
        });
      }),
      catchError((error) => {
        // Track unexpected errors
        this.trackerService.event('login', 'refresh_error_popup', {
          message: error.errorMessage,
        });

        return throwError(() => error);
      }),
      mergeMap((result) => {
        const { accessToken, account } = result;
        const email = account.username;
        return this.refreshLoginAAD(email, accessToken);
      }),
    );
  }

  /**
   * Login user in the API with a Azure Active Directory IdToken. Get the access token from API server
   * and set the token in the local storage and the user data.
   *
   * @param email the user's email
   * @param idToken the microsoft id token
   * @param accessToken the microsoft access token
   * @param inno whether to use Inno login
   */
  loginAAD(email: string, idToken: string, accessToken: string, inno: boolean): Observable<LoginResult> {
    return this.http
      .post<LoginResult>(
        this.baseUrl + '/aad',
        { email, idToken, accessToken },
        {
          params: {
            inno,
          },
        },
      )
      .pipe(
        map((login) => {
          localStorage.setItem(ACCESS_TOKEN, login.token);
          localStorage.setItem(LOGIN_TYPE, LOGIN_TYPE_AAD);
          this.getUserInfo().subscribe((user) => this.userSubject.next(user));
          return login;
        }),
      );
  }

  /**
   * Refreshes the user's application token.
   *
   * @param email the user's email
   * @param accessToken the AAD access token
   * @returns an observable that emits the new access token when the server responds
   */
  private refreshLoginAAD(email: string, accessToken: string): Observable<LoginResult> {
    return this.http
      .post<LoginResult>(
        this.baseUrl + '/aad/refresh',
        { email, accessToken },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((login) => {
          localStorage.setItem(ACCESS_TOKEN, login.token);
          this.getUserInfo().subscribe((user) => this.userSubject.next(user));
          return login;
        }),
      );
  }

  /**
   * Logout: clear data from local storage, clear user
   * data and navigate back to login page.
   */
  logout(redirectParams?: object): void {
    localStorage.removeItem(ACCESS_TOKEN);
    this.user = null;
    this.userSubject.next(null);
    localStorage.removeItem(LOGIN_TYPE);
    this.navigationHistoryService.clear();

    // Do not redirect if already at login page
    if (!this.router.url.startsWith('/login')) {
      this.router.navigate([{ outlets: { sidenav: null, primary: ['login'] } }], redirectParams);
    }
  }

  /**
   * Returns the access token from local storage.
   */
  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  /**
   * Verify if the user is logged in.
   */
  isLoggedIn(): Observable<boolean> {
    if (this.user) {
      return of(!!this.user);
    }

    const token = this.getAccessToken();

    if (token) {
      return this.getUserInfo().pipe(
        tap((user) => {
          this.userSubject.next(user);
        }),
        map((user) => !!user),
        catchError(() => of(false)),
      );
    }

    return of(false);
  }
}
