<div class="tab-tools tw-mr-1 tw-flex tw-items-center tw-justify-end">
  <button mat-icon-button *hasPermission="'CREATE_ACCOUNT'" (click)="createAccount()" matTooltip="New account">
    <mat-icon fontIcon="mdi-plus-circle" color="primary" />
  </button>

  <button mat-icon-button (click)="exportBalanceData()" matTooltip="Export">
    <mat-icon fontIcon="mdi-file-download" color="primary" />
  </button>

  <button mat-icon-button matTooltip="Filter" [matMenuTriggerFor]="menu">
    <mat-icon fontIcon="mdi-filter-variant" color="primary" />
  </button>

  <mat-menu #menu>
    <button mat-menu-item (click)="toggleInactive()">
      {{ includeInactive ? 'Hide inactive accounts' : 'Display inactive accounts' }}
    </button>
  </mat-menu>

  @if (!sidenav) {
    <button mat-icon-button color="primary" (click)="expandAccounts()" matTooltip="Expand">
      <mat-icon fontIcon="mdi-book-open" />
    </button>
  }
</div>

<app-client-balance-header
  [reportingMonthDate]="reportingMonthDate"
  [maxReportingMonthDate]="maxReportingMonthDate"
  (emitterReportingMonthDate)="handleReportingMonthDate($event)"
  [(groupBy)]="groupBy"
  [isConsultant]="isConsultant"
  class="abs-margin-bottom abs-margin-horizontal abs-margin-top"
  [totalAum]="totalBalance"
  [hasEstimates]="hasEstimates"
  [missingEstimates]="missingEstimates"
/>
@if (balanceData$ | async; as balanceData) {
  @if (balanceData.length > 0) {
    <div class="tw-flex tw-flex-1 tw-flex-col">
      <app-contact-account-balance-list
        [balanceData]="balanceData"
        [(groupBy)]="groupBy"
        class="tw-flex tw-flex-1"
        [refDate]="reportingMonthDate!"
        [isConsultant]="isConsultant"
      />
    </div>
  } @else {
    <app-no-data [entity]="'account'" />
  }
}
