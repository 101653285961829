<h1 mat-dialog-title>Override global settings</h1>
<div mat-dialog-content>
  <div class="abs-margin-bottom">
    @if (account$ | async; as account) {
      <div>
        <app-output label="Client" class="abs-margin-bottom">
          {{ account.client?.name }}
        </app-output>
        <app-output label="Portfolio">
          {{ account.portfolio?.shortName }}
        </app-output>
      </div>
    }
  </div>

  <h2>Set person</h2>
  <form [formGroup]="formGroup" fxLayout="column" (submit)="formGroup.valid && save()">
    <div fxLayout="row" fxLayoutGap="8px">
      <mat-form-field fxFlex>
        <mat-label>Primary</mat-label>
        <mat-select formControlName="idtUserPrimary">
          <mat-option [value]="null">None</mat-option>
          @for (user of users; track user) {
            <mat-option [value]="user.idtUser">{{ user.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="120px">
        <mat-label>Percent</mat-label>
        <input
          matInput
          type="number"
          class="align-end without-controls"
          placeholder="0"
          formControlName="primaryPercent"
          [errorStateMatcher]="sumPercentMatcher"
          />
          <span matTextSuffix>%</span>
          <mat-error>Invalid percentages</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="8px">
        <mat-form-field fxFlex>
          <mat-label>Primary 2</mat-label>
          <mat-select formControlName="idtUserPrimary2">
            <mat-option [value]="null">None</mat-option>
            @for (user of users; track user) {
              <mat-option [value]="user.idtUser">{{ user.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="120px">
          <mat-label>Percent</mat-label>
          <input
            matInput
            type="number"
            class="align-end without-controls"
            placeholder="0"
            formControlName="primary2Percent"
            [errorStateMatcher]="sumPercentMatcher"
            />
            <span matTextSuffix>%</span>
            <mat-error>Invalid percentages</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex>
            <mat-label>Backup</mat-label>
            <mat-select formControlName="idtUserBackup">
              <mat-option [value]="null">None</mat-option>
              @for (user of users; track user) {
                <mat-option [value]="user.idtUser">{{ user.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="120px">
            <mat-label>Percent</mat-label>
            <input matInput type="number" class="align-end without-controls" placeholder="0" formControlName="backupPercent" />
            <span matTextSuffix>%</span>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex>
            <mat-label>Maintenance</mat-label>
            <mat-select formControlName="idtUserMaintenance">
              <mat-option [value]="null">None</mat-option>
              @for (user of users; track user) {
                <mat-option [value]="user.idtUser">{{ user.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <div fxFlex="120px"></div>
        </div>

        <mat-divider />

        <h2 class="abs-margin-top">Set primary override</h2>

        <div fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="column">
            <mat-form-field style="width: 100px">
              <mat-label>1st</mat-label>
              <input matInput type="number" class="align-end without-controls" placeholder="0" formControlName="primaryOverrideFirstYear" />
              <span matTextSuffix>%</span>
            </mat-form-field>

            <mat-form-field style="width: 100px">
              <mat-label>2nd</mat-label>
              <input matInput type="number" class="align-end without-controls" placeholder="0" formControlName="primaryOverrideSecondYear" />
              <span matTextSuffix>%</span>
            </mat-form-field>

            <mat-form-field style="width: 100px">
              <mat-label>3rd+</mat-label>
              <input matInput type="number" class="align-end without-controls" placeholder="0" formControlName="primaryOverrideThirdYear" />
              <span matTextSuffix>%</span>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex>
            <mat-form-field fxFlex class="stretch-height">
              <mat-label>Notes</mat-label>

              <textarea matInput formControlName="comments"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between" class="abs-padding abs-margin-top">
          <button mat-button mat-dialog-close color="warn" type="button">Cancel</button>
          <button mat-raised-button type="submit" color="primary">Save</button>
        </div>
      </form>
    </div>
