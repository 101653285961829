import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { isHtmlString } from 'app/modules/common/framework/utils/string-utils';
import { BasicContact } from '../../../contact/model/contact.model';
import { LogDetailViewTO } from '../../model/log.model';

/**
 * Component to show the details of log
 */
@Component({
    selector: 'app-log-details',
    templateUrl: './log-details.component.html',
    styleUrls: ['./log-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LogDetailsComponent implements AfterViewInit {
  @Input()
  toolbarColor = '';

  /**
   * Event emitter called when the back button is clicked.
   * If not observed, the back button isn't displayed.
   */
  @Output()
  back = new EventEmitter<void>();

  /**
   * Event emitter called when the close button is clicked.
   * If not observed, the close button isn't displayed.
   */
  @Output()
  closed = new EventEmitter<void>();

  /**
   * Whether the content is expanded.
   */
  contentExpanded = signal(false);

  /**
   * The full log data.
   */
  log = input.required<LogDetailViewTO>();

  /**
   * Whether the log is pure text.
   */
  pureText = computed(() => {
    return !isHtmlString(this.log().bodyHtmlContent);
  });

  /**
   * Whether this component is rendered in a sidenav or not. Changes the route when clicking to edit the log.
   */
  sidenav = false;

  constructor(
    private trackerService: TrackerService,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.sidenav = !!this.el.nativeElement.closest('mat-sidenav');
  }

  /**
   * Go to the page of the selected contact.
   * @param contact the contact to go to
   */
  navigateContact(contact: BasicContact): void {
    this.trackerService.event('log', 'navigate_contact', { idtContact: contact.idtContact });
    this.router.navigate(['/contact', contact.idtContact]);
  }

  /**
   * Toggle the log content size: expanding it over the other log fields or not.
   */
  toggleExpand(): void {
    this.trackerService.event('log', 'toggle_expand', { expanded: this.contentExpanded() });
    this.contentExpanded.set(!this.contentExpanded());
  }

  /**
   * Navigate to the log edit screen.
   */
  edit(): void {
    this.trackerService.event('log', 'edit_log');

    // If rendered in the sidenav, navigate relative to the current route, otherwise navigate to the log edit base route
    if (this.sidenav) {
      this.router.navigate(['edit'], { relativeTo: this.route });
    } else {
      this.router.navigate(['', { outlets: { sidenav: ['log', this.log().idtLog, 'edit'] } }]);
    }
  }

  /**
   * Open the list of opportunities linked to this log on the sidenav.
   */
  openOpportunities(): void {
    this.trackerService.event('log', 'open_opportunities');

    if (this.sidenav) {
      this.router.navigate(['opportunities'], { relativeTo: this.route });
    } else {
      this.router.navigate(['', { outlets: { sidenav: ['log', this.log().idtLog, 'opportunities'] } }]);
    }
  }

  /**
   * Open the list of documents linked to this log on the sidenav.
   */
  openDocuments(): void {
    this.trackerService.event('log', 'open_documents');

    if (this.sidenav) {
      this.router.navigate(['docs'], { relativeTo: this.route });
    } else {
      this.router.navigate(['', { outlets: { sidenav: ['log', this.log().idtLog, 'docs'] } }]);
    }
  }

  /**
   * Emit the back event.
   */
  onBack(): void {
    this.back.emit();
  }

  /**
   * Emit the close event.
   */
  onClose(): void {
    this.closed.emit();
  }
}
