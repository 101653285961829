import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, input, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactConsultantTO } from 'app/modules/common/business/client/model/client-balance.model';
import { ConsultantService } from 'app/modules/common/business/consultant/services/consultant.service';
import { AddLeadDialogComponent } from 'app/modules/common/business/contact/components/add-lead-dialog/add-lead-dialog.component';
import { BasicContact, ContactCategory, ContactTypeEnum, ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { LogContactsDTO } from 'app/modules/common/business/log/model/log.model';
import { LogService } from 'app/modules/common/business/log/services/log.service';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, skip, take, tap } from 'rxjs/operators';
import { OpportunityTO } from '../../../model/opportunity.model';

@Component({
  selector: 'app-opportunity-select-client',
  templateUrl: './opportunity-select-client.component.html',
  styleUrls: ['./opportunity-select-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OpportunitySelectClientComponent implements OnInit {
  idtLog = input<number | null>();

  /**
   * The original log, with the referenced contacts.
   */
  logContacts$?: Observable<LogContactsDTO>;

  /**
   * The contacts from the selected company.
   */
  contacts?: PageableDataSource<ContactViewTO>;

  /**
   * The client options for a consultant.
   */
  consultantContacts$?: Observable<ContactViewTO[]>;

  /**
   * The selected values with getters and setters.
   */

  private _selectedContact: ContactViewTO | null = null;
  get selectedContact(): ContactViewTO | null {
    return this._selectedContact;
  }
  set selectedContact(value: ContactViewTO | null) {
    this._selectedContact = value;
    this.findCompanyContacts();
    this.cdr.markForCheck();
  }

  private _selectedKeyContact: BasicContact | null = null;
  get selectedKeyContact(): BasicContact | null {
    return this._selectedKeyContact;
  }
  set selectedKeyContact(value: BasicContact | null) {
    this._selectedKeyContact = value;
    this.cdr.markForCheck();
  }

  private _selectedConsultant: BasicContact | null = null;
  get selectedConsultant(): BasicContact | null {
    return this._selectedConsultant;
  }
  set selectedConsultant(value: BasicContact | null) {
    this._selectedConsultant = value;
    this.cdr.markForCheck();
  }

  private _selectedConsultantCompany: ContactViewTO | null = null;
  get selectedConsultantCompany(): ContactViewTO | null {
    return this._selectedConsultantCompany;
  }
  set selectedConsultantCompany(value: ContactViewTO | null) {
    this._selectedConsultantCompany = value;
    this.findConsultantContacts();
    this.cdr.markForCheck();
  }

  /**
   * Flags for displaying different sections.
   */
  selectingContact = signal(true);
  selectingKeyContact = signal(false);
  selectingConsultantContact = signal(false);

  private _selecting = true;

  set selecting(value: boolean) {
    if (value !== this._selecting) {
      this._selecting = value;
      this.selected.emit(value);
      this.cdr.markForCheck();
    }
  }

  /**
   * Getter to verify if currently selecting any data.
   */
  get selecting(): boolean {
    return this._selecting;
  }

  /**
   * Output event that emits when selecting changes.
   */
  @Output()
  selected = new EventEmitter<boolean>();

  /**
   * The existing opportunity for editions.
   */
  @Input()
  opportunity?: OpportunityTO;

  /**
   * Getter to see if currently editing existing opportunity.
   */
  get edition(): boolean {
    return !!this.opportunity;
  }

  @ViewChild('keyContactViewPort', { static: false })
  keyContactViewPort?: CdkVirtualScrollViewport;

  constructor(
    private logService: LogService,
    private contactService: ContactService,
    private dialog: MatDialog,
    private consultantService: ConsultantService,
    private cdr: ChangeDetectorRef,
  ) {}

  /**
   * Load the log on component initialization.
   */
  ngOnInit(): void {
    if (this.idtLog()) {
      this.logContacts$ = this.logService.getContacts(this.idtLog()!);
    }

    if (this.opportunity) {
      this.setInitialValues();
    }
  }

  /**
   * Load the contacts referenced in the existing opportunity.
   */
  setInitialValues(): void {
    if (this.opportunity) {
      if (this.opportunity.idtContact) {
        this.contactService
          .getInfoById(this.opportunity.idtContact)
          .pipe(
            tap((c) => {
              this.selectedContact = c;

              if (!this.opportunity?.idtConsultantCompany) {
                this.findConsultants();
              }
            }),
          )
          .subscribe();
      }

      if (this.opportunity.idtConsultant) {
        this.contactService.get(this.opportunity.idtConsultant).subscribe((c) => (this.selectedConsultant = c));
      }

      if (this.opportunity.idtConsultantCompany) {
        this.contactService.getInfoById(this.opportunity.idtConsultantCompany).subscribe((c) => (this.selectedConsultantCompany = c));
      }

      if (this.opportunity.idtKeyContact) {
        this.contactService.get(this.opportunity.idtKeyContact).subscribe((c) => (this.selectedKeyContact = c));
      }

      this.selecting = false;
      this.selectingContact.set(false);
      this.selectingKeyContact.set(false);
    }
  }

  /**
   * Handle the initial contact selection.
   * If a person, load its company and select both. Move to the consultant selection.
   * If a company, select it, and allow to choose the key contact.
   *
   * @param contact the selected contact
   */
  select(contact: BasicContact): void {
    this.selectingContact.set(false);

    let person: ContactViewTO | null = null;
    let company: ContactViewTO | null = null;

    this.contactService
      .getInfoById(contact.idtContact)
      .pipe(
        mergeMap((c) => {
          if (c.type === ContactTypeEnum.PERSON) {
            person = c;

            if (c.idtCompanyContact) {
              return this.contactService.getInfoById(c.idtCompanyContact);
            }
          } else {
            company = c;
          }

          return of(null);
        }),
        tap((c) => {
          if (c) {
            company = c;
          }
        }),
      )
      .subscribe(() => {
        this.selectContactOrConsultant(person, company);
      });
  }

  /**
   * Select initial person and company.
   * If company is a consultant, select as consultant, if not, select it as client.
   *
   * @param person the selected person or null
   * @param company the selected company or null
   */
  selectContactOrConsultant(person: ContactViewTO | null, company: ContactViewTO | null): void {
    if (company?.category === ContactCategory.CONSULTANT) {
      this.selectedConsultant = person;
      this.selectedConsultantCompany = company;
    } else {
      this.selectedKeyContact = person;

      if (company) {
        this.selectedContact = company;
      } else {
        this.selectedContact = person;
      }
    }

    if (this.selectedContact && this.selectedKeyContact) {
      this.selecting = false;
      this.findConsultants();
    } else if (this.selectedContact) {
      this.selectingKeyContact.set(true);
    } else if (this.selectedConsultantCompany) {
      this.selectingConsultantContact.set(true);
    }
  }

  /**
   * Select the provided contact and update the displayed sections.
   *
   * @param contact the selected contact or null
   */
  selectContact(contact?: ContactViewTO): void {
    this.selectingContact.set(false);
    this.selectingConsultantContact.set(false);

    if (contact) {
      if (contact.type === ContactTypeEnum.COMPANY) {
        if (this.selectedKeyContact) {
          this.selectKeyContact(this.selectedKeyContact);
        } else {
          this.selectedContact = contact;
          this.findCompanyContacts();

          this.selectingKeyContact.set(true);
        }
      } else {
        // If the contact is a person and has no company, both the client and the key contact is the contact itself
        // If the contact is a person that works at a company, the company is the client and the contact is the key contact
        if (!contact.idtCompany) {
          this.selectedContact = contact;
          this.selectKeyContact(contact);
        } else {
          this.contactService
            .getInfoById(contact.idtContact)
            .pipe(
              mergeMap((c) => {
                if (c.idtCompanyContact) {
                  return this.contactService.getInfoById(c.idtCompanyContact);
                }

                return EMPTY;
              }),
            )
            .subscribe((c) => {
              this.selectedContact = c;
              this.selectKeyContact(contact);
            });
        }
      }
    } else {
      this.selecting = false;
    }
  }

  /**
   * Find contacts that work to selected company.
   */
  findCompanyContacts(): void {
    if (this.selectedContact && this.selectedContact.idtCompany) {
      this.contacts = this.contactService.getCompanyContactDatasource(this.selectedContact.idtCompany);

      this.contacts.dataStream.pipe(skip(1), take(1)).subscribe((data) => {
        if (!this.edition) {
          if (data.length === 0) {
            this.selectKeyContact(null);
          } else if (data.length === 1) {
            this.selectKeyContact(data[0]);
          }
        }
      });
    }
  }

  /**
   * Select the provided key contact and updated the displayed sections.
   *
   * @param contact the selected key contact
   */
  selectKeyContact(contact: BasicContact | null): void {
    this.selectedKeyContact = contact;

    this.selectingKeyContact.set(false);
    this.selecting = false;

    if (!this.selectedConsultantCompany) {
      this.findConsultants();
    }
  }

  /**
   * Find the consultants for the selected contact.
   */
  findConsultants(): void {
    if (this.selectedContact?.idtContact) {
      this.contactService
        .getConsultants(this.selectedContact.idtContact)
        .pipe(
          tap((consultants) => {
            if (consultants.length === 0) {
              this.selectConsultant(null);
            } else {
              // Select the most recent consultant
              this.selectConsultant(
                consultants.reduce((prev, current) => ((prev.idtContactConsultant || 0) > (current.idtContactConsultant || 0) ? prev : current)),
              );
            }
          }),
        )
        .subscribe();
    }
  }

  /**
   * Find contacts consulted by the selected consultant. If a consultant person is selected, show only contacts associated
   * to that specific person, if only the company is selected then show all contacts consulted by the company.
   */
  findConsultantContacts(): void {
    if (this.selectedConsultant || this.selectedConsultantCompany) {
      const contact = this.selectedConsultant ?? this.selectedConsultantCompany;

      this.consultantContacts$ = this.contactService.getConsultantContacts(contact!.idtContact).pipe(
        tap((contacts) => {
          if (contacts.length === 0) {
            this.selectContact();
          }
        }),
      );
    }
  }

  /**
   * Select the provided consultant.
   *
   * @param consultant the selected consultant
   */
  selectConsultant(consultant: ContactConsultantTO | null): void {
    this.selectedConsultant = consultant?.consultant || null;
    this.selectedConsultantCompany = consultant?.externalRep || null;
    this.selecting = false;
  }

  /**
   * Go back to selecting the client contact.
   */
  changeContact(): void {
    this.selectedContact = null;
    this.selectedKeyContact = null;
    this.selectedConsultant = null;
    this.selectedConsultantCompany = null;
    this.selecting = true;

    this.selectingContact.set(true);
    this.selectingKeyContact.set(false);
    this.selectingConsultantContact.set(false);
  }

  /**
   * Go back to selecting the key contact.
   */
  changeKeyContact(): void {
    this.selectedKeyContact = null;
    this.selecting = true;

    this.selectingContact.set(false);
    this.selectingKeyContact.set(true);
    this.selectingConsultantContact.set(false);

    setTimeout(() => {
      if (this.keyContactViewPort) {
        this.keyContactViewPort.checkViewportSize();
      }
    });
  }

  /**
   * Go back to selecting the consultant.
   */
  changeConsultant(): void {
    this.selectedConsultant = null;
    this.selectedConsultantCompany = null;
    this.selecting = true;

    this.selectingContact.set(true);
    this.selectingKeyContact.set(false);
    this.selectingConsultantContact.set(false);
  }

  /**
   * Get the object with the selected values.
   *
   * @returns object with the selected values
   */
  getValues(): any {
    return {
      idtContact: this.selectedContact?.idtContact,
      idtKeyContact: this.selectedKeyContact?.idtContact,
      idtConsultant: this.selectedConsultant?.idtContact,
      idtConsultantCompany: this.selectedConsultantCompany?.idtContact,
    };
  }

  /**
   * Open dialog to add a new consultant to the client.
   */
  addNewConsultant(): void {
    if (this.selectedContact) {
      const dialogRef = this.consultantService.openNewConsultantDialog(this.selectedContact.idtContact);
      dialogRef.afterClosed().subscribe(() => this.findConsultants());
    }
  }

  /**
   * Add a lead to the selected contact. After saving it, reload the contact data.
   */
  addLead(): void {
    if (this.selectedContact) {
      const dialogRef = this.dialog.open(AddLeadDialogComponent, {
        width: '400px',
        data: {
          idtContact: this.selectedContact.idtContact,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(
          mergeMap(() => {
            return this.contactService.getInfoById(this.selectedContact!.idtContact);
          }),
          tap((c) => (this.selectedContact = c)),
        )
        .subscribe();
    }
  }
}
