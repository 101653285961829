import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactChannel, ContactChannelEnum, ContactChannelTypeEnum } from 'app/modules/common/business/contact/model/contact-channel.model';

export type ContactChannelForm = FormGroup<{
  idtContact: FormControl<number | null>;
  channel: FormControl<ContactChannelEnum | null>;
  type: FormControl<ContactChannelTypeEnum | null>;
  value: FormControl<string>;
  defaultChannel: FormControl<boolean>;
}>;

/**
 * Component of contact channel form
 */
@Component({
    selector: 'app-contact-channel-form',
    templateUrl: './contact-channel-form.component.html',
    styleUrls: ['./contact-channel-form.component.scss'],
    standalone: false
})
export class ContactChannelFormComponent implements OnInit {
  ContactChannelEnum = ContactChannelEnum;
  ContactChannelTypeEnum = ContactChannelTypeEnum;

  /**
   * The index of form array.
   */
  @Input()
  index!: number;

  /**
   * The contact channel form
   */
  @Input()
  contactChannelForm!: ContactChannelForm;

  /**
   * The contact channel for edition
   */
  @Input()
  contactChannel?: ContactChannel | null;

  /**
   * The channel of contact.
   */
  @Input()
  channel!: ContactChannelEnum;

  /**
   * Emits the remove event to delete a channel form from array.
   */
  @Output()
  remove = new EventEmitter<number>();

  /**
   * Emits the default channel event to handle with the default channel of other forms from formArray.
   */
  @Output()
  defaultChannel = new EventEmitter<number>();

  /**
   * List of channel types.
   */
  channelTypes: string[] = Object.keys(ContactChannelTypeEnum);

  /**
   * Errors to be shown for the email.
   */
  error?: string;

  constructor(private destroyRef: DestroyRef) {}

  /**
   * Gets the form group of contact email
   * @param contactChannel the channel of contact to set the fields of form
   * @returns the contact channel form group
   */
  static getContactChannelForm(contactChannel: ContactChannel | null): ContactChannelForm {
    return new FormGroup({
      idtContact: new FormControl<number | null>(contactChannel?.idtContact ?? null),
      channel: new FormControl<ContactChannelEnum | null>(contactChannel?.channel ?? null),
      type: new FormControl<ContactChannelTypeEnum | null>(contactChannel?.type ?? null, Validators.required),
      value: new FormControl<string>(contactChannel?.value ?? '', { nonNullable: true }),
      defaultChannel: new FormControl<boolean>(
        { value: !!contactChannel?.defaultChannel, disabled: !!contactChannel?.defaultChannel },
        { nonNullable: true, validators: Validators.required },
      ),
    });
  }

  ngOnInit(): void {
    if (!this.contactChannel && this.index === 0) {
      this.contactChannelForm.controls.defaultChannel.setValue(true);
    }

    this.contactChannelForm.controls.defaultChannel.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value) {
        this.contactChannelForm.controls.defaultChannel.disable({ emitEvent: false });
        this.defaultChannel.emit(this.index);
      } else {
        this.contactChannelForm.controls.defaultChannel.enable({ emitEvent: false });
      }
    });
  }

  /**
   * Emits the event to remove a job form from form array.
   */
  removeForm(): void {
    this.remove.emit(this.index);
  }
}
