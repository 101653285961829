import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nearUnitNumber',
    standalone: false
})
export class NearUnitNumberPipe implements PipeTransform {
  transform(value: number, fractionSize?: string, baseUnit?: string, prefix?: string, limit?: number, locale?: string): string;
  transform(value: null | undefined, fractionSize?: string, baseUnit?: string, prefix?: string, limit?: number, locale?: string): null;
  transform(
    value: number | null | undefined,
    fractionSize?: string,
    baseUnit?: string,
    prefix?: string,
    limit?: number,
    locale?: string,
  ): string | null;
  transform(value: number | null | undefined, fractionSize?: string, baseUnit?: string, prefix = '', limit = 1000, locale = 'en-US'): string | null {
    if (value == null) {
      return null;
    }

    const units = [null, 'k', 'M', 'B'];
    let i: number;

    // eslint-disable-next-line eqeqeq
    for (i = 0; baseUnit != units[i]; i++);

    while (i < units.length - 1 && (value <= -1 * limit || value >= limit)) {
      value = value / 1000;
      i++;
    }

    return prefix + formatNumber(value, locale, fractionSize) + (i > 0 ? units[i] : '');
  }
}
