import { Directive, ElementRef, EventEmitter, HostListener, Output, input, signal } from '@angular/core';

/**
 * Directive to listen to scroll events and register when reaching sections.
 *
 * Works vertically only.
 */
@Directive({
    selector: '[appScrollSpy]',
    standalone: false
})
export class ScrollSpyDirective {
  /**
   * Input tags to check if the view.
   */
  spiedTags = input.required<string[]>();

  /**
   * Emits the id of the current first element in the viewport.
   */
  @Output()
  sectionChange = new EventEmitter<string | null>();

  /**
   * The current id of the first element in the viewport.
   */
  private currentSection = signal<string | null>(null);

  constructor(private el: ElementRef) {}

  /**
   * Listen to scroll events and calculate which is the first element in the viewport based on the provided tag names.
   *
   * @param event the scroll event
   */
  @HostListener('scroll', ['$event'])
  onScroll() {
    let currentSection: string | null = null;
    let currentSectionY: number | null = null;
    const children = this.el.nativeElement.children;

    const parentRect = this.el.nativeElement.getBoundingClientRect();

    for (const element of children) {
      const rect = element.getBoundingClientRect();

      // If the children tag is one of the spied ones
      if (this.spiedTags().some((spiedTag) => spiedTag.toLocaleLowerCase() === element.tagName.toLocaleLowerCase())) {
        // If the child element is at least partially in the view port
        if ((rect.top >= parentRect.top && rect.top <= parentRect.bottom) || (rect.bottom >= parentRect.top && rect.bottom <= parentRect.bottom)) {
          // If the child element is higher vertically than the previous found section
          if (!currentSectionY || rect.top < currentSectionY) {
            currentSectionY = rect.top;
            currentSection = element.id;
          }
        }
      }
    }

    if (currentSection && currentSection !== this.currentSection()) {
      this.currentSection.set(currentSection!);
      this.sectionChange.emit(this.currentSection());
    }
  }
}
