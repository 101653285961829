import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { Document } from '../../model/document.model';

/**
 * Component to List documents
 */
@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DocumentListComponent {
  @Input()
  documentData!: PageableDataSource<Document> | Document[];

  @Input()
  showLog = false;

  @Input()
  showEdit = false;

  @Input()
  showDelete = false;

  @Input()
  trackingFeature: string = 'document_list';

  @Output()
  deleted = new EventEmitter<Document>();

  isEmpty(): boolean {
    if (!this.documentData) {
      return true;
    } else if (Array.isArray(this.documentData)) {
      return this.documentData.length === 0;
    } else {
      return !this.documentData.hasData;
    }
  }

  onDelete(document: Document): void {
    this.deleted.emit(document);
  }
}
