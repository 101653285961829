import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Account } from 'app/modules/common/business/account/model/account.model';
import { AccountService } from 'app/modules/common/business/account/services/account.service';
import { User } from 'app/modules/common/business/user/user.model';
import { UserService } from 'app/modules/common/business/user/user.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { SumPercentMatcher, sumPercent } from 'app/modules/common/framework/validations/validation-utils';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FeesAndFlowsRoleEnum } from '../../models/fees-flows.model';
import { FeesFlowsService } from '../../services/fees-flows.service';

@Component({
    selector: 'app-fees-flows-override-dialog',
    templateUrl: './fees-flows-override-dialog.component.html',
    styleUrls: ['./fees-flows-override-dialog.component.scss'],
    standalone: false
})
export class FeesFlowsOverrideDialogComponent {
  /**
   * Form group instance.
   */
  formGroup = this.fb.group(
    {
      year: new FormControl<number>(this.data.year, { nonNullable: true }),
      idtContact: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      idtPortfolio: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      idtUserPrimary: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      idtUserPrimary2: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      idtUserBackup: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      idtUserMaintenance: new FormControl<number | undefined>(undefined, { nonNullable: true }),
      primaryPercent: new FormControl<number>(100, { nonNullable: true, validators: [Validators.max(100), Validators.min(0)] }),
      primary2Percent: new FormControl<number>(0, { nonNullable: true, validators: [Validators.max(100), Validators.min(0)] }),
      backupPercent: new FormControl<number>(100, { nonNullable: true, validators: [Validators.max(100), Validators.min(0)] }),
      primaryOverrideFirstYear: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.max(100), Validators.min(0)],
      }),
      primaryOverrideSecondYear: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.max(100), Validators.min(0)],
      }),
      primaryOverrideThirdYear: new FormControl<number | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.max(100), Validators.min(0)],
      }),
      comments: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    },
    {
      validators: [sumPercent(['primaryPercent', 'primary2Percent'])],
    }
  );

  /**
   * Error matcher to mark field as invalid based on the sum of percents.
   */
  sumPercentMatcher = new SumPercentMatcher();

  /**
   * The employee options to be associated to the client.
   */
  users: User[] = [];

  /**
   * The account data.
   */
  account$: Observable<Account>;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private feesFlowsService: FeesFlowsService,
    private dialogRef: MatDialogRef<FeesFlowsOverrideDialogComponent>,
    private dialogService: DialogService,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) private data: { idtAccount: number; year: number }
  ) {
    this.userService.getEmployees().subscribe((users) => {
      this.users = users;
    });

    this.feesFlowsService.getOverrides(data.year, data.idtAccount).subscribe((override) => {
      this.formGroup.patchValue(override);
    });

    this.feesFlowsService.getEmployees(data.year, data.idtAccount).subscribe((employess) => {
      this.formGroup.patchValue({
        idtUserPrimary: employess.find((emp) => emp.role === FeesAndFlowsRoleEnum.PRIMARY)?.idtUser,
        idtUserPrimary2: employess.find((emp) => emp.role === FeesAndFlowsRoleEnum.PRIMARY_2)?.idtUser,
        idtUserBackup: employess.find((emp) => emp.role === FeesAndFlowsRoleEnum.BACKUP)?.idtUser,
        idtUserMaintenance: employess.find((emp) => emp.role === FeesAndFlowsRoleEnum.MAINTENANCE)?.idtUser,
      });
    });

    this.account$ = this.accountService.getOne(data.idtAccount).pipe(
      tap((account) => {
        this.formGroup.patchValue({
          idtContact: account.client?.idtOwner,
          idtPortfolio: account.idtPortfolio,
          year: data.year,
        });
      })
    );
  }

  /**
   * Save all changes made.
   */
  save(): void {
    const value = this.formGroup.getRawValue();

    this.feesFlowsService
      .setOverrides({
        ...value,
        idtContact: value.idtContact!,
        idtPortfolio: value.idtPortfolio!,
      })
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error.message || 'Error saving override data');

          return throwError(() => err);
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
