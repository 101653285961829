<div class="tw-flex tw-flex-1 tw-flex-col" #homeContainer>
  @if (homeWidth()) {
    <!-- MOBILE -->
    @if (homeWidth()! < 961) {
      <div class="abs-container-sm mat-elevation-z4 tw-flex tw-flex-1">
        <mat-tab-group
          backgroundColor="primary"
          color="accent"
          class="flex-tab-group tw-flex tw-flex-1"
          [(selectedIndex)]="selectedTab"
          preserveContent
        >
          <mat-tab label="Portfolios">
            <ng-template matTabContent>
              <div class="tab-card tw-flex tw-flex-1 tw-flex-col">
                <div class="card-actions">
                  <span style="margin-right: 5px">as of</span>
                  <mat-form-field class="as-of-datepicker labelless" subscriptSizing="dynamic">
                    <input matInput [matDatepicker]="picker" [formControl]="dateControl" [max]="maxDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker" />
                    <mat-datepicker #picker startView="year" (monthSelected)="monthSelected($event, picker)" />
                  </mat-form-field>
                </div>

                <mat-tab-group
                  mat-stretch-tabs="false"
                  class="flex-tab-group tab-tool-header tw-flex tw-flex-1"
                  preserveContent
                  (selectedTabChange)="onTabChange($event)"
                >
                  <mat-tab label="AUM">
                    <ng-template matTabContent>
                      <app-portfolio-allocation
                        class="tw-flex tw-flex-1 tw-flex-col"
                        (selectPortfolio)="selectPortfolio($event)"
                        [selectedPortfolio]="selectedPortfolio"
                        [asOf]="dateControl.value"
                      />
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="Performance" *hasPermission="'BETA_FEATURES'">
                    <ng-template matTabContent>
                      <app-portfolio-performance [asOf]="dateControl.value" class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col" />
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Investments">
            <ng-template matTabContent>
              <mat-tab-group mat-stretch-tabs="false" class="tw-flex tw-flex-1" preserveContent>
                <mat-tab label="Clients">
                  <ng-template matTabContent>
                    <app-client-allocation
                      class="tw-flex tw-flex-1 tw-flex-col"
                      [portfolio]="selectedPortfolio"
                      [selectedClient]="selectedClient"
                      (selectClient)="selectClient($event)"
                      (portfolioRemoved)="selectPortfolio()"
                      [showChip]="true"
                      [asOf]="dateControl.value"
                    />
                  </ng-template>
                </mat-tab>
                <mat-tab label="Consultants">
                  <ng-template matTabContent>
                    <app-consultant-allocation
                      class="tw-flex tw-flex-1 tw-flex-col"
                      [portfolio]="selectedPortfolio"
                      (selectConsultant)="selectConsultant($event)"
                      [selectedConsultant]="selectedConsultant"
                      (portfolioRemoved)="selectPortfolio()"
                      [showChip]="true"
                      [asOf]="dateControl.value"
                    />
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Opportunities">
            <ng-template matTabContent>
              <mat-tab-group mat-stretch-tabs="false" class="flex-tab-group tab-tool-header tw-flex tw-flex-1" preserveContent>
                <mat-tab label="Clients">
                  <ng-template matTabContent>
                    <app-home-opportunity-list
                      class="tw-flex tw-flex-1 tw-flex-col"
                      [portfolio]="selectedPortfolio"
                      (portfolioRemoved)="selectPortfolio()"
                      (selectOpportunity)="selectOpportunity($event)"
                      [selectedOpportunity]="selectedOpportunity"
                      [showChip]="true"
                    />
                  </ng-template>
                </mat-tab>
                <mat-tab label="Consultants">
                  <ng-template matTabContent>
                    <app-opportunity-list-consultant
                      class="tw-flex tw-flex-1 tw-flex-col"
                      [portfolio]="selectedPortfolio"
                      (portfolioRemoved)="selectPortfolio()"
                      (selectConsultant)="selectOpportunityConsultant($event)"
                      [selectedConsultant]="selectedOpportunityConsultant"
                      [showChip]="true"
                    />
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Logs">
            <ng-template matTabContent>
              <app-log-list-home
                class="tw-flex tw-flex-1 tw-flex-col"
                [portfolio]="selectedPortfolio"
                [client]="selectedClient"
                [consultant]="selectedConsultant"
                [opportunity]="selectedOpportunity"
                [opportunityConsultant]="selectedOpportunityConsultant"
                [showChip]="true"
                (portfolioRemoved)="selectPortfolio()"
                (clientRemoved)="selectClient()"
                (consultantRemoved)="selectConsultant()"
                (opportunityRemoved)="selectOpportunity()"
                (opportunityConsultantRemoved)="selectOpportunityConsultant()"
              />
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    } @else {
      <!-- DESKTOP -->
      <div class="abs-container-gt-sm tw-flex tw-flex-1 tw-gap-1">
        <!-- Portfolio Allocations -->
        <div class="mat-elevation-z4 abs-margin-vertical tab-card tw-ml-0.5 tw-flex tw-w-1/3 tw-flex-col">
          <div class="card-actions">
            <span style="margin-right: 5px">as of</span>
            <mat-form-field class="as-of-datepicker labelless" subscriptSizing="dynamic">
              <input matInput [matDatepicker]="picker" [formControl]="dateControl" [max]="maxDate" />
              <mat-datepicker-toggle matSuffix [for]="picker" />
              <mat-datepicker #picker startView="year" (monthSelected)="monthSelected($event, picker)" />
            </mat-form-field>
          </div>
          <mat-tab-group
            mat-stretch-tabs="false"
            animationDuration="500ms"
            class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab label="Portfolios" class="tw-flex tw-flex-1 tw-flex-col">
              <app-portfolio-allocation
                class="tw-flex tw-flex-1 tw-flex-col"
                (selectPortfolio)="selectPortfolio($event)"
                [selectedPortfolio]="selectedPortfolio"
                [asOf]="dateControl.value"
              />
            </mat-tab>
            <mat-tab label="Performance" class="tw-flex tw-flex-1 tw-flex-col" *hasPermission="'BETA_FEATURES'">
              <ng-template matTabContent>
                <app-portfolio-performance [asOf]="dateControl.value" class="tw-mt-[36px] tw-flex tw-flex-1 tw-flex-col" />
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="mat-elevation-z4 abs-margin-vertical tw-flex tw-w-1/3 tw-flex-col">
          <mat-tab-group mat-stretch-tabs="false" animationDuration="500ms" class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col">
            <mat-tab label="CLIENTS" class="tw-flex tw-flex-1 tw-flex-col">
              <app-client-allocation
                class="tw-flex tw-flex-1 tw-flex-col"
                [portfolio]="selectedPortfolio"
                [selectedClient]="selectedClient"
                (selectClient)="selectClient($event)"
                [asOf]="dateControl.value"
              />
            </mat-tab>
            <mat-tab label="CONSULTANTS" class="tw-flex tw-flex-1 tw-flex-col">
              <ng-template matTabContent>
                <app-consultant-allocation
                  class="tw-flex tw-flex-1 tw-flex-col"
                  [portfolio]="selectedPortfolio"
                  (selectConsultant)="selectConsultant($event)"
                  [selectedConsultant]="selectedConsultant"
                  [asOf]="dateControl.value"
                />
              </ng-template>
            </mat-tab>
            <mat-tab label="OPP: Clients" class="tw-flex tw-flex-1 tw-flex-col">
              <ng-template matTabContent>
                <app-home-opportunity-list
                  class="tw-flex tw-flex-1 tw-flex-col"
                  [portfolio]="selectedPortfolio"
                  (selectOpportunity)="selectOpportunity($event)"
                  [selectedOpportunity]="selectedOpportunity"
                />
              </ng-template>
            </mat-tab>
            <mat-tab label="OPP: Consultants" class="tw-flex tw-flex-1 tw-flex-col">
              <ng-template matTabContent>
                <app-opportunity-list-consultant
                  class="tw-flex tw-flex-1 tw-flex-col"
                  [portfolio]="selectedPortfolio"
                  (selectConsultant)="selectOpportunityConsultant($event)"
                  [selectedConsultant]="selectedOpportunityConsultant"
                />
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="mat-elevation-z4 abs-margin-vertical tw-mr-0.5 tw-flex tw-w-1/3 tw-flex-col">
          <mat-tab-group mat-stretch-tabs="false" animationDuration="500ms" class="flex-tab-group tab-tool-header tw-flex tw-flex-1 tw-flex-col">
            <mat-tab label="LOGS" class="tw-flex tw-flex-1 tw-flex-col">
              <app-log-list-home
                [client]="selectedClient"
                [consultant]="selectedConsultant"
                [opportunity]="selectedOpportunity"
                [opportunityConsultant]="selectedOpportunityConsultant"
                class="tw-flex tw-flex-1 tw-flex-col"
              />
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    }
  }
</div>
