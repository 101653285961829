import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/modules/common/material-module';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FrameworkModule } from '../framework/framework.module';
import { AccountAddOrEditComponent } from './account/account-add-or-edit/account-add-or-edit.component';
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { AccountBalanceHistoryComponent } from './account/components/account-balance-history/account-balance-history.component';
import { AccountFormComponent } from './account/components/account-form/account-form.component';
import { AccountListItemComponent } from './account/components/account-list-item/account-list-item.component';
import { CreateBalanceComponent } from './account/components/create-balance/create-balance.component';
import { PortfolioTreeComponent } from './account/components/portfolio-tree/portfolio-tree.component';
import { HurdleDataPipe } from './account/pipes/hurdle-data.pipe';
import { CountryAutocompleteComponent } from './address/components/country-autocomplete/country-autocomplete.component';
import { ClientBalanceHeaderComponent } from './balance/components/client-balance-header/client-balance-header.component';
import { ClientAllocationComponent } from './client/component/client-allocation/client-allocation.component';
import { ClientGeneralInfoComponent } from './client/component/client-general-info/client-general-info.component';
import { ClientSelectComponent } from './client/component/client-select/client-select.component';
import { ClientTransactionListComponent } from './client/component/client-transaction-list/client-transaction-list.component';
import { ConsultantAllocationComponent } from './client/component/consultant-allocation/consultant-allocation.component';
import { AddConsultantDialogComponent } from './consultant/components/add-consultant-dialog/add-consultant-dialog.component';
import { AccountBalanceInfoTooltipComponent } from './contact/components/account-balance-info-tooltip/account-balance-info-tooltip.component';
import { AccountPerformanceListItemComponent } from './contact/components/account-performance-list-item/account-performance-list-item.component';
import { AddLeadDialogComponent } from './contact/components/add-lead-dialog/add-lead-dialog.component';
import { CompanyIconComponent } from './contact/components/company-icon/company-icon.component';
import { ContactAccountBalanceListComponent } from './contact/components/contact-account-balance-list/contact-account-balance-list.component';
import { ContactAdditionalInfoEditComponent } from './contact/components/contact-additional-info-edit/contact-additional-info-edit.component';
import { ContactAddressEditComponent } from './contact/components/contact-address-edit/contact-address-edit.component';
import { ContactAutocompleteComponent } from './contact/components/contact-autocomplete/contact-autocomplete.component';
import { ContactBalanceItdComponent } from './contact/components/contact-balance-itd/contact-balance-itd.component';
import { ContactBalanceListComponent } from './contact/components/contact-balance-list/contact-balance-list.component';
import { ContactBasicInfoEditComponent } from './contact/components/contact-basic-info-edit/contact-basic-info-edit.component';
import { ContactConsultantControlComponent } from './contact/components/contact-consultant-control/contact-consultant-control.component';
import { ContactConsultantEditComponent } from './contact/components/contact-consultant-edit/contact-consultant-edit.component';
import { ContactContextMenuComponent } from './contact/components/contact-context-menu/contact-context-menu.component';
import { ContactAddressFormComponent } from './contact/components/contact-create-edit/contact-address-form/contact-address-form.component';
import { ContactChannelFormComponent } from './contact/components/contact-create-edit/contact-channel-form/contact-channel-form.component';
import { ContactConsultantFormComponent } from './contact/components/contact-create-edit/contact-consultant-form/contact-consultant-form.component';
import { ContactJobFormComponent } from './contact/components/contact-create-edit/contact-job-form/contact-job-form.component';
import { ContactRelationshipFormComponent } from './contact/components/contact-create-edit/contact-relationship-form/contact-relationship-form.component';
import { ContactEmailEditComponent } from './contact/components/contact-email-edit/contact-email-edit.component';
import { ContactEmailFieldComponent } from './contact/components/contact-email-field/contact-email-field.component';
import { ContactEndJobDialogComponent } from './contact/components/contact-end-job-dialog/contact-end-job-dialog.component';
import { ContactImageComponent } from './contact/components/contact-image/contact-image.component';
import { ContactItemComponent } from './contact/components/contact-item/contact-item.component';
import { ContactJobEditComponent } from './contact/components/contact-job-edit/contact-job-edit.component';
import { ContactLastEmailErrorComponent } from './contact/components/contact-last-email-error/contact-last-email-error.component';
import { ContactListComponent } from './contact/components/contact-list/contact-list.component';
import { ContactPersonalInfoComponent } from './contact/components/contact-personal-info/contact-personal-info.component';
import { ContactPhoneEditComponent } from './contact/components/contact-phone-edit/contact-phone-edit.component';
import { ContactRelationshipEditComponent } from './contact/components/contact-relationship-edit/contact-relationship-edit.component';
import { ContactSearchDialogComponent } from './contact/components/contact-search-dialog/contact-search-dialog.component';
import { ContactSocialEditComponent } from './contact/components/contact-social-edit/contact-social-edit.component';
import { ContactNewAssociateEmployeesComponent } from './contact/components/create-new-contact/contact-new-associate-employees/contact-new-associate-employees.component';
import { ContactNewAssociateToCompanyComponent } from './contact/components/create-new-contact/contact-new-associate-to-company/contact-new-associate-to-company.component';
import { ContactNewBasicDataFormComponent } from './contact/components/create-new-contact/contact-new-basic-data-form/contact-new-basic-data-form.component';
import { ContactNewBasicDataComponent } from './contact/components/create-new-contact/contact-new-basic-data/contact-new-basic-data.component';
import { ContactNewSearchExistingComponent } from './contact/components/create-new-contact/contact-new-search-existing/contact-new-search-existing.component';
import { ContactNewComponent } from './contact/components/create-new-contact/contact-new/contact-new.component';
import { TagAutocompleteComponent } from './contact/components/tag-autocomplete/tag-autocomplete.component';
import { ContactDocumentsComponent } from './document/components/contact-documents/contact-documents.component';
import { DocumentListItemComponent } from './document/components/document-list-item/document-list-item.component';
import { DocumentListComponent } from './document/components/document-list/document-list.component';
import { DuplicatedDocumentsDialogComponent } from './document/components/duplicated-documents-dialog/duplicated-documents-dialog.component';
import { DocumentAddComponent } from './document/document-add/document-add.component';
import { DocumentFormComponent } from './document/document-form/document-form.component';
import { DraftComponent } from './draft/components/draft/draft.component';
import { EntityTableFilterComponent } from './entity-table/components/entity-table-filter/entity-table-filter.component';
import { EntityTableComponent } from './entity-table/components/entity-table/entity-table.component';
import { ContactLogComponent } from './log/components/contact-log/contact-log.component';
import { LogContactChipsComponent } from './log/components/log-contact-chips/log-contact-chips.component';
import { LogDetailsComponent } from './log/components/log-details/log-details.component';
import { LogListDraftItemComponent } from './log/components/log-list-draft-item/log-list-draft-item.component';
import { LogListHomeComponent } from './log/components/log-list-home/log-list-home.component';
import { LogListItemComponent } from './log/components/log-list-item/log-list-item.component';
import { LogListNavigatorComponent } from './log/components/log-list-navigator/log-list-navigator.component';
import { LogListComponent } from './log/components/log-list/log-list.component';
import { LogSidenavComponent } from './log/components/log-sidenav/log-sidenav.component';
import { LogTextEditorComponent } from './log/components/log-text-editor/log-text-editor.component';
import { AddAccountGroupsSidenavComponent } from './mailing/components/add-account-groups-sidenav/add-account-groups-sidenav.component';
import { AddAccountGroupsComponent } from './mailing/components/add-account-groups/add-account-groups.component';
import { AddGroupListComponent } from './mailing/components/add-group-list/add-group-list.component';
import { MailHistoryContactDetailsComponent } from './mailing/components/mail-history-contact-details/mail-history-contact-details.component';
import { MailPreviewDialogComponent } from './mailing/components/mail-preview-dialog/mail-preview-dialog.component';
import { MailingGroupListComponent } from './mailing/components/mailing-group-list/mailing-group-list.component';
import { MenuComponent } from './menu/components/menu/menu.component';
import { AddOrEditOpportunityComponent } from './opportunity/components/add-or-edit-opportunity/add-or-edit-opportunity.component';
import { OpportunityProbabilityComponent } from './opportunity/components/add-or-edit-opportunity/opportunity-probability/opportunity-probability.component';
import { OpportunityRfpComponent } from './opportunity/components/add-or-edit-opportunity/opportunity-rfp/opportunity-rfp.component';
import { OpportunitySelectClientComponent } from './opportunity/components/add-or-edit-opportunity/opportunity-select-client/opportunity-select-client.component';
import { OpportunitySelectPortfolioComponent } from './opportunity/components/add-or-edit-opportunity/opportunity-select-portfolio/opportunity-select-portfolio.component';
import { ContactOpportunitiesListComponent } from './opportunity/components/contact-opportunities-list/contact-opportunities-list.component';
import { HomeOpportunityListItemComponent } from './opportunity/components/home-opportunity-list/home-opportunity-list-item/home-opportunity-list-item.component';
import { HomeOpportunityListComponent } from './opportunity/components/home-opportunity-list/home-opportunity-list.component';
import { OpportunityExceptionsTableComponent } from './opportunity/components/opportunity-exceptions-table/opportunity-exceptions-table.component';
import { OpportunityListConsultantComponent } from './opportunity/components/opportunity-list-consultant/opportunity-list-consultant.component';
import { OpportunityListHeaderComponent } from './opportunity/components/opportunity-list-header/opportunity-list-header.component';
import { OpportunityListItemComponent } from './opportunity/components/opportunity-list-item/opportunity-list-item.component';
import { OpportunityStatusComponent } from './opportunity/components/opportunity-status/opportunity-status.component';
import { ProbabilityTimingPipe } from './opportunity/pipes/probability-timing.pipe';
import { PortfolioAllocationItemComponent } from './portfolio/components/portfolio-allocation-item/portfolio-allocation-item.component';
import { PortfolioAllocationComponent } from './portfolio/components/portfolio-allocation/portfolio-allocation.component';
import { PortfolioAutocompleteComponent } from './portfolio/components/portfolio-autocomplete/portfolio-autocomplete.component';
import { PortfolioContextMenuComponent } from './portfolio/components/portfolio-context-menu/portfolio-context-menu.component';
import { PortfolioSelectComponent } from './portfolio/components/portfolio-select/portfolio-select.component';
import { SearchLogSidenavComponent } from './search/components/search-log-sidenav/search-log-sidenav.component';
import { SearchResultComponent } from './search/components/search-result/search-result.component';
import { SuperSearchFieldComponent } from './search/components/super-search-field/super-search-field.component';
import { TaskListItemComponent } from './task/components/task-list-item/task-list-item.component';
import { TaskListComponent } from './task/components/task-list/task-list.component';
import { TransactionListHeaderComponent } from './transaction/components/transaction-list-header/transaction-list-header.component';
import { TransactionListItemComponent } from './transaction/components/transaction-list-item/transaction-list-item.component';
import { UserAutocompleteComponent } from './user/user-autocomplete/user-autocomplete.component';
import { UserPreferenceDialogComponent } from './user/user-preference-dialog/user-preference-dialog.component';
import { UserSelectComponent } from './user/user-select/user-select.component';

const components = [
  ContactListComponent,
  ContactPersonalInfoComponent,
  ContactItemComponent,
  ContactNewComponent,
  ContactJobFormComponent,
  ContactChannelFormComponent,
  ContactRelationshipFormComponent,
  ContactAddressFormComponent,
  ContactAccountBalanceListComponent,
  ClientTransactionListComponent,
  ClientGeneralInfoComponent,
  ContactBalanceListComponent,
  ContactBalanceItdComponent,
  ContactConsultantFormComponent,
  ContactAddressEditComponent,
  ContactEmailEditComponent,
  ContactPhoneEditComponent,
  ContactJobEditComponent,
  ContactSocialEditComponent,
  ContactConsultantEditComponent,
  ContactRelationshipEditComponent,
  ContactAdditionalInfoEditComponent,
  AddOrEditOpportunityComponent,
  OpportunitySelectClientComponent,
  ProbabilityTimingPipe,
  OpportunitySelectPortfolioComponent,
  OpportunityProbabilityComponent,
  OpportunityStatusComponent,
  OpportunityRfpComponent,
  HomeOpportunityListComponent,
  HomeOpportunityListItemComponent,
  ContactOpportunitiesListComponent,
  OpportunityListConsultantComponent,
  ClientBalanceHeaderComponent,
  ClientAllocationComponent,
  ConsultantAllocationComponent,
  DocumentListComponent,
  DocumentListItemComponent,
  DocumentAddComponent,
  DocumentFormComponent,
  ContactDocumentsComponent,
  DraftComponent,
  LogListComponent,
  LogDetailsComponent,
  LogSidenavComponent,
  LogListItemComponent,
  LogListNavigatorComponent,
  LogContactChipsComponent,
  ContactLogComponent,
  LogListDraftItemComponent,
  LogListHomeComponent,
  AccountListItemComponent,
  PortfolioTreeComponent,
  EntityTableComponent,
  EntityTableFilterComponent,
  AccountDetailsComponent,
  AccountAddOrEditComponent,
  AccountFormComponent,
  AccountBalanceHistoryComponent,
  ContactConsultantControlComponent,
  MenuComponent,
  SuperSearchFieldComponent,
  SearchResultComponent,
  SearchLogSidenavComponent,
  PortfolioAllocationComponent,
  PortfolioAllocationItemComponent,
  CountryAutocompleteComponent,
  ContactContextMenuComponent,
  UserAutocompleteComponent,
  CompanyIconComponent,
  ContactAutocompleteComponent,
  OpportunityListItemComponent,
  OpportunityListHeaderComponent,
  ContactLastEmailErrorComponent,
  AddConsultantDialogComponent,
  PortfolioSelectComponent,
  ClientSelectComponent,
  MailPreviewDialogComponent,
  MailHistoryContactDetailsComponent,
  MailingGroupListComponent,
  ContactImageComponent,
  ContactEndJobDialogComponent,
  TransactionListItemComponent,
  TransactionListHeaderComponent,
  AddGroupListComponent,
  AddAccountGroupsComponent,
  AddAccountGroupsSidenavComponent,
  AddLeadDialogComponent,
  TaskListComponent,
  TaskListItemComponent,
  PortfolioAutocompleteComponent,
  PortfolioContextMenuComponent,
  CreateBalanceComponent,
  AccountBalanceInfoTooltipComponent,
  HurdleDataPipe,
  AccountPerformanceListItemComponent,
  LogTextEditorComponent,
  ContactNewBasicDataComponent,
  ContactNewSearchExistingComponent,
  ContactNewAssociateToCompanyComponent,
  ContactNewBasicDataFormComponent,
  ContactNewAssociateEmployeesComponent,
  UserSelectComponent,
  ContactBasicInfoEditComponent,
  TagAutocompleteComponent,
  ContactEmailFieldComponent,
  OpportunityExceptionsTableComponent,
  DuplicatedDocumentsDialogComponent,
  ContactSearchDialogComponent,
  UserPreferenceDialogComponent,
];

@NgModule({
  declarations: components,
  imports: [
    AngularCommonModule,
    FrameworkModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxCurrencyDirective,
    CKEditorModule,
    A11yModule,
  ],
  exports: components,
})
export class BusinessModule {}
