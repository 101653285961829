import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MONTH_YEAR_DATE_FORMATS } from 'app/modules/common/framework/date-format/custom-date-format';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DateTime } from 'luxon';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Balance } from '../../../client/model/client-balance.model';
import { AccountService } from '../../services/account.service';

/**
 * Component for the dialog to create a new balance.
 */
@Component({
    selector: 'app-create-balance',
    templateUrl: './create-balance.component.html',
    styleUrls: ['./create-balance.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMATS }],
    standalone: false
})
export class CreateBalanceComponent {
  /**
   * The most recent balance available for the account.
   */
  lastBalance$?: Observable<Balance | null>;

  /**
   * Whether editing an existing balance.
   */
  edition = false;

  /**
   * The form group to hold the data to be saved.
   */
  formGroup = this.fb.group({
    endBalance: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required, Validators.min(0)] }),
    reportingMonth: new FormControl<DateTime>(DateTime.now(), { nonNullable: true, validators: [Validators.required] }),
  });

  /**
   * The max allowed date. Latest month end.
   */
  maxDate: DateTime;

  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateBalanceComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: { idtAccount: number; edition: boolean }
  ) {
    const now = DateTime.now();

    if (now.endOf('month').toISODate() === now.toISODate()) {
      this.maxDate = now;
    } else {
      this.maxDate = now.minus({ month: 1 }).endOf('month');
    }

    this.formGroup.controls.reportingMonth.setValue(this.maxDate);

    // Get the latest balance
    this.lastBalance$ = this.accountService.getLatestBalance(data.idtAccount).pipe(
      tap((lastBalance) => {
        this.edition = data.edition;

        if (lastBalance) {
          const control = this.formGroup.controls.reportingMonth;

          const reportingMonth = DateTime.fromISO(lastBalance.reportingMonthDate);
          const nextMonth = reportingMonth.plus({ month: 1 }).endOf('month');

          control.setValue(this.edition ? reportingMonth : nextMonth);
          control.disable();

          if (this.edition) {
            this.formGroup.controls.endBalance.setValue(lastBalance.endBalance);
          } else if (nextMonth > DateTime.now()) {
            this.dialogRef.close();
            this.dialogService.showError('Account already has balance for all past months');
          }
        }
      })
    );
  }

  /**
   * Save the form.
   */
  save(): void {
    this.accountService
      .createNewBalance(this.data.idtAccount, this.formGroup.getRawValue())
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error.message || 'Error creating the balance');

          return throwError(() => err);
        })
      )
      .subscribe(() => {
        this.dialogService.showSuccess('Balance created successfully');
        this.dialogRef.close(true);
      });
  }

  /**
   * Handle when a month is selected, sets the value as the end of that month and close the picker.
   *
   * @param fullDate the selected date
   * @param datepicker the date picker instance
   */
  chosenMonthHandler(fullDate: DateTime, datepicker: MatDatepicker<DateTime>) {
    this.formGroup.controls.reportingMonth.setValue(fullDate.endOf('month'));
    datepicker.close();
  }
}
