import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { EMPTY, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, tap } from 'rxjs/operators';

/**
 * Contact form group type
 */
type ContactTypeFormGroup = FormGroup<{
  consultant: FormControl<ContactViewTO | null>;
  externalRep: FormControl<ContactViewTO | null>;
}>;

/**
 * Component for having a consultant and consultant company inputs.
 */
@Component({
  selector: 'app-contact-consultant-control',
  templateUrl: './contact-consultant-control.component.html',
  styleUrls: ['./contact-consultant-control.component.scss'],
  standalone: false,
})
export class ContactConsultantControlComponent implements OnInit {
  @Input()
  formGroup!: ContactTypeFormGroup;

  /**
   * The form control for the search field of consultant.
   */
  personSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The options to select a consultant.
   */
  consultantOptions$?: Observable<ContactViewTO[]>;

  /**
   * The form control for the external rep search field.
   */
  companySearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The options to select a external rep.
   */
  externalRepOptions$?: Observable<ContactViewTO[]>;

  constructor(
    private contactService: ContactService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.setupContactSearch();
    this.setupExternalRep();
  }

  /**
   * Setup the select search behavior.
   * Also sets up the filling of the external rep based on the selected consultant.
   */
  private setupContactSearch(): void {
    this.consultantOptions$ = this.personSearchControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      debounceTime(1000),
      mergeMap((search) => {
        if (search.length < 2) {
          return of([]);
        } else {
          const filter = new DataFilter()
            .like('name', `%${search}%`, 'string')
            .equals('type', 'PERSON', 'contacttypeenum')
            .equals('inactive', false, 'boolean')
            .encodeURIComponent();
          return this.contactService.search(filter).dataStream.asObservable();
        }
      }),
    );

    this.externalRepOptions$ = this.companySearchControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      debounceTime(1000),
      mergeMap((search) => {
        if (search.length < 2) {
          return of([]);
        } else {
          const filter = new DataFilter()
            .like('name', `%${search}%`, 'string')
            .equals('type', 'COMPANY', 'contacttypeenum')
            .equals('inactive', false, 'boolean')
            .encodeURIComponent();
          return this.contactService.search(filter).dataStream.asObservable();
        }
      }),
    );
  }

  /**
   * Sets up defining the consultant company when selecting a consultant person.
   */
  private setupExternalRep(): void {
    this.formGroup.controls.consultant?.valueChanges
      .pipe(
        distinctUntilChanged(),
        mergeMap((contact) => {
          if (contact!.idtCompany) {
            return this.contactService.getCompanyContact(contact!.idtCompany);
          }

          return EMPTY;
        }),
        tap((companyContact) => {
          if (companyContact) {
            this.formGroup.controls.externalRep.reset();
            setTimeout(() => {
              this.formGroup.controls.externalRep.setValue(companyContact);
            }, 0);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Compares equality for externalRep conttacts.
   */
  externalRepCompare(c1: ContactViewTO, c2: ContactViewTO): boolean {
    return c1 && c2 ? c1.idtContact === c2.idtContact : c1 === c2;
  }
}
