import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Dialog to display messages to the user.
 */
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DialogComponent {
  /**
   * The message to display.
   */
  message: string;

  /**
   * The type of message to display.
   */
  type: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { message: string; type: string }) {
    this.message = data.message;
    this.type = data.type;
  }
}
