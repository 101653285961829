import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BasicUser, User, UserPreferenceTO } from './user.model';

/**
 * Service to interact with the server for user related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the crm users from principals group.
   * @returns the list of users from principals group
   */
  getPrincipals(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/user/group/principals`);
  }

  /**
   * Gets the crm users from employees group.
   * @returns the list of users from employees group
   */
  getEmployees(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/user/group/employees`);
  }

  /**
   * Searches for users in employees group, based on the provided search term.
   *
   * @param term the term to search for
   * @returns an observable that resolves to a list of user mathing the search term
   */
  searchEmployeesByTerm(term: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/user/group/employees`, { params: { term } });
  }

  /**
   * Get all users that are part of the "presentation" group.
   *
   * @returns an observable that emits the list of users when the server responds
   */
  getPresentationGroup(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/user/group/presentation`);
  }

  /**
   * Get an user data by its id.
   *
   * @param idtUser the user id
   * @returns an observable that emits the user data when the server responds
   */
  getById(idtUser: number): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/${idtUser}`);
  }

  /**
   * Find users by email.
   *
   * @param emails
   *         list of emails
   * @returns the list of users found
   */
  getByEmails(emails: string[]) {
    return this.http.get<BasicUser[]>(`${environment.apiUrl}/user`, { params: { emails } });
  }

  /**
   * Get the current user preferences.
   *
   * @returns observable that emits the user preferences after the server responds
   */
  getUserPreferences() {
    return this.http.get<UserPreferenceTO>(`${environment.apiUrl}/user/preference`);
  }

  /**
   * Update the user preferences.
   *
   * @returns observable that emits the updated user preferences after the server responds
   */
  updateUserPreferences(userPreferences: UserPreferenceTO) {
    return this.http.put<UserPreferenceTO>(`${environment.apiUrl}/user/preference`, userPreferences);
  }
}
