import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasicContact } from '../../../contact/model/contact.model';

@Component({
    selector: 'app-log-contact-chips',
    templateUrl: './log-contact-chips.component.html',
    styleUrl: './log-contact-chips.component.scss',
    standalone: false
})
export class LogContactChipsComponent {
  /**
   * The label to display above the chips.
   */
  @Input()
  label!: string;

  /**
   * The contacts to list as chips.
   */
  @Input()
  contacts!: BasicContact[];

  /**
   * Event emitter called when a chip is clicked. Emits the contact.
   */
  @Output()
  selected = new EventEmitter<BasicContact>();

  /**
   * Emits the selected contact as an event.
   *
   * @param contact
   */
  select(contact: BasicContact): void {
    this.selected.emit(contact);
  }
}
