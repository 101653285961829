import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to add a prefix to a value, if it's not empty.
 */
@Pipe({
    name: 'prefix',
    standalone: false
})
export class PrefixPipe implements PipeTransform {
  transform(input: string, prefix: string): string {
    if (!input || input.length === 0) {
      return input;
    }
    return prefix + input;
  }
}
