import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { AccountBalanceTO } from '../../../client/model/client-balance.model';

/*
 * Display an ITD (Inception To Date) figure, unless the values are unreliable.
 */
@Component({
    selector: 'app-contact-balance-itd',
    templateUrl: './contact-balance-itd.component.html',
    styleUrls: ['./contact-balance-itd.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactBalanceItdComponent {
  /**
   * The class balance to display the ITD for.
   */
  @Input()
  account!: AccountBalanceTO;

  @Input()
  dense = false;

  /**
   * Whether the ITD was estimated.
   */
  @Input()
  estimated = false;

  /**
   * Whteher the ITD is cumulative or annualized.
   */
  @Input()
  cumulative = false;

  /**
   * Data from Clienteer prior to 2011-12 isn't reliable.
   */
  minDate = DateTime.fromFormat('2011-12', 'yyyy-MM');
}
