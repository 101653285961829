<app-dialog-title displayTitle="Your preferences" [loading]="loading()" />

<div mat-dialog-content>
  <div class="tw-mb-1">Where do you want to receive your Daily Portfolios Estimates message?</div>
  <mat-button-toggle-group [formControl]="dpeControl" hideSingleSelectionIndicator>
    <mat-button-toggle value="EMAIL">Email</mat-button-toggle>
    <mat-button-toggle value="NONE">None</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>
