import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountBalanceTO } from '../../../client/model/client-balance.model';

/**
 * Component to show icon with tooltip for account fees data.
 */
@Component({
    selector: 'app-account-balance-info-tooltip',
    templateUrl: './account-balance-info-tooltip.component.html',
    styleUrls: ['./account-balance-info-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountBalanceInfoTooltipComponent {
  /**
   * Whether the tooltip is open.
   */
  isOpen = false;

  /**
   * The account data.
   */
  @Input({ required: true })
  data!: AccountBalanceTO;
}
