import { Pipe, PipeTransform } from '@angular/core';
import { HurdleData, HurdleType } from '../../portfolio/model/portfolio.model';

/**
 * Pipe to transform hurdle data into a string representation.
 */
@Pipe({
    name: 'hurdleData',
    standalone: false
})
export class HurdleDataPipe implements PipeTransform {
  /**
   * Transform the data into a string.
   *
   * @param value the hurdle data
   * @returns a string representation of the hurdle data
   */
  transform(value: HurdleData): string | undefined {
    if (!value) {
      return value;
    }

    let res: string | undefined;

    switch (value.hurdleType) {
      case HurdleType.NO_HURDLE:
        res = 'with no hurdle';
        break;
      case HurdleType.FIXED_VALUE:
        res = `with a fixed value of ${value.hurdleValue}`;
        break;
      case HurdleType.INDEX:
        res = `index hurdle \n${value.hurdleIndex}`;
        break;
    }

    return res;
  }
}
