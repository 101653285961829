import { Component, Input } from '@angular/core';

/**
 * Component to display icon for companies.
 */
@Component({
    selector: 'app-company-icon',
    templateUrl: './company-icon.component.html',
    styleUrls: ['./company-icon.component.scss'],
    standalone: false
})
export class CompanyIconComponent {
  /**
   * Whether the company is a parent or branch.
   */
  @Input()
  isParentCompany = true;
}
