import { Component, ElementRef, Inject, QueryList, ViewChildren, signal } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContactAddressViewTO } from '../../../address/model/address.model';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { ContactService } from '../../services/contact.service';
import { AddressFromGroup, ContactAddressFormComponent } from '../contact-create-edit/contact-address-form/contact-address-form.component';

@Component({
    selector: 'app-contact-address-edit',
    templateUrl: './contact-address-edit.component.html',
    styleUrls: ['./contact-address-edit.component.scss'],
    standalone: false
})
export class ContactAddressEditComponent {
  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    addresses: new FormArray<AddressFromGroup>([]),
  });

  /**
   * Get the formArray with the data.
   */
  get formArray() {
    return this.formGroup.controls.addresses;
  }

  private addresses?: ContactAddressViewTO[];

  /**
   * The addresses of the contact's company.
   */
  companyAddresses$?: Observable<ContactAddressViewTO[]>;

  /**
   * The contact id for the company of the contact. Used to pre-load company addresses to be used for a contact.
   */
  idtCompanyContact?: number;

  /**
   * If this is the contact's favorite address.
   */
  idtFavoriteAddress = signal<number | null>(null);

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * Reference to the form components created for each address.
   */
  @ViewChildren(ContactAddressFormComponent, { read: ElementRef })
  formComponents!: QueryList<ElementRef>;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private trackerService: TrackerService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean; idtCompanyContact?: number; idtFavoriteAddress?: number },
    private dialogRef: MatDialogRef<ContactAddressEditComponent>,
  ) {
    this.idtContact = data.idtContact;
    this.idtCompanyContact = data.idtCompanyContact;
    this.idtFavoriteAddress.set(data.idtFavoriteAddress || null);

    this.getAddresses(data.includeNew);
    this.getContactCompany();
  }

  /**
   * Load the contact's company id, to be used for pre-filling the addresses options.
   */
  private getContactCompany(): void {
    if (this.idtCompanyContact && this.idtCompanyContact !== this.idtContact) {
      this.companyAddresses$ = this.contactService.findAddressesByIdtContact(this.idtCompanyContact, false);
    }
  }

  /**
   * Get the contact addresses from the server.
   */
  private getAddresses(includeNew: boolean): void {
    if (this.idtContact) {
      this.formArray.clear();

      this.contactService
        .findAddressesByIdtContact(this.idtContact, false)
        .pipe(
          tap((addresses) => {
            this.addresses = addresses;

            for (const address of this.addresses) {
              this.addContactAddressForm(address);
            }
          }),
          tap(() => {
            if (includeNew) {
              this.addContactAddressForm();
            }
          }),
        )
        .subscribe();
    }
  }

  /**
   * Get a single address data from the list of already saved.
   *
   * @param index the index of the address in the list
   * @returns the address found or null
   */
  getAddress(index: number): ContactAddressViewTO | null {
    if (this.addresses) {
      return this.addresses[index];
    }

    return null;
  }

  /**
   * Adds a new contact address form group in the form array
   */
  addContactAddressForm(address?: ContactAddressViewTO): void {
    const form = ContactAddressFormComponent.getContactAddressForm(address, this.idtFavoriteAddress() === address?.idtAddress);
    this.formArray.push(form);

    // If creating a new address, after the form component is created, scroll it into the visible area
    if (!address) {
      setTimeout(() => {
        this.formComponents.last.nativeElement.scrollIntoView();
      });
    }
  }

  /**
   * Remove an entity from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }

  /**
   * Change the favorite address.
   *
   * @param index the index of the new favorite address
   */
  changeFavoriteAddress(index: number | null): void {
    for (let i = 0; i < this.formArray.length; i++) {
      const control = this.formArray.controls[i]?.controls.favorite;

      if (i !== index) {
        control?.setValue(false);
      }
    }

    // If an address from the contact's address, then remove the company address as favorite
    if (index != null) {
      this.idtFavoriteAddress.set(null);
    }
  }

  /**
   * Set the contact's favorite address.
   *
   * @param idtAddress the address id
   */
  setFavoriteAddress(event: MatSlideToggleChange, idtAddress: number): void {
    if (event.checked) {
      this.trackerService.event('contact_edit_address', 'set_favorite', { value: idtAddress });
      this.idtFavoriteAddress.set(idtAddress);
      this.changeFavoriteAddress(null);
    }
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      this.contactService
        .updateAddresses(this.idtContact, {
          idtFavoriteAddress: this.idtFavoriteAddress(),
          addresses: this.formArray.getRawValue(),
        })
        .pipe(
          trackLoading(this.saving),
          catchError((err) => {
            this.dialogService.showError(err.error?.message || "Couldn't save the contact addresses");
            return throwError(() => err);
          }),
          tap(() => {
            this.dialogService.showSuccess('Contact addresses saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
