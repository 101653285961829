import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output, effect, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { BusinessRoleService } from 'app/modules/common/business/business-role/services/business-role.service';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { NavigationService } from 'app/modules/common/framework/services/navigation.service';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, mergeMap, throwError } from 'rxjs';
import { BasicContact, ContactTypeEnum, ContactViewTO } from '../../../model/contact.model';
import { ContactService } from '../../../services/contact.service';
import { ContactNewBasicData } from '../contact-new-basic-data-form/contact-new-basic-data-form.component';

/**
 * Component for dialog contents to create a new contact.
 */
@Component({
    selector: 'app-contact-new-basic-data',
    templateUrl: './contact-new-basic-data.component.html',
    styleUrl: './contact-new-basic-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactNewBasicDataComponent implements OnInit {
  ContactTypeEnum = ContactTypeEnum;

  /**
   * The contact type to be created, person or company.
   */
  type = input.required<ContactTypeEnum>();

  /**
   * The name of the contact, can be changed.
   */
  name = input.required<string>();

  /**
   * Value to prefill the contact email.
   */
  email = input<string>();

  /**
   * Id of a main company when creating a branch.
   */
  idtMainCompany = input<number>();

  /**
   * The main company entity.
   */
  mainCompany = signal<BasicContact | null>(null);

  /**
   * The selected company.
   */
  selectedCompany = signal<BasicContact | null>(null);

  /**
   * Wheter currently selecting a company.
   */
  selecting = signal(false);

  /**
   * Effect to clear input after selecting an option.
   */
  private selectingEffect = effect(() => {
    if (this.selecting()) {
      this.trackerService.event('contact_new', 'associate_company_change_selected');
    }
  });

  /**
   * Form control for the job role input.
   */
  jobRoleControl = new FormControl<string>('', { nonNullable: true });

  /**
   * Options for autocomplete of job roles.
   */
  filteredRoles$ = this.jobRoleControl.valueChanges.pipe(
    takeUntilDestroyed(this.destroyRef),
    debounceTime(500),
    distinctUntilChanged(),
    filter((search) => search.length >= 2),
    mergeMap((search) => {
      return this.businessRoleService.findByRole(search);
    }),
  );

  /**
   * Emits flag indicating if the server is processing a request.
   */
  @Output()
  processing = new EventEmitter<boolean>();

  /**
   * Emits the saved contact after server completes the request.
   */
  @Output()
  completed = new EventEmitter<ContactViewTO>();

  /**
   * Whether searching for duplicated emails.
   */
  searchingDuplicatedEmails = signal(false);

  constructor(
    private contactService: ContactService,
    private dialogService: DialogService,
    private trackerService: TrackerService,
    private navigationService: NavigationService,
    private destroyRef: DestroyRef,
    private businessRoleService: BusinessRoleService,
  ) {}

  ngOnInit(): void {
    this.getPageContact();

    if (this.idtMainCompany()) {
      this.contactService.getCompanyContact(this.idtMainCompany()!).subscribe((mainCompany) => {
        this.mainCompany.set(mainCompany);
      });
    }
  }

  private getPageContact(): void {
    const idtCompany = this.navigationService.getCurrentMainRouteData().entity?.idtCompany;

    if (idtCompany) {
      this.processing.emit(true);

      this.contactService
        .getCompanyContact(idtCompany)
        .pipe(
          finalize(() => {
            this.processing.emit(false);
          }),
        )
        .subscribe((v) => {
          this.selectedCompany.set(v);
        });
    }
  }

  /**
   * Handles selecting a company for the contact.
   *
   * @param company the selected company
   */
  companySelected(company: BasicContact | null): void {
    if (company) {
      this.selectedCompany.set(company);
    }

    this.selecting.set(false);
  }

  /**
   * Send request to the server to save the new contact.
   */
  save(data: ContactNewBasicData): void {
    this.processing.emit(true);

    this.contactService
      .create({
        type: this.type(),
        idtMainCompany: this.idtMainCompany(),
        idtCompany: this.selectedCompany()?.idtCompany,
        jobRole: this.jobRoleControl.value,
        ...data,
      })
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error creating contact');

          return throwError(() => err);
        }),
        finalize(() => {
          this.processing.emit(false);
        }),
      )
      .subscribe((contact) => {
        this.trackerService.event('contact_new', 'save_new', { type: this.type() });
        this.completed.emit(contact);
      });
  }
}
