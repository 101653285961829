import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ImageDialogComponent } from 'app/modules/common/framework/image-dialog/image-dialog.component';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ContactTypeEnum } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';

/**
 * Interface to prevent binding erros on file input select event.
 */
interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

/**
 * Component to show contact image as an avatar.
 */
@Component({
    selector: 'app-contact-image',
    templateUrl: './contact-image.component.html',
    styleUrls: ['./contact-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactImageComponent {
  /**
   * The image path wrapped in an observable.
   */
  image$?: Observable<string | null>;

  /**
   * Whether the contact has an actual image or is using just an avatar.
   */
  hasImage = false;

  /**
   * Image file reference for updating the contact's picture.
   */
  picture?: File;

  /**
   * The contact id setter, loads the image.
   */
  @Input()
  set idtContact(value: number) {
    this._idtContact = value;
    this.loadImage();
  }

  get idtContact(): number {
    return this._idtContact;
  }

  private _idtContact!: number;

  @Input()
  showEdit = false;

  type = input<ContactTypeEnum>(ContactTypeEnum.PERSON);

  constructor(
    private contactService: ContactService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {}

  /**
   * Load the image from the server.
   */
  loadImage() {
    this.image$ = this.contactService.getImageUrl(this.idtContact).pipe(
      tap((url) => (this.hasImage = !!url)),
      map((url) => {
        if (url) {
          return url;
        }

        return this.type() === ContactTypeEnum.PERSON ? 'assets/images/no_profile_picture.jpg' : 'assets/images/no_logo.png';
      }),
    );
  }

  /**
   * Opens the provided image in a dialog.
   *
   * @param event the click event
   * @param url the picture url
   */
  openZoomDialog(event: Event, url: string) {
    event.stopPropagation();

    this.dialog.open(ImageDialogComponent, {
      data: {
        url,
      },
    });
  }

  /**
   * When a picture is selected.
   *
   * @param event the input event
   */
  selectPicture(event: Event): void {
    this.picture = (event as HTMLInputEvent).target.files?.item(0) ?? undefined;
    this.updateImage();
  }

  /**
   * Send the request to update the contact's picture.
   */
  updateImage() {
    if (this.picture) {
      this.contactService
        .updatePicture(this.idtContact, this.picture)
        .pipe(
          catchError((err) => {
            this.dialogService.showError(err.error?.message || 'Error updating the picture. Try again or contact support');

            return throwError(() => err);
          }),
        )
        .subscribe(() => {
          this.loadImage();

          // We must mark this component to be changed detected because if this method was called from the update picture subscription, it wont trigger change detection
          this.cdr.markForCheck();
        });
    }
  }
}
