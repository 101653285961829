import { Component, DestroyRef, effect, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { FeesFlowsService } from 'app/modules/routes/fees-flows/services/fees-flows.service';
import { PortfolioDpeConfigurationDialogComponent } from 'app/modules/routes/home/components/portfolio-dpe-configuration-dialog/portfolio-dpe-configuration-dialog.component';
import { InboxEventEnum } from 'app/modules/routes/inbox/services/inbox-event.enum';
import { InboxService } from 'app/modules/routes/inbox/services/inbox.service';
import { ApplicationUser } from 'app/modules/routes/login/model/application-user';
import { Subscription, tap, timer } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { ContactNewDialogResponse } from '../../../contact/components/create-new-contact/contact-new/contact-new.component';
import { ContactService } from '../../../contact/services/contact.service';
import { UserPreferenceDialogComponent } from '../../../user/user-preference-dialog/user-preference-dialog.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: false,
})
export class MenuComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatDrawer;

  /**
   * Observable to identify if user is logged in.
   */
  loggedIn$ = this.authService.loggedIn$;

  /**
   * The logged in user data.
   */
  user = signal<ApplicationUser | null>(null);

  /**
   * Subscription reference to avoid memory leaks.
   */
  private inboxSubscription?: Subscription;

  /**
   * Effect to trigger polling inbox message count.
   */
  private userEffect = effect(() => {
    if (this.inboxSubscription) {
      this.inboxSubscription.unsubscribe();
    }

    if (this.user()) {
      // Every 5 minutes get the amount of pending inbox messages
      this.inboxSubscription = timer(0, 300000)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          tap(() => {
            // Use document.hidden to make sure the tab is active
            if (!document.hidden) {
              this.countInboxMessages();
            }
          }),
        )
        .subscribe();
    }
  });

  /**
   * The number of messages in the inbox for the current user.
   */
  pendingMessagesCount = signal<number>(0);

  /**
   * Last avilable configured fees and flows year.
   */
  feesAndFlowsLatestYear?: number;

  constructor(
    public authService: AuthService,
    private trackerService: TrackerService,
    private contactService: ContactService,
    private router: Router,
    private inboxService: InboxService,
    private destroyRef: DestroyRef,
    private eventQueueService: EventQueueService,
    private feesFlowsService: FeesFlowsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    // Load the current logged in user
    this.authService.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((user) => {
          this.user.set(user);

          if (user && !this.feesAndFlowsLatestYear) {
            this.feesFlowsService.getYears().subscribe((data) => {
              this.feesAndFlowsLatestYear = Math.max(...data);
            });
          }
        }),
      )
      .subscribe();

    // Trigger counting inbox messages after each message is saved
    this.eventQueueService
      .on(InboxEventEnum.messageSaved)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.countInboxMessages());
  }

  /**
   * Open the main menu sidenav.
   */
  open() {
    this.trackerService.event('main_menu', 'open');
    this.drawer.toggle();
  }

  /**
   * Close the main menu sidenav.
   */
  close() {
    this.trackerService.event('main_menu', 'close');
    this.drawer.toggle();
  }

  /**
   * Navigate to the new contact sidenav route.
   * @param e the click event
   */
  navigateToNewContact(e: Event): void {
    e?.stopPropagation();
    this.contactService
      .openCreateContactDialog()
      .afterClosed()
      .subscribe((data?: ContactNewDialogResponse) => {
        if (data?.idtContact) {
          if (data.new) {
            // Redirect to contact overview page with contact editing sidenav
            this.router.navigate([{ outlets: { sidenav: ['contact', data.idtContact, 'info'], primary: ['contact', data.idtContact] } }]);
          } else {
            // If existing contact, just go to contact overview page
            this.router.navigate(['contact', data.idtContact]);
          }
        }
      });
    this.clicked('Add Contact');
  }

  /**
   * When a link is clicked, track it and close the drawer.
   * @param item the identification of the selected option to be tracked
   */
  clicked(item: string): void {
    this.trackerService.event('main_menu', 'select', { item });
    this.drawer.toggle();
  }

  /**
   * Track navigation to the home page.
   */
  home(): void {
    this.trackerService.event('main_menu', 'home');

    // Adding fake navigation to reload the page when the user is at home and when they are on another route just navigate.
    this.router.navigateByUrl('blank', { skipLocationChange: true }).then(() => {
      this.router.navigate(['']);
    });
  }

  /**
   * Logs the user out of the system.
   */
  logout(): void {
    this.trackerService.event('main_menu', 'logout');
    this.authService.logout();
  }

  /**
   * Open admin configurations.
   */
  openAdminConfigurations() {
    this.dialog.open(PortfolioDpeConfigurationDialogComponent, {
      width: '500px',
      height: '800px',
    });

    this.trackerService.event('admin', 'open_dms_configuration_dialog');
  }

  /**
   * Get the number of pending messages in the inbox to show in the badge.
   */
  private countInboxMessages() {
    this.inboxService
      .getCountForSingleUser(this.user()!.id)
      .pipe(
        tap((count) => {
          this.pendingMessagesCount.set(count);
        }),
      )
      .subscribe();
  }

  /**
   * Open user configurations dialog.
   */
  openConfigurations() {
    this.dialog.open(UserPreferenceDialogComponent, {
      width: '500px',
      height: '200px',
    });
  }

  isAdmin() {
    return this.authService.hasPermission('ADMIN_CONFIGURATIONS');
  }
}
