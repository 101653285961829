import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { TotalType } from 'app/modules/common/framework/model/total-type.model';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { EmailGroupContactViewTO, EmailHistoryContactGroupViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { DateTime } from 'luxon';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Pageable, SuperSearchPageableResultItem } from '../../../framework/pagination/pageable';
import { Category } from '../../account/model/account.model';
import { ContactAddressViewTO, UpdateContactAddressDTO } from '../../address/model/address.model';
import { AccountBalanceTO, ContactConsultantTO, ContactLeadInputDTO, UpdateConsultantDTO } from '../../client/model/client-balance.model';
import { ClientConsultantViewTO } from '../../client/model/consultant.model';
import { Document } from '../../document/model/document.model';
import { Column } from '../../entity-table/model/column.model';
import { CustomTableService } from '../../entity-table/services/custom-table';
import { LogDetailViewTO } from '../../log/model/log.model';
import { TrackerService } from '../../tracker/services/tracker.service';
import { ContactNewComponent, ContactNewDialogResponse } from '../components/create-new-contact/contact-new/contact-new.component';
import { ContactChannel, ContactChannelEnum } from '../model/contact-channel.model';
import { ContactJob, ContactJobInputDTO, ContactJobViewTO } from '../model/contact-job.model';
import { ContactSocialMedia, UpdateSocialInputDTO } from '../model/contact-social-media.model';
import {
  CanInactivateContactResponseDTO,
  ContactBaseInputDTO,
  ContactFullTableViewTO,
  ContactIndexedDTO,
  ContactInput,
  ContactList,
  ContactRelationshipTO,
  ContactRelationshipType,
  ContactRelationshipViewTO,
  ContactTypeEnum,
  ContactViewTO,
  CreateContactInputDTO,
} from '../model/contact.model';

/**
 * Service to call the api for a contact CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class ContactService implements CustomTableService<ContactFullTableViewTO> {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private trackerService: TrackerService,
    private dialogService: DialogService,
  ) {}

  /**
   * Gets the data of a specific contact
   *
   * @param id contact id
   * @returns Contact
   */
  getInfoById(id: number): Observable<ContactViewTO> {
    return this.http.get<ContactViewTO>(`${environment.apiUrl}/contact/${id}/info`);
  }

  /**
   * Searches for contact relationship by idtContactFrom and other optional filter
   *
   * @param idtContact the id of contact
   * @param filter the filter for search
   * @param sort to sort the result
   * @param limit the size of page
   * @returns the page of found contacts
   */
  searchRelationship(
    idtContact: number,
    filter: string,
    sort: string[] = ['name,ASC'],
    limit: number = 10,
  ): PageableDataSource<ContactRelationshipViewTO> {
    const http = this.http;

    return new (class extends PageableDataSource<ContactRelationshipViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<ContactRelationshipViewTO>> {
        return http.get<Pageable<ContactRelationshipViewTO>>(`${environment.apiUrl}/contact/${idtContact}/relationship`, {
          params: { filter, sort, page: page.toString(), limit: limit.toString() },
        });
      }
    })(limit);
  }

  /**
   * Get all relationships for the provided contact.
   *
   * @param idtContact the id of the contact
   * @returns the list of relationships found wrapped in an observable
   */
  getRelationships(idtContact: number): Observable<ContactRelationshipTO[]> {
    return this.http.get<ContactRelationshipTO[]>(`${environment.apiUrl}/contact/${idtContact}/relationship`);
  }

  /**
   * Get all relationships for the provided contact.
   *
   * @param idtContact the contact id
   * @returns observable that emits the list of relationships found
   */
  getRelationshipsData(idtContact: number): Observable<ContactRelationshipViewTO[]> {
    return this.http.get<ContactRelationshipViewTO[]>(`${environment.apiUrl}/contact/${idtContact}/relationship/search`);
  }

  /**
   * Update all the relationships for the provided contact.
   *
   * @param idtContact the id of the contact
   * @param relationships the relationships to be saved
   * @returns the list of relationships saved wrapped in an observable
   */
  updateRelationships(idtContact: number, relationships: ContactRelationshipTO[]): Observable<ContactRelationshipTO[]> {
    return this.http.put<ContactRelationshipTO[]>(`${environment.apiUrl}/contact/${idtContact}/relationship`, relationships);
  }

  /**
   * Get all contacts matching the provided filters.
   *
   * @param filter the filters to apply
   * @param sort the properties to sort by
   * @returns an observable that emits the list of contacts when the server responds
   */
  getAll(filter: DataFilter, sort: string | string[] = 'name,ASC'): Observable<ContactList[]> {
    return this.http.get<ContactList[]>(`${environment.apiUrl}/contact?filter=${filter.encodeURIComponent()}`, {
      params: { sort },
    });
  }

  /**
   * Searches for contacts regarding of its name
   *
   * @param filter the filter for search
   * @param sort to sort the result
   * @param limit the size of page
   * @returns the page of found contacts
   */
  search(filter: string, sort: string | string[] = ['name,ASC'], limit: number = 10): PageableDataSource<ContactViewTO> {
    const getContactsViewPage = this.getContactsViewPage.bind(this);

    return new (class extends PageableDataSource<ContactViewTO> {
      fetch(page: number, size: number): Observable<Pageable<ContactViewTO>> {
        return getContactsViewPage(page, size, sort, filter);
      }
    })(limit);
  }

  /**
   * Get a page of contacts from the contact view.
   *
   * @param page the page number
   * @param size the size of the page
   * @param sort the sorting column
   * @param filter the filter to apply to the search
   * @returns the page of contacts found wrapped in an observable
   */
  getContactsViewPage(page: number, size: number, sort: string | string[], filter: string = ''): Observable<Pageable<ContactViewTO>> {
    return this.http.get<Pageable<ContactViewTO>>(`${environment.apiUrl}/contact/search?filter=${filter}`, {
      params: { sort, page: page.toString(), size: size.toString() },
    });
  }

  /**
   * Gets a list of Address for the provided contact.
   *
   * @param idtContact contact id
   * @returns list of address found wrapped in an observable
   */
  findAddressesByIdtContact(idtContact: number, includeBranches: boolean): Observable<ContactAddressViewTO[]> {
    return this.http.get<ContactAddressViewTO[]>(`${environment.apiUrl}/contact/${idtContact}/addresses`, {
      params: {
        includeBranches,
      },
    });
  }

  /**
   * Update a contact's addresses.
   *
   * @param idtContact the contact id
   * @param addresses the addresses to be saved
   * @returns list of address found wrapped in an observable
   */
  updateAddresses(idtContact: number, addresses: UpdateContactAddressDTO): Observable<ContactAddressViewTO[]> {
    return this.http.put<ContactAddressViewTO[]>(`${environment.apiUrl}/contact/${idtContact}/addresses`, addresses);
  }

  /**
   * Finds mail groups for the provided contact.
   *
   * @param idtContact the contact id
   * @param sort the column and direction to sort by
   * @param filter the filters to apply when searching
   */
  findMailingGroupsByIdtContact(idtContact: number, sort: string | string[], filter: DataFilter): Observable<EmailGroupContactViewTO[]> {
    return this.http.get<EmailGroupContactViewTO[]>(
      `${environment.apiUrl}/contact/${idtContact}/email-groups?filter=${filter.encodeURIComponent()}`,
      {
        params: {
          sort,
        },
      },
    );
  }

  /**
   * Gets a list of total of logs by type
   * @param id contact id
   * @returns TotalType
   */
  getLogsTotal(id: number): Observable<TotalType[]> {
    return this.http.get<TotalType[]>(`${environment.apiUrl}/contact/${id}/logs/total`);
  }

  /**
   * Searches for logs related to this contact
   * @param id the id of contact
   * @param term the term to search
   * @param page the page to start
   * @param limit the page size
   * @returns the result of search
   */
  searchLogs(
    id: number,
    term: string | null,
    type: string | null,
    page: number = 0,
    limit: number = 25,
  ): Observable<SuperSearchPageableResultItem<LogDetailViewTO>> {
    const from = page * limit;
    const params: Record<string, string> = {};
    params.from = from.toString();
    params.size = limit.toString();

    if (term) {
      params.term = term;
    }

    if (type) {
      params.type = type;
    }

    return this.http.get<SuperSearchPageableResultItem<LogDetailViewTO>>(`${environment.apiUrl}/contact/${id}/indexed/log`, {
      params,
    });
  }

  /**
   * Search for contacts in the index server.
   *
   * @param term the term to search for
   * @param type optional contact type filter
   * @param companies the companies to prioritize when sorting results
   * @returns the page of contacts found wrapped in an observable
   */
  searchIndexedContacts(term: string, type?: ContactTypeEnum, companies: string[] = []): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        term,
        companies: companies.length > 0 ? companies : '',
        ...(type && { type }),
      },
    });
  }

  /**
   * Searches for contacts belonging to the provided company or any of its branches.
   *
   * @param term the term to search for
   * @param idtCompany the company to filter for
   * @returns an observable that emits the contacts found when the server responds
   */
  searchIndexedContactsFromCompany(term: string, idtCompany: number): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        term,
        idtCompany,
      },
    });
  }

  /**
   * Search for invested contacts in the index server.
   *
   * @param term the term to search for
   * @returns the page of contacts found wrapped in an observable
   */
  searchIndexedInvestedContacts(term: string): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        client: true,
        term,
      },
    });
  }

  /**
   * Search for main consultant companies in the index server.
   *
   * @param term the term to search for
   * @returns the page of contacts found wrapped in an observable
   */
  searchIndexedConsultantContacts(term: string): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        mainConsultant: true,
        term,
      },
    });
  }

  /**
   * Search for main companies (any company that is not a branch) in the index server.
   *
   * @param term the term to search for
   * @returns the page of contacts found wrapped in an observable
   */
  searchIndexedMainCompanies(term: string): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        mainCompany: true,
        term,
      },
    });
  }

  /**
   * Search for unassociated contacts
   *
   * @param term the term to search for
   * @returns the list of contacts found wrapped in an observable
   */
  searchIndexedUnassociatedContacts(term: string): Observable<ContactIndexedDTO[]> {
    return this.http.get<ContactIndexedDTO[]>(`${environment.apiUrl}/contact/search/indexed`, {
      params: {
        unassociated: true,
        term,
      },
    });
  }

  /**
   * Gets the paged list of documents from a contact, optionally filtering by type and a search term.
   *
   * @param id the contact id
   * @param term the search term
   * @param type the document type to filter for
   * @returns pageable datasource
   */
  findDocuments(id: number, term: string | null = null, type: string | null = null): PageableSuperSearchDataSource<Document> {
    const self = this;
    const limit = 25;
    const page = 0;

    return new (class extends PageableSuperSearchDataSource<Document> {
      fetch(page: number, limit: number): Observable<SuperSearchPageableResultItem<Document>> {
        return self.searchDocuments(id, term, type, page, limit);
      }
    })(limit, page);
  }

  /**
   * Get a single page of documents from the server.
   *
   * @param id the contact id
   * @param term the search term
   * @param type the document type to filter for
   * @param page the page number to get
   * @param limit the page size
   * @returns page of documents matching the criteria
   */
  private searchDocuments(
    id: number,
    term: string | null,
    type: string | null,
    page: number = 0,
    limit: number = 25,
  ): Observable<SuperSearchPageableResultItem<Document>> {
    const from = page * limit;
    const params: Record<string, string> = {};
    params.from = from.toString();
    params.size = limit.toString();

    if (term) {
      params.term = term;
    }

    if (type) {
      params.type = type.toString();
    }

    return this.http.get<SuperSearchPageableResultItem<Document>>(`${environment.apiUrl}/contact/${id}/indexed/document`, {
      params,
    });
  }

  /**
   * Gets a list of total of docs by type
   * @param id contact id
   * @returns TotalType
   */
  getDocsTotal(id: number): Observable<TotalType[]> {
    return this.http.get<TotalType[]>(`${environment.apiUrl}/contact/${id}/docs/total`);
  }

  /**
   * Gets the paged list of emails from a contact, optionally filtering by description
   * @param id contact id
   * @param limit the page size
   * @param filter the filters to apply to the query
   * @returns a datasource that will load more data when scrolling
   */
  findEmailsByIdtContact(id: number, limit: number, filter: DataFilter): PageableDataSource<EmailHistoryContactGroupViewTO> {
    const http = this.http;

    return new (class extends PageableDataSource<EmailHistoryContactGroupViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<EmailHistoryContactGroupViewTO>> {
        const params: Record<string, string> = {
          page: page.toString(),
          size: limit.toString(),
        };

        return http.get<Pageable<EmailHistoryContactGroupViewTO>>(`${environment.apiUrl}/contact/${id}/campaigns?filter=${filter}`, {
          params,
        });
      }
    })(limit);
  }

  /**
   * Gets the totals of emails by type
   * @param id contact id
   * @returns TotalType
   */
  getEmailsTotal(id: number): Observable<TotalType[]> {
    return this.http.get<TotalType[]>(`${environment.apiUrl}/contact/${id}/emails/total`);
  }

  /**
   * Gets params for pagination and filter for type
   * @param page
   * @param limit
   * @param type
   */
  private getRequestParams(page: number, limit: number, type: string | null = null): string {
    let params = `page=${page}&size=${limit}`;

    if (type && type.toLowerCase() !== 'total') {
      params += `&type=${type}`;
    }

    return params;
  }

  /**
   * Open dialog to create a new contact.
   *
   * @param name initial name to use
   * @param type optional type to allow only creating a person or a company
   */
  openCreateContactDialog(name?: string, type?: ContactTypeEnum, idtMainCompany?: number, email?: string): MatDialogRef<ContactNewComponent> {
    const dialogRef = this.dialog.open(ContactNewComponent, {
      width: '700px',
      height: '75vh',
      panelClass: 'fullscreen-dialog-mobile',
      closeOnNavigation: true,
      data: {
        name,
        type,
        idtMainCompany,
        email,
      },
    });

    dialogRef.afterClosed().subscribe((data?: ContactNewDialogResponse) => {
      this.trackerService.event('contact_new', 'closed');

      if (data?.new) {
        this.dialogService.showSuccess('Contact created successfully');
      }
    });

    return dialogRef;
  }

  /**
   * Create a new contact.
   *
   * @param data the new contact data
   * @returns the created contact wrapped in an observable
   */
  create(data: CreateContactInputDTO): Observable<ContactViewTO> {
    return this.http.post<ContactViewTO>(`${environment.apiUrl}/contact`, data);
  }

  /**
   * Find a single contact by id.
   *
   * @param id the id of the contact
   * @returns the found contact
   */
  get(id: number): Observable<ContactInput> {
    return this.http.get<ContactInput>(`${environment.apiUrl}/contact/${id}`);
  }

  /**
   * Get all possible relationship types.
   *
   * @returns a list of relationship types
   */
  getRelationshipTypes(): Observable<ContactRelationshipType[]> {
    return this.http.get<ContactRelationshipType[]>(`${environment.apiUrl}/contact/relationship/type`);
  }

  /**
   * Gets the Balance of client
   * @param id contact id
   * @param reportingMonthDate the reference month of balance
   * @returns ClientBalance
   */
  findClientBalance(id: number, reportingMonthDate: DateTime | undefined, includeInactive = false): Observable<AccountBalanceTO[]> {
    const params: Record<string, any> = {
      includeInactive,
    };

    if (reportingMonthDate) {
      params.reportingMonthDate = reportingMonthDate.endOf('month').toISODate();
    }

    return this.http.get<AccountBalanceTO[]>(`${environment.apiUrl}/contact/${id}/balances`, { params });
  }

  /**
   * Export the contact balance data to an Excel file.
   *
   * @param id contact id
   * @param reportingMonthDate the reference month
   * @returns an observable that emits the file Blob when the server responds
   */
  exportContactBalance(id: number, reportingMonthDate: DateTime | undefined, includeInactive = false): Observable<Blob> {
    const params: Record<string, any> = {
      includeInactive,
    };

    if (reportingMonthDate) {
      params.reportingMonthDate = reportingMonthDate.endOf('month').toISODate();
    }

    return this.http.get<Blob>(`${environment.apiUrl}/contact/${id}/balances/export`, { params, responseType: 'blob' as 'json' });
  }

  /**
   * Finds the contact for the provided company id.
   *
   * @param idtCompany the id of the company
   * @returns the company found or null wrapped in an observable
   */
  getCompanyContact(idtCompany: number): Observable<ContactViewTO | null> {
    return this.getContactsViewPage(
      0,
      1,
      'idtContact,ASC',
      new DataFilter().equals('idtCompany', idtCompany, 'long').equals('type', 'COMPANY', 'contacttypeenum').encodeURIComponent(),
    ).pipe(map((cs) => (cs.content.length > 0 ? cs.content[0] : null)));
  }

  /**
   * Load a page of contacts from the provided company.
   *
   * @param idtCompany the id of the company
   * @param page the page to fetch
   * @param limit the size of the page
   * @returns the page fetched form the server
   */
  getCompanyContacts(idtCompany: number, page: number, limit: number): Observable<Pageable<ContactViewTO>> {
    let filter = new DataFilter();
    filter = filter.equals('type', ContactTypeEnum.PERSON, 'contacttypeenum');
    filter = filter.equals('idtCompany', idtCompany, 'long');
    filter = filter.equals('inactive', false, 'boolean');

    return this.getContactsViewPage(page, limit, 'name,ASC', filter.encodeURIComponent());
  }

  /**
   * Get a datasource for fecthing company contacts.
   *
   * @param idtCompany the id of the company
   * @returns the built datasource
   */
  getCompanyContactDatasource(idtCompany: number): PageableDataSource<ContactViewTO> {
    const getCompanyContacts = this.getCompanyContacts.bind(this);

    return new (class extends PageableDataSource<ContactViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<ContactViewTO>> {
        return getCompanyContacts(idtCompany, page, limit);
      }
    })(40);
  }

  /**
   * Search for the contacts consulted by the provided consultant.
   *
   * @param idtConsultant the id of the contact that is possibly a consultant
   * @returns an observable that emits the list of contacts consulted by the provide contact
   */
  getConsultantContacts(idtConsultant: number): Observable<ContactViewTO[]> {
    return this.http.get<ContactViewTO[]>(`${environment.apiUrl}/contact/consultant/${idtConsultant}`);
  }

  /**
   * Finds the key contact for the provided company id.
   *
   * @param idtCompany the id of the company
   * @returns the key contact of the company if any, wrapped in an observable
   */
  getKeyContact(idtCompany: number): Observable<ContactViewTO | null> {
    return this.getContactsViewPage(
      0,
      1,
      'idtContact,ASC',
      new DataFilter()
        .equals('idtCompany', idtCompany, 'long')
        .equals('keyContact', true, 'boolean')
        .equals('type', 'PERSON', 'contacttypeenum')
        .equals('inactive', false, 'boolean')
        .encodeURIComponent(),
    ).pipe(map((cs) => (cs.content.length > 0 ? cs.content[0] : null)));
  }

  /**
   * Get the contact channels for the provided contact id.
   *
   * @param idtContact the id of the contact
   * @returns the list of channels found
   */
  getChannels(idtContact: number, channel?: ContactChannelEnum): Observable<ContactChannel[]> {
    return this.http.get<ContactChannel[]>(`${environment.apiUrl}/contact/${idtContact}/channel`, {
      params: {
        ...(channel && { channel }),
      },
    });
  }

  /**
   * Save contact channels.
   *
   * @param idtContact the contact id
   * @param channels the list of channels to be saved
   * @param channelType the type of the channel being saved
   * @returns the list of saved channels wrapped in an observable
   */
  saveChannels(idtContact: number, channels: ContactChannel[], channelType: ContactChannelEnum): Observable<ContactChannel[]> {
    return this.http.put<ContactChannel[]>(`${environment.apiUrl}/contact/${idtContact}/channel`, channels, {
      params: {
        channelType,
      },
    });
  }

  /**
   * Get the contact jobs for the provided contact id.
   *
   * @param idtContact the id of the contact
   * @returns the list of jobs found
   */
  getJobs(idtContact: number): Observable<ContactJobViewTO[]> {
    return this.http.get<ContactJobViewTO[]>(`${environment.apiUrl}/contact/${idtContact}/job`);
  }

  /**
   * Save a list of jobs to a contact.
   *
   * @param idtContact the id of the contact
   * @param jobs the jobs to be saved
   * @returns the list of jobs after being saved wrapped in an observable
   */
  saveJobs(idtContact: number, jobs: ContactJobInputDTO[]): Observable<ContactJob[]> {
    return this.http.put<ContactJob[]>(`${environment.apiUrl}/contact/${idtContact}/jobs`, jobs);
  }

  /**
   * Save a list of jobs to a contact.
   *
   * @param idtContact the id of the contact
   * @param job the job data to be saved
   * @returns an observable that emits the saved job entity when the server responds
   */
  saveJob(idtContact: number, job: ContactJobInputDTO): Observable<ContactJob> {
    return this.http.put<ContactJob>(`${environment.apiUrl}/contact/${idtContact}/job`, job);
  }

  /**
   * Save a list of contacts as employees of the provided company.
   *
   * @param idtContact the contact id
   * @param idtEmployees the id of the employees
   * @returns an observable that emits void when the server responds
   */
  saveCompanyEmployees(idtContact: number, idtEmployees: number[]): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/employees`, idtEmployees);
  }

  /**
   * Get the social networks for the provided contact.
   *
   * @param idtContact the id of the contact
   * @returns the list of social networks found wrapped in an observable
   */
  getSocialMedia(idtContact: number): Observable<ContactSocialMedia[]> {
    return this.http.get<ContactSocialMedia[]>(`${environment.apiUrl}/contact/${idtContact}/social`);
  }

  /**
   * Save social media data for the provided contact.
   *
   * @param idtContact the id of the contact
   * @param socialMedias the social media data
   * @returns the list of social media saved wrapped in an observable
   */
  saveSocialMedia(idtContact: number, socialMedias: UpdateSocialInputDTO): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/social`, socialMedias);
  }

  getColumnsDefinitions(): Observable<Column[]> {
    return this.http.get<Column[]>(`${environment.apiUrl}/contact/columns`).pipe(shareReplay());
  }

  getGroups(filter: string, groupBy: string, sumColumns: string[]): Observable<Record<string, any>[]> {
    return this.http.post<Record<string, any>[]>(`${environment.apiUrl}/contact/searchGroup`, {
      filter,
      groupBy,
      sumColumns,
    });
  }

  getTotals(filter: string, sumColumns: string[]): Observable<Record<string, number>> {
    return this.http.post<Record<string, number>>(`${environment.apiUrl}/contact/search/totals`, {
      filter,
      sumColumns,
    });
  }

  export(filter: string, selectedColumns: string[], sort: string): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.apiUrl}/contact/search/export`,
      { selectedColumns, filter },
      { params: { sort }, responseType: 'blob' as 'json' },
    );
  }

  getPage(
    page: number,
    size: number,
    sort: string | string[],
    filter: string,
    selectedColumns?: string[],
    entities: string[] = [],
  ): Observable<Pageable<ContactFullTableViewTO>> {
    return this.http.post<Pageable<ContactFullTableViewTO>>(`${environment.apiUrl}/contact/search?page=${page}&size=${size}&sort=${sort}`, {
      filter,
      entities,
      selectedColumns,
    });
  }

  /**
   * Get all possible values for the provided column.
   *
   * @param filter filter logic to apply
   * @param column the column to get the values
   * @returns a list of values found
   */
  getOptions(filter: string, column: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/contact/options`, { params: { filter, column } }).pipe(map((gs) => gs.sort()));
  }

  /**
   * Get the contact consultant relation for the provided portfolio.
   *
   * @param idtContact the id of the contact
   * @param idtPortfolio the id of the portfolio
   * @returns the relationship entry wrapped in an observable
   */
  getContactConsultantPortfolio(idtContact: number, idtPortfolio: number): Observable<ClientConsultantViewTO> {
    return this.http.get<ClientConsultantViewTO>(`${environment.apiUrl}/contact/${idtContact}/consultant/${idtPortfolio}`);
  }

  /**
   * Gets a list of Consultants
   * @param id contact id
   * @returns Consultant
   */
  findUniqueConsultantsByIdtContact(id: number): Observable<ClientConsultantViewTO[]> {
    return this.http.get<ClientConsultantViewTO[]>(`${environment.apiUrl}/contact/${id}/consultant/unique`);
  }

  /**
   * Get consultants data for the provided contact.
   *
   * @param idtContact the id of the contact
   * @returns the list of consultant data found, wrapped in an observable
   */
  getConsultants(idtContact: number): Observable<ContactConsultantTO[]> {
    return this.http.get<ContactConsultantTO[]>(`${environment.apiUrl}/contact/${idtContact}/consultant`);
  }

  /**
   * Update a contact's consultants.
   *
   * @param idtContact the id of the contact
   * @param consultants the consultants data
   * @returns the list of consultant data saved, wrapped in an observable
   */
  updateConsultants(idtContact: number, consultants: UpdateConsultantDTO[]): Observable<ContactConsultantTO[]> {
    return this.http.put<ContactConsultantTO[]>(`${environment.apiUrl}/contact/${idtContact}/consultant`, consultants);
  }

  /**
   * Adds a new consultant to the provided contact.
   *
   * @param idtContact the contact id
   * @param consultant the consultant association data
   * @returns an observable that emits the saved association data
   */
  addConsultant(idtContact: number, consultant: ContactConsultantTO): Observable<ClientConsultantViewTO> {
    return this.http.post<ClientConsultantViewTO>(`${environment.apiUrl}/contact/${idtContact}/consultant`, consultant);
  }

  /**
   * Saves a contact's lead.
   *
   * @param idtContact the contact id
   * @param data the lead data to be saved
   * @returns an observable that emits void when the servers responds
   */
  updateLeads(idtContact: number, data: ContactLeadInputDTO): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/lead`, data);
  }

  /**
   * Get all available categories.
   *
   * @returns the list of categories found wrapped in an observable
   */
  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.apiUrl}/contact-category`);
  }

  /**
   * Save the base data for a contact.
   *
   * @param idtContact the id of the contact
   * @param data the data to be saved
   * @returns an observable that resolves to void when the server responds
   */
  updateBaseData(idtContact: number, data: ContactBaseInputDTO): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/base`, data);
  }

  /**
   * Update a contacts additional data.
   *
   * @param idtContact the id of the contact
   * @param data the data to be saved
   * @returns an observable that resolves to void when the server responds
   */
  updateAdditionalData(idtContact: number, data: any): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/additional`, data);
  }

  /**
   * Update a company contact parent company.
   *
   * @param idtContact the id of the company contact
   * @param idtParentCompany the id of the parent company, empty for setting as null
   */
  updateParantCompany(idtContact: number, idtParentCompany?: number): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/contact/${idtContact}/parentCompany`,
      {},
      { params: { ...(idtParentCompany && { idtParentCompany }) } },
    );
  }

  /**
   * Get a short duration url for the contact's image.
   *
   * @param idtContact the contact id
   * @returns an observable that emits the url to access the image or null
   */
  getImageUrl(idtContact: number): Observable<string | null> {
    return this.http.get<string | null>(`${environment.apiUrl}/contact/${idtContact}/image`);
  }

  /**
   * Update a contact's picture.
   *
   * @param idtContact the contact id
   * @param picture the new picture file
   * @returns an observable that emits void when the server responds
   */
  updatePicture(idtContact: number, picture: File): Observable<void> {
    const data = new FormData();
    data.append('picture', picture);

    return this.http.put<void>(`${environment.apiUrl}/contact/${idtContact}/picture`, data);
  }

  /**
   * Get the contacts default email entity.
   *
   * @param idtContact the contact id
   * @returns the email entity
   */
  getMainEmail(idtContact: number): Observable<ContactChannel> {
    return this.http.get<ContactChannel>(`${environment.apiUrl}/contact/${idtContact}/email`);
  }

  /**
   * Get the contacts default phone entity.
   *
   * @param idtContact the contact id
   * @returns the phone entity
   */
  getMainPhone(idtContact: number): Observable<ContactChannel> {
    return this.http.get<ContactChannel>(`${environment.apiUrl}/contact/${idtContact}/phone`);
  }

  /**
   * Finds contacts by email.
   *
   * @param email the email address to search for
   * @param idtContact the contact id to ignore from the search
   * @returns an observable that emits the contacts found or null
   */
  findByAnyEmail(email: string, idtContact?: number | null): Observable<ContactViewTO[]> {
    return this.http.get<ContactViewTO[]>(`${environment.apiUrl}/contact`, {
      params: {
        email,
        ...(idtContact && { idtContact }),
      },
    });
  }

  /**
   * Get the values for each tag in the provided contact.
   *
   * @param idtContact the contact id
   * @returns the values for each tag
   */
  getTagValues(idtContact: number): Observable<Record<number, string[]>> {
    return this.http.get<Record<number, string[]>>(`${environment.apiUrl}/contact/${idtContact}/tag`);
  }

  /**
   * Send request to check if a contact may be inactivated.
   *
   * @param idtContact the contact id
   * @returns observable that emits object with data about what blocks the contact inactivation
   */
  checkIfCanInactivateContact(idtContact: number) {
    return this.http.get<CanInactivateContactResponseDTO>(`${environment.apiUrl}/contact/${idtContact}/canInactivate`);
  }

  /**
   * Inactivate the contact so it no longer shows in the system.
   *
   * @param idtContact the contact id
   * @returns an observable that emits void when the server responds
   */
  inactivateContact(idtContact: number) {
    return this.http.delete<void>(`${environment.apiUrl}/contact/${idtContact}`);
  }
}

/**
 * Resolve service to get a contact based on the route id.
 */
export const contactResolver: ResolveFn<ContactViewTO> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  contactService: ContactService = inject(ContactService),
  snackBar: MatSnackBar = inject(MatSnackBar),
  router: Router = inject(Router),
) => {
  return contactService.getInfoById(Number(route.paramMap.get('id'))).pipe(
    tap((contact) => {
      if (contact.inactive) {
        snackBar.open('Contact was deleted', 'Close', { duration: 5000 });
        router.navigate(['/']);
      }
    }),
    catchError((err) => {
      if (err.status === 403) {
        snackBar.open(err.error.detail, 'Close', { duration: 10000 });
      }

      return EMPTY;
    }),
  );
};
