import { ContactTypeEnum } from 'app/modules/common/business/contact/model/contact.model';
import { Country } from './country.model';
import { State } from './state.model';

/**
 * Represents the Address
 */
export interface Address {
  idtAddress: number;
  idtCountry: number;
  idtState: number;
  name: string;
  location: string;
  city: string;
  province: string;
  zipCode: string;
  type: AddressTypeEnum;
  country?: Country;
  state?: State;
  version: number;
}

/**
 * Represents the List of Address
 */
export interface ContactAddressViewTO {
  idtContactAddress: number;
  idtAddress: number;
  idtContact: number;
  name: string;
  location: string;
  city: string;
  province: string;
  zipCode: string;
  type: AddressTypeEnum;
  country: string;
  countryAbbreviation: string;
  state: string;
  stateAbbreviation: string;
  branch: boolean;
  contactType: ContactTypeEnum;
  idtCountry: number;
  idtState: number;
}

export interface UpdateContactAddressDTO {
  idtFavoriteAddress: number | null;
  addresses: {
    idtAddress: number | null;
    idtCountry: number | null;
    idtState: number | null;
    stateName: string | null;
    name: string | null;
    location: string | null;
    city: string | null;
    zipCode: string | null;
    type: AddressTypeEnum | null;
    favorite: boolean;
  }[];
}

/**
 * Represents the types of address
 */
export enum AddressTypeEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

/**
 * List of importantant countri ids.
 */
export class CountriesIds {
  static USA = 228;
}
