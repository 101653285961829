import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientAllocation } from '../../../client/model/client-allocation.model';
import { ConsultantAllocation } from '../../../client/model/consultant-allocation.model';
import { OpportunitiesByConsultantTO, OpportunityViewTO } from '../../../opportunity/model/opportunity.model';
import { PortfolioAllocation } from '../../../portfolio/model/portfolio-allocation.model';
import { LogDetailViewTO, LogTotal, LogTypeEnum, LogTypeLabel } from '../../model/log.model';
import { LogService } from '../../services/log.service';

/**
 * List of logs for home: lists all logs optionally filtered by client or external rep.
 */
@Component({
    selector: 'app-log-list-home',
    templateUrl: './log-list-home.component.html',
    styleUrls: ['./log-list-home.component.scss'],
    standalone: false
})
export class LogListHomeComponent implements OnChanges {
  @Input()
  client?: ClientAllocation;

  /**
   * The external rep.
   */
  @Input()
  consultant?: ConsultantAllocation;

  /**
   * The selected opportunity.
   */
  @Input()
  opportunity?: OpportunityViewTO;

  /**
   * The selected portfolio, used to display only.
   */
  @Input()
  portfolio?: PortfolioAllocation;

  /**
   * Opportunities data for a consultant.
   */
  @Input()
  opportunityConsultant?: OpportunitiesByConsultantTO;

  /**
   * Log type
   */
  private logType!: LogTypeEnum;

  /**
   * The data to be displayed.
   */
  logData!: PageableDataSource<LogDetailViewTO>;

  /**
   * Total logs by type.
   */
  $logTotals!: Observable<LogTotal[]>;

  /**
   * Total logs.
   */
  logCount!: number;

  /**
   * The selected log.
   */
  selectedLog?: LogDetailViewTO;

  /**
   * Whehter to show selection filter chips.
   */
  @Input()
  showChip = false;

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  portfolioRemoved = new EventEmitter<void>();

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  clientRemoved = new EventEmitter<void>();

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  consultantRemoved = new EventEmitter<void>();

  /**
   * Event emitted if the opportunity is removed.
   */
  @Output()
  opportunityRemoved = new EventEmitter<void>();

  /**
   * Event emitted if the opportunities by consultant is removed.
   */
  @Output()
  opportunityConsultantRemoved = new EventEmitter<void>();

  private idtContact?: number;

  constructor(
    private logService: LogService,
    private trackerService: TrackerService,
    private router: Router,
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    const idtContact = this.client?.idtContact || this.consultant?.idtExternalRep;

    this.idtContact = idtContact;
    this.setLogData();
  }

  /**
   * Gets the data from the server.
   */
  private setLogData(): void {
    this.selectedLog = undefined;
    const self = this;
    const pageSize = 50;

    this.logData = new (class extends PageableDataSource<LogDetailViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<LogDetailViewTO>> {
        return self.logService.findLogs(
          self.idtContact,
          self.logType,
          self.opportunity?.idtOpportunity || self.opportunityConsultant?.idtOpportunities,
          page,
          limit,
        );
      }
    })(pageSize);

    this.setTotals();
  }

  /**
   * Find and populate the summary of logs by type.
   */
  private setTotals(): void {
    this.$logTotals = this.logService
      .findLogsTotals(this.idtContact, this.opportunity?.idtOpportunity || this.opportunityConsultant?.idtOpportunities)
      .pipe(
        tap((items) => {
          this.logCount = items.map((item) => item.total).reduce((item, total) => (total += item), 0);
        }),
      );
  }

  /**
   * Log selection.
   */
  onLogClick(eventData: { log: LogDetailViewTO; index: number }): void {
    this.trackerService.event('home', 'open_log');
    this.selectedLog = eventData.log;
  }

  /**
   * Open the log for edit in the sidenav.
   */
  editLog(): void {
    this.trackerService.event('home', 'edit_log');
    this.router.navigate(['', { outlets: { sidenav: ['log', this.selectedLog?.idtLog, 'edit'] } }]);
  }

  /**
   * Close the opened log.
   */
  closeLog(): void {
    this.trackerService.event('home', 'close_log');
    this.selectedLog = undefined;
  }

  /**
   * Filter the logs by the type selected.
   */
  setLogType(type?: string): void {
    this.logType = type ? (LogTypeEnum as any)[type] : null;
    this.trackerService.event('home', 'filter_log', { type: this.logType || 'All' });
    this.setLogData();
  }

  /*
   * Label for the log type enum.
   */
  getLogTypeLabel(type: string): string {
    return LogTypeLabel.getValue(type as LogTypeEnum);
  }

  removePortfolio(): void {
    this.portfolioRemoved.emit();
  }

  removeClient(): void {
    this.clientRemoved.emit();
  }

  removeConsultant(): void {
    this.consultantRemoved.emit();
  }

  removeOpportunity(): void {
    this.opportunityRemoved.emit();
  }

  removeOpportunityConsultant(): void {
    this.opportunityConsultantRemoved.emit();
  }
}
