import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeesFlowsOverrideDialogComponent } from '../components/fees-flows-override-dialog/fees-flows-override-dialog.component';
import {
  FeesAndFlowSummaryViewTO,
  FeesAndFlowsAccountViewTO,
  FeesAndFlowsAnnualByMonthViewTO,
  FeesAndFlowsAnnualByPortfolioViewTO,
  FeesAndFlowsEmployeeTO,
  FeesAndFlowsGlobalConfigurationTO,
  FeesAndFlowsOverrideInputDTO,
  FeesAndFlowsOverridePerEmployeeViewTO,
  FeesAndFlowsOverrideTO,
} from '../models/fees-flows.model';

/**
 * Service for fees and flows operations.
 */
@Injectable({
  providedIn: 'root',
})
export class FeesFlowsService {
  /**
   * Subject to emit events when global configuration changes, to trigger reloading data.
   */
  private onConfigChangesSubject = new BehaviorSubject<void>(undefined);

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  /**
   * Get fees and flows configuration data for the provided year.
   *
   * @param year the year to get the configurations
   * @returns an observable that emits the found configuration data
   */
  getConfiguration(year: number): Observable<FeesAndFlowsGlobalConfigurationTO> {
    return this.http.get<FeesAndFlowsGlobalConfigurationTO>(`${environment.apiUrl}/fees-and-flows/configuration`, {
      params: {
        year,
      },
    });
  }

  /**
   * Saves a global fees and flows configuration.
   *
   * @param data the global cofiguration data to be saved
   * @returns an observable that emits the saved instance when the server responds
   */
  saveConfiguration(data: FeesAndFlowsGlobalConfigurationTO): Observable<FeesAndFlowsGlobalConfigurationTO> {
    return this.http.post<FeesAndFlowsGlobalConfigurationTO>(`${environment.apiUrl}/fees-and-flows/configuration`, data).pipe(
      tap(() => {
        this.onConfigChangesSubject.next();
      }),
    );
  }

  /**
   * Gets the fees and flows summary data for all employees.
   *
   * @param year the year to search for
   * @returns an observable that emits the summary data when the server responds
   */
  getSummary(year: number): Observable<FeesAndFlowSummaryViewTO[]> {
    return this.http.get<FeesAndFlowSummaryViewTO[]>(`${environment.apiUrl}/fees-and-flows/summary`, {
      params: {
        year,
      },
    });
  }

  /**
   * Gets the fees and flows summary data for a single employee.
   *
   * @param year the year to search for
   * @param idtUser the employee user to search for
   * @returns an observable that emits the summary data when the server responds
   */
  getSummaryForUser(year: number, idtUser: number): Observable<FeesAndFlowSummaryViewTO> {
    return this.http.get<FeesAndFlowSummaryViewTO>(`${environment.apiUrl}/fees-and-flows/summary`, {
      params: {
        year,
        idtUser,
      },
    });
  }

  /**
   * Get a page of accounts with employee data from the server.
   *
   * @param page page number
   * @param size page size
   * @param sort sort columns and direction
   * @param filter filters to apply
   * @returns an observable that emits a list of data found when the server responds
   */
  getAccountsForUser(
    page: number,
    size: number,
    sort: string | string[],
    filter: string,
  ): Observable<Pageable<FeesAndFlowsOverridePerEmployeeViewTO>> {
    return this.http.get<Pageable<FeesAndFlowsOverridePerEmployeeViewTO>>(`${environment.apiUrl}/fees-and-flows/account/employee`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sort,
        filter: filter,
      },
    });
  }

  /**
   * Get a page of accounts fees data.
   *
   * @param page page number
   * @param size page size
   * @param sort sort columns and direction
   * @param filter filters to apply
   * @returns an observable that emits a list of data found when the server responds
   */
  getAccounts(page: number, size: number, sort: string | string[], filter: string): Observable<Pageable<FeesAndFlowsAccountViewTO>> {
    return this.http.get<Pageable<FeesAndFlowsAccountViewTO>>(`${environment.apiUrl}/fees-and-flows/account`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sort,
        filter,
      },
    });
  }

  /**
   * Open a dialog to edit override data for the provided account in the provided year.
   *
   * @param year the year
   * @param idtAccount the account id
   * @returns the dialog reference
   */
  openOverrideDialog(year: number, idtAccount: number): MatDialogRef<FeesFlowsOverrideDialogComponent> {
    return this.dialog.open(FeesFlowsOverrideDialogComponent, {
      data: {
        idtAccount,
        year,
      },
      width: '500px',
    });
  }

  /**
   * Get override data for the provided account in the provided year.
   *
   * @param year the year
   * @param idtAccount the account id
   * @returns an observable that emits the override data
   */
  getOverrides(year: number, idtAccount: number): Observable<FeesAndFlowsOverrideTO> {
    return this.http.get<FeesAndFlowsOverrideTO>(`${environment.apiUrl}/fees-and-flows/account/override`, {
      params: {
        year,
        idtAccount,
      },
    });
  }

  /**
   * Get the associated employees for the provided accounts in the provided year.
   *
   * @param year the year
   * @param idtAccount the account id
   * @returns an observable that emits the associated employees data
   */
  getEmployees(year: number, idtAccount: number): Observable<FeesAndFlowsEmployeeTO[]> {
    return this.http.get<FeesAndFlowsEmployeeTO[]>(`${environment.apiUrl}/fees-and-flows/account/employees`, {
      params: {
        year,
        idtAccount,
      },
    });
  }

  /**
   * Save client fees overrides.
   */
  setOverrides(data: FeesAndFlowsOverrideInputDTO): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/fees-and-flows/override`, data).pipe(
      tap(() => {
        this.onConfigChangesSubject.next();
      }),
    );
  }

  /**
   * Get an observable that emits event whenever the global configuration changes.
   *
   * @returns the observable
   */
  onConfigChanges(): Observable<void> {
    return this.onConfigChangesSubject.asObservable();
  }

  getAnnualFlowsPerPortfolio(year: number): Observable<FeesAndFlowsAnnualByPortfolioViewTO[]> {
    return this.http.get<FeesAndFlowsAnnualByPortfolioViewTO[]>(`${environment.apiUrl}/fees-and-flows/annual/portfolio`, { params: { year } });
  }

  getAnnualFlowsPerMonth(year: number): Observable<FeesAndFlowsAnnualByMonthViewTO[]> {
    return this.http.get<FeesAndFlowsAnnualByMonthViewTO[]>(`${environment.apiUrl}/fees-and-flows/annual/month`, { params: { year } });
  }

  /**
   * Get data to export.
   *
   * @param sort sort columns and direction.
   * @param filter filters to apply.
   * @returns a file exported.
   */
  exportExcel(filter: string, sort: string | string[]): Observable<Blob> {
    const params = {
      filter,
      sort,
    };

    return this.http.get<Blob>(`${environment.apiUrl}/fees-and-flows/export`, { params, responseType: 'blob' as 'json' });
  }

  /**
   * Get data to export.
   *
   * @param sort sort columns and direction.
   * @param filter filters to apply.
   * @returns a file exported.
   */
  exportExcelSummaryWithAccounts(idtUser: number, year: number, sort: string | string[]): Observable<Blob> {
    const params = {
      idtUser,
      year,
      sort,
    };

    return this.http.get<Blob>(`${environment.apiUrl}/fees-and-flows/export/summary-account`, { params, responseType: 'blob' as 'json' });
  }

  /**
   * Get a list of years.
   *
   * @returns the years list.
   */
  getYears(): Observable<number[]> {
    return this.http.get<number[]>(`${environment.apiUrl}/fees-and-flows/years`);
  }
}
