import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, input, OnChanges, output, signal } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { SidenavService } from 'app/modules/common/framework/services/sidenav.service';
import { OpportunityExceptionViewTO } from 'app/modules/routes/exception-report/models/exception-report.model';
import { OpportunityViewTO } from '../../model/opportunity.model';

@Component({
    selector: 'app-opportunity-exceptions-table',
    templateUrl: './opportunity-exceptions-table.component.html',
    styleUrl: './opportunity-exceptions-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OpportunityExceptionsTableComponent implements OnChanges {
  /**
   * The data to be displayed in the table.
   */
  dataSource = input.required<CdkTableDataSourceInput<OpportunityExceptionViewTO>>();

  /**
   * Whether to show action buttons column.
   */
  showActions = input<boolean>(true);

  /**
   * Whether loading data from the server.
   */
  loading = input.required<boolean>();

  /**
   * Output for notifying parent components of sort changes.
   */
  sortChange = output<Sort>();

  /**
   * The columns to be displayed in the table.
   */
  displayedColumns = signal([
    'status',
    'portfolio',
    'contact',
    'consultantCompany',
    'lead',
    'leadBackup',
    'lastLogDate',
    'nextContactDate',
    'months',
    'size',
  ]);

  /**
   * Before printing, remove the columns backup lead and actions, as they are not needed in the printed version.
   *
   * Notice, we must explicitly call for detect changes, to make sure it completes and renders the data before the print dialog is opened.
   */
  @HostListener('window:beforeprint')
  onBeforePrint(): void {
    this.displayedColumns.set(['status', 'portfolio', 'contact', 'consultantCompany', 'lead', 'lastLogDate', 'nextContactDate', 'months', 'size']);
    this.cdr.detectChanges();
  }

  /**
   * After printing, restore columns to the original configuration.
   */
  @HostListener('window:afterprint')
  onAfterPrint(): void {
    this.displayedColumns.set([
      'status',
      'portfolio',
      'contact',
      'consultantCompany',
      'lead',
      'leadBackup',
      'lastLogDate',
      'nextContactDate',
      'months',
      'size',
    ]);

    this.addActionsColumn();
  }

  constructor(
    private sidenavService: SidenavService,
    private cdr: ChangeDetectorRef,
  ) {}

  /**
   * On input changes, make sure the "actions" column is included if the flag is active.
   */
  ngOnChanges() {
    this.addActionsColumn();
  }

  /**
   * Adds the "actions" columns to the list of displayed columns if necessary.
   */
  addActionsColumn() {
    if (this.showActions() && !this.displayedColumns().includes('actions')) {
      this.displayedColumns().push('actions');
    }
  }

  /**
   * Track function to avoid rerenders when list changes.
   *
   * @param index the index in the list
   * @param value the opportunity data
   * @returns the id of the opportunity
   */
  trackOpportunity(index: number, value: OpportunityExceptionViewTO) {
    return value.idtOpportunity;
  }

  /**
   * Open sidenav to create a log associated with the provided opportunity exception.
   *
   * @param item the opporutnity exception item
   */
  async createLog(item: OpportunityViewTO) {
    // Close the sidenav to force reloading the contacts and
    await this.sidenavService.close();

    this.sidenavService.navigate(['log', 'new'], {
      queryParams: {
        'sidenav.idtContacts': item.idtContact,
        'sidenav.idtPortfolio': item.idtPortfolio,
        'sidenav.idtOpportunity': item.idtOpportunity,
      },
    });
  }

  /**
   * Handle sorting changes, emit event so the parent component handles it.
   * @param sort the new sorting configuration
   */
  onSortChange(sort: Sort) {
    this.sortChange.emit(sort);
  }
}
