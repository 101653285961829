import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Directive to focus on component as soon as it is rendered.
 */
@Directive({
    selector: '[appAutoFocus]',
    standalone: false
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    // We need to wait a little to avoid race conditions with dialog focus traps and initial page load focus traos
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 100);
  }
}
