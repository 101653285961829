import { ChangeDetectionStrategy, Component, effect, input, signal } from '@angular/core';
import { IndexXtdTO } from 'app/modules/common/business/index/models/index.model';
import { IndexService } from 'app/modules/common/business/index/services/index.service';
import { PortfolioPerformanceTO } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DateTime } from 'luxon';

/**
 * Component for a portfolio with performance item in the home page list.
 */
@Component({
    selector: 'app-portfolio-performance-item',
    templateUrl: './portfolio-performance-item.component.html',
    styleUrl: './portfolio-performance-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortfolioPerformanceItemComponent {
  /**
   * The portfolio data.
   */
  portfolio = input.required<PortfolioPerformanceTO>();

  /**
   * Whether to show the index data.
   */
  showIndex = signal(false);

  // When the selected tab is changed, also change the query params, so we can refresh or share the url and open at the same tab.
  private showIndexEffect = effect(() => {
    if (this.showIndex()) {
      this.trackerService.event('home', 'expand_index', { portfolio: this.portfolio().ticker });

      if (!this.indexData()) {
        this.loadIndexData();
      }
    }
  });

  /**
   * The index data.
   */
  indexData = signal<IndexXtdTO | null>(null);

  constructor(
    private indexService: IndexService,
    private trackerService: TrackerService,
  ) {}

  /**
   * Toggle wether to show index data or not.
   */
  toggleShowIndex(): void {
    if (this.portfolio().idtIndex && this.portfolio().asOf) {
      this.showIndex.update((i) => !i);
    }
  }

  /**
   * Fetch the index data.
   */
  loadIndexData(): void {
    this.indexService.getPortfolioIndexXtd(this.portfolio().idtPortfolio, DateTime.fromISO(this.portfolio().asOf)).subscribe((data) => {
      this.indexData.set(data);
    });
  }
}
