import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Chip } from 'app/modules/common/framework/model/chip.model';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { ChipService } from 'app/modules/common/framework/services/chip.service';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { tap } from 'rxjs/operators';
import { DocumentEventEnum } from '../../DocumentEventEnum';
import { Document } from '../../model/document.model';

@Component({
    selector: 'app-contact-documents',
    templateUrl: './contact-documents.component.html',
    styleUrls: ['./contact-documents.component.scss'],
    standalone: false
})
export class ContactDocumentsComponent implements OnInit {
  /**
   * The id of the contact.
   */
  private _idtContact!: number;

  /**
   * Setter for the id of the contact.
   */
  @Input()
  set idtContact(value: number) {
    this._idtContact = value;

    this.setData();
    this.setChipData();
  }

  /**
   * Getter for the id of the contact.
   */
  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * If it should keep the chips in a single line.
   */
  @Input()
  singeLineChips = false;

  /**
   * The data for the chips.
   */
  chipData: Chip[] = [];

  documentData!: PageableDataSource<Document>;

  /**
   * Event to emit when the logs are loading.
   */
  @Output()
  loadingChanged = new EventEmitter<boolean>();

  constructor(
    private eventService: EventQueueService,
    private contactService: ContactService,
    private chipService: ChipService,
    private trackerService: TrackerService,
    private destroyRef: DestroyRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.eventService
      .on(DocumentEventEnum.documentSaved)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setData();
        this.setChipData();
      });
  }

  /**
   * Sets the documents list datasource based on the contact id.
   */
  private setData(): void {
    this.documentData = this.contactService.findDocuments(this.idtContact);

    this.documentData.loading$.subscribe((loading) => {
      setTimeout(() => {
        this.loadingChanged.emit(loading);
      });
    });
  }

  /**
   * Sets the chips data.
   */
  private setChipData(): void {
    this.contactService
      .getDocsTotal(this.idtContact)
      .pipe(
        tap((data) => {
          this.chipData = this.chipService.getChipListOfTotalType(data);
        }),
      )
      .subscribe();
  }

  /**
   * Open sidenav when the chip of logs or docs are clicked.
   *
   * @param type the type of the clicked chip
   */
  onChipClick(type: Chip): void {
    this.trackerService.event('contact', 'expand_documents', { type: type.value });
    this.router.navigate(['', { outlets: { sidenav: ['doc', 'contact', this.idtContact.toString()] } }], {
      queryParams: { 'sidenav.type': type.value },
    });
  }
}
