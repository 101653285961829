@if (showSearch) {
  <mat-form-field class="vertical-align" subscriptSizing="dynamic">
    <mat-label>Search</mat-label>
    <input matInput type="text" [formControl]="searchField" />
  </mat-form-field>
  <mat-divider />
}

@if (!external && (hasBlackoutOpportunity$ | async)) {
  <app-alert-box><span class="tw-uppercase">Do not contact them regarding the pending RFP</span></app-alert-box>
}

@if (contactData && !contactData.hasData) {
  <app-no-data [entity]="'contacts'" />
}

<app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
  <cdk-virtual-scroll-viewport
    class="tw-flex tw-flex-1 tw-flex-col"
    itemSize="50"
    [ngClass]="{ 'abs-scroll-hidden': !contactData || (contactData.loading && contactData.totalElements === 0) }"
  >
    @let datasource = contactData || externalContactsData;

    <!-- Sekeleton items while loading -->
    <app-list-skeleton-loader
      [lines]="2"
      [height]="50"
      [showAvatar]="true"
      [items]="10"
      [loading]="!datasource || (datasource.loading && datasource.totalElements === 0)"
    />

    @if (this.external) {
      <app-contact-item
        *cdkVirtualFor="let contact of externalContactsData"
        [contact]="contact"
        [active]="idtContact === contact.idtContact"
        [showInvestmentStatus]="showInvestmentStatus()"
      />
    } @else {
      <app-contact-item
        *cdkVirtualFor="let contact of contactData"
        [contact]="contact"
        [active]="idtContact === contact.idtContact"
        [showInvestmentStatus]="showInvestmentStatus()"
      />
    }
  </cdk-virtual-scroll-viewport>
</app-custom-list>
