import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { PortfolioAllocation } from '../../model/portfolio-allocation.model';

/**
 * Component that represents a portfolio item.
 */
@Component({
    selector: 'app-portfolio-allocation-item',
    templateUrl: './portfolio-allocation-item.component.html',
    styleUrls: ['./portfolio-allocation-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortfolioAllocationItemComponent {
  /**
   * Portfolio item
   */
  portfolio = input.required<PortfolioAllocation>();

  /**
   * Selected portfolioId
   */
  idtPortfolio = input<number>();

  /**
   * Emit selection event when a portfolio is clicked
   */
  @Output()
  selectEmitter = new EventEmitter<PortfolioAllocation>();

  /**
   * Ungrouped control
   */
  ungrouped = input<boolean>(false);

  /**
   * Emit the selected portfolio
   *
   * @param portfolio clicked portfolio info
   */
  select(portfolio?: PortfolioAllocation) {
    this.selectEmitter.emit(portfolio);
  }
}
