import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-custom-list-item',
    templateUrl: './custom-list-item.component.html',
    styleUrls: ['./custom-list-item.component.scss'],
    standalone: false
})
export class CustomListItemComponent {
  /**
   * Whether the item should show with the selected style.
   */
  @Input()
  selected = false;
}
