import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-output',
    templateUrl: './output.component.html',
    styleUrls: ['./output.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OutputComponent {
  @Input({ required: true }) label!: string;
}
