import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvestmentStatus } from '../../business/contact/model/contact.model';

/**
 * Component to display a contact's investment status with proper color scheme.
 */
@Component({
    selector: 'app-investment-status',
    templateUrl: './investment-status.component.html',
    styleUrls: ['./investment-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvestmentStatusComponent {
  /**
   * The investment status string.
   */
  @Input()
  investmentStatus!: InvestmentStatus;

  /**
   * Appearance options.
   */
  @Input()
  appearance: 'normal' | 'outline' = 'normal';

  /**
   * Whether to show as a small badge.
   */
  @Input()
  short = false;

  /**
   * Get a shorter version of the investment status.
   */
  get shortInvestmentStatus(): string {
    switch (this.investmentStatus) {
      case 'Invested':
        return 'I';
      case 'Not Invested':
        return 'NI';
      case 'Consultant':
        return 'C';
      case 'Former Investor':
        return 'FI';
      case 'Opportunity':
        return 'O';
      default:
        return '';
    }
  }
}
