import { Company } from 'app/modules/common/business/company/model/company.model';
import { Person } from 'app/modules/common/business/person/model/person.model';
import { Address } from '../../address/model/address.model';
import { ContactConsultantTO } from '../../client/model/client-balance.model';
import { ContactChannel, ContactChannelTypeEnum } from './contact-channel.model';
import { ContactJob } from './contact-job.model';
import { ContactSocialMedia } from './contact-social-media.model';

/**
 * Represents the Contact
 */
export interface ContactViewTO extends BasicContact {
  idtClient?: number;
  idtPerson: number;
  department: string;
  contactGift: string;
  type: ContactTypeEnum;
  creationDate: Date;
  contactKey: string;
  contactNotes: string;
  anniversaryDate: Date;
  spouseName: string;
  businessRole: string;
  email: string;
  phone: string;
  companyName: string;
  picture: string;
  keyContact: boolean;
  inactive: boolean;
  idtLead: number;
  lead?: string;
  leadName?: string;
  idtLeadBackup: number;
  leadBackup?: string;
  leadBackupName?: string;
  clientInactive?: boolean;
  idtCategory: number;
  category: string;
  idtSubcategory: number;
  subcategory: string;
  parentCompanyName: string;
  title?: string;
  investmentStatus: InvestmentStatus;
  idtParentCompany: number | null;
  parent: boolean;
  branch: boolean;
  internal: boolean;
  idtParentCompanyContact?: number;
  idtCompanyContact?: number;
  version: number;
  idtFavoriteAddress?: number;
  shirtSize: string;
  consultant: boolean;
  personalInterests: string[];
  salutation: string;
  branchName: string;
}

export enum InvestmentStatusEnum {
  INVESTED = 'Invested',
  NOT_INVESTED = 'Not Invested',
  CONSULTANT = 'Consultant',
  FORMER_INVESTOR = 'Former Investor',
  OPPORUTNITY = 'Opportunity',
  INACTIVE = 'Inactive',
}

/**
 * Investment status options.
 */
export type InvestmentStatus = 'Invested' | 'Not Invested' | 'Consultant' | 'Former Investor' | 'Opportunity' | 'Inactive';

/**
 * Contact type Enum
 */
export enum ContactTypeEnum {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

/**
 * Represents the Contact List
 */
export interface ContactList extends BasicContact {
  id?: number;
  idtPerson: number;
  idtParentCompany: number;
  type: ContactTypeEnum;
  companyName: string;
  businessRole: string;
  relationship: string;
  email: string;
  phone: string;
  picture: string;
  keyContact: boolean;
  inactive: boolean;
  investmentStatus: InvestmentStatus;
  internal: boolean;
}

/**
 * A contact relationship model.
 */
export interface ContactRelationshipTO {
  idtContactTo: number | null;
  idtContactFrom?: number | null;
  idtContactRelationshipType: number | null;
  contactTo?: ContactViewTO;
}

/**
 * Contact relationship view data.
 */
export interface ContactRelationshipViewTO {
  idtContactRelationship: number;
  idtContactTo: number;
  idtContactFrom: number;
  relationship: string;
  name: string;
  internal: boolean;
  email: string;
  phone: string;
  picture: string;
  idtContact: number;
  idtParentCompany: number;
  type: ContactTypeEnum;
  companyName: string;
  idtCompanyContact: number;
}

/**
 * A contact relationship type.
 */
export interface ContactRelationshipType {
  idtContactRelationshipType: number;
  relationship: string;
}

/**
 * Represents the contact relationship client
 */
export interface ContactRelationshipClient {
  idtContactRelationship: number;
  idtClient: number;
  idtContactFrom: number;
  idtContactTo: number;
  clientName: string;
  portfolioTotal: number;
  relationship: string;
  transactionValueBalance: number;
  transactionValueBalanceTotal: number;
}

/**
 * Represents a Contact input.
 */
export interface ContactInput extends BasicContact {
  idtPerson: number;
  idtLead: number;
  idtLeadBackup: number;
  type: ContactTypeEnum;
  picture: string;
  inactive: boolean;
  giftContactType: string;
  contactKey: string;
  contactNotes: string;
  version: number;
  person: Person;
  company: Company;
  address: Address[];
  jobs: ContactJob[];
  channels: ContactChannel[];
  contactEmail: ContactChannel[];
  contactPhone: ContactChannel[];
  socialMedia: ContactSocialMedia[];
  relationships: ContactRelationshipTO[];
  idtCategory: number;
  idtSubcategory: number;
  consultants: ContactConsultantTO[];
  branch: boolean;
  mainCompany: boolean;
}

/**
 * DTO for creating new contacts.
 */
export interface CreateContactInputDTO {
  name: string;
  type: ContactTypeEnum;
  email?: string;
  emailType: ContactChannelTypeEnum;
  phone?: string;
  phoneType: ContactChannelTypeEnum;
  idtLead: number | null;
  idtLeadBackup: number | null;
  idtCompany?: number | null;
  jobRole?: string;
  idtMainCompany?: number;
}

/**
 * The basic contact data.
 */
export interface BasicContact {
  idtContact: number;
  idtCompany: number;
  name: string;
  type: ContactTypeEnum;
  branch: boolean;
}

/**
 * List os possible contact category values.
 */
export enum ContactCategory {
  CONSULTANT = 'Consultant',
}

/**
 * Contact data with all fields used in dynamic table.
 */
export interface ContactFullTableViewTO extends BasicContact {
  inactive: boolean;
  relationshipMaster: boolean;
  key: string;
  notes: string;
  giftType: string;
  birthdate: string;
  idtCompanyContact: string;
  company: string;
  idtParentContact: number;
  parentCompany: string;
  spouseName: string;
  salutation: string;
  title: string;
  creationDate: string;
  modificationDate: string;
  creationUser: string;
  lead: string;
  leadBackup: string;
  category: string;
  subcategory: string;
  country: string;
  state: string;
  location: string;
  zipCode: string;
  city: string;
  internal: boolean;
  emails: string[];
  phones: string[];
  branch: boolean;
}

/**
 * Data of indexed contacts.
 */
export interface ContactIndexedDTO extends BasicContact {
  companyName: string;
  picture: string;
  phone: string;
  email: string;
  investmentStatus: InvestmentStatus;
  keyContact: boolean;
  businessRole: string;
  idtParentCompany: number;
  internal: boolean;
  idtCompanyContact: number;
}

/**
 * Data for requests to update contact's basic info.
 */
export interface ContactBaseInputDTO {
  idtLead: number | null | undefined;
  idtLeadBackup: number | null | undefined;
  name: string;
  title: string | null | undefined;
  salutation: string | null | undefined;
  branchName: string | null | undefined;
  idtParentCompany: number | null | undefined;
}

/**
 * Data returned by the server when checkin if a contact can be deleted.
 */
export interface CanInactivateContactResponseDTO {
  hasLogs: boolean;
  hasDocuments: boolean;
  hasOpportunities: boolean;
  hasEmployees: boolean;
  mainCompany: boolean;
  hasAccounts: boolean;
  hasConsultedClients: boolean;
  canInactivate: boolean;
}

export interface ContactAdditionalDataInputDTO {
  spouseName: string;
  idtCategory: number;
  idtSubcategory: number;
  contactNotes: string;
  contactKey: string;
  shirtSize: string;
  tags: Record<number, string[]>;
}
