import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * Outputs an iframe, placing the given HTML contents inside it.
 */
@Component({
    selector: 'app-output-iframe',
    templateUrl: './output-iframe.component.html',
    styleUrls: ['./output-iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OutputIframeComponent implements AfterViewInit {
  @ViewChild('iframe') iframe!: ElementRef;

  @Input({ required: true })
  content!: string;

  ngAfterViewInit() {
    // insert the html content into the iframe
    const doc = this.iframe.nativeElement.contentDocument;
    doc.open();
    doc.write(this.content);
    doc.close();

    // ensure the iframe scrollbar matches the system-wide style
    doc.head.insertAdjacentHTML(
      'beforeend',
      `<style>
        *::-webkit-scrollbar { width: 6px !important; height: 6px !important; }
        *::-webkit-scrollbar-thumb { background-color: rgba(0, 0, 0, 0.2); }
        *::-webkit-scrollbar-track { background: #f5f5f5; }
      </style>`
    );
  }
}
