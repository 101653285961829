import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, input, Input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { filter } from 'rxjs/operators';
import { TrackerService } from '../../business/tracker/services/tracker.service';

/**
 * Interface for how context menu options object should be passed.
 */
export interface ContextMenuOption {
  icon: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routerLink?: any[];
  queryParams?: Record<string, string | number>;
  click?: () => void;
  type: 'button' | 'link';
}

/**
 * Component for context menu components.
 */
@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContextMenuComponent implements OnInit {
  /**
   * The text to display as the link.
   */
  @Input()
  displayText!: string;

  /**
   * Whether the context menu is closed.
   */
  isOpen = signal(false);

  /**
   * The options to display.
   */
  @Input()
  options: ContextMenuOption[] = [];

  /**
   * Router link to navigate to when left clicking the element.
   */
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: any[] = [];

  /**
   * When the context menu is opened.
   */
  @Output()
  menuOpen = new EventEmitter<void>();

  @Input()
  entity!: string;

  /**
   * Whether to disable the context menu and render as a simple text.
   */
  disabled = input<boolean>(false);

  private get trackFeature(): string {
    return `${this.entity}_context_menu`;
  }

  constructor(
    protected router: Router,
    private mediaObserver: MediaObserver,
    private trackerService: TrackerService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    // Make sure the context menu closes on any type of navigation
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e) => e instanceof NavigationStart),
      )
      .subscribe(() => {
        this.isOpen.set(false);
      });
  }

  /**
   * Open the context menu. Loads the contact data if not yet loaded.
   *
   * @param event the event
   */
  openContextMenu(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    // Make sure it was closed and then open
    // Avoids issues with closing using "esc" that doesn't update the flag
    this.isOpen.set(false);
    setTimeout(() => {
      this.isOpen.set(true);
    });

    this.menuOpen.emit();
    this.trackerService.event(this.trackFeature, 'open', { entity: this.displayText });
  }

  clicked(event: Event): void {
    event.stopPropagation();

    if (this.mediaObserver.isActive('lt-md')) {
      this.openContextMenu(event);
    } else {
      this.router.navigate(this.link);
    }
  }

  /**
   * Handle user clicking to close the menu.
   */
  close(): void {
    this.isOpen.set(false);
    this.trackerService.event(this.trackFeature, 'close', { entity: this.displayText });
  }

  /**
   * Tracks clicking on context menu options.
   *
   * @param option the clicked option
   */
  trackClick(option: ContextMenuOption): void {
    this.trackerService.event(this.trackFeature, 'click', { option: option.text });
  }
}
