import { animate, group, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PortfolioDpeConfigurationViewTO } from 'app/modules/common/business/portfolio/model/portfolio-dpe.model';
import { PortfolioDpeService } from 'app/modules/common/business/portfolio/services/portfolio-dpe.service';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { compareProductLine } from 'app/modules/common/business/portfolio/utils/portfolio.utils';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { groupBy } from 'lodash-es';

/**
 * Component for the dialog to configure DMS portfolios.
 */
@Component({
  selector: 'app-portfolio-dpe-configuration-dialog',
  templateUrl: './portfolio-dpe-configuration-dialog.component.html',
  styleUrl: './portfolio-dpe-configuration-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(25%)', opacity: 0.5 }),
        animate('0.3s ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
    trigger('shrinkAndFadeOut', [
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate('0.5s ease-out', style({ height: 0 })), animate('0.25s ease-out', style({ opacity: 0 }))]),
      ]),
    ]),
  ],
  standalone: false,
})
export class PortfolioDpeConfigurationDialogComponent implements OnInit {
  /**
   * The portfolios configured.
   */
  configuration = signal<PortfolioDpeConfigurationViewTO[]>([]);

  /**
   * The confgiured portfolios grouped by product line.
   */
  groupedConfiguration = computed(() => {
    return groupBy(this.configuration(), (c) => c.productLine);
  });

  /**
   * Whether waiting for the server to respond.
   */
  loading = signal(false);

  /**
   * Form control for the input the select portfolios.
   */
  portfolioSelectControl = new FormControl<number | null>(null);

  constructor(
    private portfolioDmsService: PortfolioDpeService,
    private portfolioService: PortfolioService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<PortfolioDpeConfigurationDialogComponent>,
  ) {}

  ngOnInit() {
    this.loadCurrentConfig();

    this.portfolioSelectControl.valueChanges.subscribe((v) => {
      if (v) {
        if (!this.configuration().some((p) => p.idtPortfolio === v)) {
          this.portfolioService.getPortfolioDetails(v).subscribe((p) => {
            this.configuration.update((configuration) => [
              ...configuration,
              {
                defaultClass: p.defaultClass,
                idtDefaultClass: p.idtDefaultClass,
                idtPortfolio: p.idtPortfolio,
                idtProductLine: p.idtProductLine,
                portfolio: p.shortName,
                productLine: p.productLine,
                productLineOrder: p.productLineOrder,
              },
            ]);
          });
        }

        this.portfolioSelectControl.setValue(null);
      }
    });
  }

  /**
   * Load the current saved configuration.
   */
  loadCurrentConfig() {
    this.portfolioDmsService
      .getDpeConfiguration()
      .pipe(trackLoading(this.loading))
      .subscribe((configuration) => {
        this.configuration.set(configuration);
      });
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioDpeConfigurationViewTO[]>, b: KeyValue<string, PortfolioDpeConfigurationViewTO[]>): number {
    return compareProductLine(a, b);
  }

  /**
   * Handle a portfolio being dragged and dropped.
   *
   * @param event the event data
   * @param group the group of the portfolio
   */
  drop(event: CdkDragDrop<string[]>, group: PortfolioDpeConfigurationViewTO[]): void {
    moveItemInArray(group, event.previousIndex, event.currentIndex);

    this.configuration.set(Object.values(this.groupedConfiguration()).flat());
  }

  /**
   * Remove the provided portfolio from the list.
   *
   * @param idtPortfolio the id of the portfolio to be removed
   */
  remove(idtPortfolio: number): void {
    this.configuration.update((config) => config.filter((p) => p.idtPortfolio !== idtPortfolio));
  }

  /**
   * Send request to save the configuration.
   */
  save() {
    const values = this.configuration();

    values.forEach((c, index) => {
      c.order = index;
      c.idtPortfolioDpeConfiguration = undefined;
    });

    this.portfolioDmsService
      .updateDpeConfiguration(this.configuration())
      .pipe(trackLoading(this.loading))
      .subscribe(() => {
        this.dialogService.showSuccess('Configuration saved successfully');
        this.dialogRef.close();
      });
  }
}
