import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactViewTO } from '../../../contact/model/contact.model';
import { ContactService } from '../../../contact/services/contact.service';

/**
 * Component for a dialog to add a new consultant to a contact.
 */
@Component({
  selector: 'app-add-consultant-dialog',
  templateUrl: './add-consultant-dialog.component.html',
  styleUrls: ['./add-consultant-dialog.component.scss'],
  standalone: false,
})
export class AddConsultantDialogComponent {
  /**
   * The form group to hold the consultant data to be saved.
   */
  formGroup = this.fb.group({
    consultant: new FormControl<ContactViewTO | null>(null),
    externalRep: new FormControl<ContactViewTO | null>(null, Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddConsultantDialogComponent>,
    private contactService: ContactService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: { idtContact: number },
  ) {}

  /**
   * Save the consultant.
   */
  save(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const values = this.formGroup.getRawValue();

      this.contactService
        .addConsultant(this.data.idtContact, {
          idtContact: this.data.idtContact,
          idtConsultant: values.consultant?.idtContact,
          idtExternalRep: values.externalRep?.idtContact,
        })
        .pipe(
          catchError((err) => {
            this.dialogService.showError(err.error?.message || 'Error saving consultant');

            return throwError(() => err);
          }),
        )
        .subscribe((consultant) => {
          this.dialogRef.close(consultant);
        });
    }
  }
}
