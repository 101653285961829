import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to join an array with the provided separator.
 *
 * Nullish values (including empty strings) are ignored and no separator is added for them.
 */
@Pipe({
    name: 'join',
    standalone: false
})
export class JoinPipe implements PipeTransform {
  transform(input: (string | null | undefined)[], separator: string = ''): string {
    if (!Array.isArray(input)) {
      return input;
    }

    const values = input.filter((s) => s != null && s?.length > 0);
    return values.join(separator);
  }
}
