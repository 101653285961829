import { Injectable } from '@angular/core';
import { ApplicationUser } from 'app/modules/routes/login/model/application-user';
import { environment } from 'environments/environment';
import { AmplitudeService } from './amplitude.service';
import { ServiceWorkerTrackerService } from './service-worker-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  /**
   * Local history of previous 10 events.
   */
  history: string[] = [];

  constructor(private AmplitudeService: AmplitudeService) {}

  /**
   * Initializes the event tracking with Amplitude.
   */
  start() {
    this.AmplitudeService.setup(environment.amplitudeApiKey);

    this.event('app', 'loaded', { version: localStorage.getItem(ServiceWorkerTrackerService.APP_VERSION_KEY) });
  }

  /**
   * Pushes user information to Amplitude.
   *
   * @param user the currently logged user
   */
  identify(user: ApplicationUser | null) {
    if (!user) {
      this.AmplitudeService.logout();
    } else {
      this.AmplitudeService.identify(user.initials, {
        id: user.id,
        code: user.initials,
        email: user.email,
        name: user.name,
        viewport: `${window.innerWidth}x${window.innerHeight}`,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
      });
    }
  }

  /**
   * Pushes an event to Google Tag Manager data layer.
   * Use feature and action names with spaces or snake_case.
   *
   * @param feature the name of the feature / page. With spaces or snake_case
   * @param action the action that the user performed on the feature / page. With spaces or snake_case
   * @param properties the event properties
   */
  event(feature: string, action: string, properties?: Record<string, unknown>) {
    // normalize feature and action
    feature = feature.toLowerCase().replace(/ /g, '_');
    action = action.toLowerCase().replace(/ /g, '_');

    // combine feature and action as event name
    const event = `${feature}_${action}`;

    // add default properties to event
    const eventProperties: Record<string, unknown> = {
      feature,
      action,
      page_location: window.location.href,
      ...properties,
    };

    // push to datalayer, which will merge with existing data
    this.AmplitudeService.track(event, eventProperties);

    this.sendToLocalHistory(event, properties);
  }

  /**
   * Stores a tracked event in a local array to be logged when errors happen.
   *
   * @param event the event string
   * @param properties the properties sent with the event
   */
  private sendToLocalHistory(event: string, properties?: Record<string, unknown>): void {
    let activity = event;

    // If a page view, add the path
    if (event === 'page_view') {
      activity = `${activity} - ${location.pathname}`;
    }

    if (properties) {
      const jsonProperties = JSON.stringify(properties);

      if (jsonProperties !== '{}') {
        activity = `${activity} - ${jsonProperties}`;
      }
    }

    this.history.push(activity);

    while (this.history.length > 10) {
      this.history.shift();
    }
  }
}
