import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractContextMenu } from 'app/modules/common/framework/context-menu/abstract-context-menu';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PortfolioTO } from '../../model/portfolio.model';
import { PortfolioService } from '../../services/portfolio.service';

/**
 * Component for portfolio with context menu.
 */
@Component({
    selector: 'app-portfolio-context-menu',
    templateUrl: './portfolio-context-menu.component.html',
    styleUrls: ['./portfolio-context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortfolioContextMenuComponent extends AbstractContextMenu<PortfolioTO> {
  constructor(
    private portfolioService: PortfolioService,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  /**
   * @inheritdoc
   */
  protected override loadOptions(): void {
    this.options.set([
      {
        icon: 'mdi-application',
        text: 'Portfolio info',
        routerLink: ['/portfolio', this.id],
        type: 'link',
      },
      {
        icon: 'mdi-swap-horizontal',
        text: 'Cash Flow',
        type: 'link',
        routerLink: ['/cash-flow'],
        queryParams: { idtPortfolio: this.id! },
      },
      {
        icon: 'mdi-card-bulleted-outline',
        text: 'Terms',
        routerLink: ['', { outlets: { sidenav: ['portfolio', this.id, 'term'] } }],
        type: 'link',
      },
      {
        icon: 'mdi-hand-coin',
        text: 'Classes',
        routerLink: ['/portfolio', this.id, 'class'],
        type: 'link',
      },
    ]);
  }

  /**
   * @inheritdoc
   */
  override loadEntity(): void {
    if (!this.entity() && this.id) {
      this.portfolioService
        .getById(this.id)
        .pipe(
          catchError((err) => {
            // In case of error loading the data close and show error snackbar
            this.menu.close();
            this.snackBar.open(err.error.detail, 'Close', { duration: 10000 });

            return throwError(err);
          }),
        )
        .subscribe((p) => {
          this.entity.set(p);
        });
    }
  }
}
