import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, input, OnInit, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { BasicContact, ContactIndexedDTO, ContactTypeEnum } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-company-autocomplete',
    templateUrl: './company-autocomplete.component.html',
    styleUrls: ['./company-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CompanyAutocompleteComponent implements OnInit {
  /**
   * The input label
   */
  label = input.required<string>();

  /**
   * Input form control that holds the value.
   */
  control = input.required<FormControl<number | null>>();

  /**
   * Internal form control to hold the full entity to properly display values.
   */
  formControl = new FormControl<BasicContact | null>(null);

  /**
   * Adds a filter to show only companies that can be parent companies.
   */
  parentCompaniesOnly = input<boolean>(false);

  /**
   * The companies to display in the autocomplete list.
   */
  companies = signal<ContactIndexedDTO[]>([]);

  /**
   * The inital contact to use as the company
   */
  idtCompanyContact = input<number | null>();

  /**
   * The search input element reference.
   */
  searchInput = viewChild.required<ElementRef>('input');

  constructor(
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
    private contactService: ContactService,
  ) {}

  ngOnInit(): void {
    this.setupCompanySearch();

    if (this.idtCompanyContact()) {
      this.contactService.get(this.idtCompanyContact()!).subscribe((value) => {
        this.formControl.setValue(value, { emitEvent: false });
      });
    }

    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.control().setValue(value?.idtCompany ?? null);
    });
  }

  /**
   * Setups the select search field behavior.
   */
  setupCompanySearch(): void {
    fromEvent<InputEvent>(this.searchInput().nativeElement, 'input')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        map(() => this.searchInput().nativeElement.value.toLowerCase() as string),
        distinctUntilChanged(),
        mergeMap((term) => {
          if (term && term.length > 2) {
            if (this.parentCompaniesOnly()) {
              return this.contactService.searchIndexedMainCompanies(term);
            }

            return this.contactService.searchIndexedContacts(term, ContactTypeEnum.COMPANY);
          }

          return of([]);
        }),
      )
      .subscribe((companies) => {
        this.companies.set(companies);
        this.cdr.markForCheck();
      });
  }

  /**
   * Get the string to be shown as the selected value.
   *
   * @param option the selected option object
   * @returns the name of the contact to use as display value
   */
  getOptionDisplay(option: ContactIndexedDTO | null): string {
    return option?.name ?? '';
  }
}
