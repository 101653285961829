import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { PortfolioService } from '../../../portfolio/services/portfolio.service';

/**
 * Interface for the event emitter when a portfolio or class is selected.
 */
export interface SelectedPortfolio {
  type: 'portfolio' | 'class' | 'all';
  id?: number;
}

/**
 * Component to display a tree with all portfolios and classes, allowing to select one.
 */
@Component({
    selector: 'app-portfolio-tree',
    templateUrl: './portfolio-tree.component.html',
    styleUrls: ['./portfolio-tree.component.scss'],
    standalone: false
})
export class PortfolioTreeComponent implements OnInit {
  /**
   * Output to emit when a portfolio or class is selected.
   */
  @Output()
  selected = new EventEmitter<SelectedPortfolio>();

  /**
   * Outputs when the option to show inactive changes.
   */
  @Output()
  showInactiveChanged = new EventEmitter<boolean>();

  /**
   * Tree control to handle the logic of expanding/collapsing the portfolio.
   */
  treeControl = new NestedTreeControl<any>((node) => node.portfolios || node.classes);

  /**
   * Datasource to hold the porfolios and classes loaded from the server.
   */
  dataSource = new MatTreeNestedDataSource<any>();

  constructor(private portfolioService: PortfolioService) {}

  /**
   * Angular lifecycle hook method, called after component initialization.
   * Loads the portfolios and classes from the server.
   */
  ngOnInit(): void {
    this.buildDataSource(false);
  }

  /**
   * Function to verify if a portfolio has child classes.
   *
   * @param _ the index of the entry
   * @param node the entry itself
   */
  hasChild = (_: number, node: any) => !!node.portfolios || (!!node.classes && node.classes.length > 0);

  /**
   * Handles when the user clicks an entry in the tree.
   *
   * @param node the selected portfolio or class
   */
  select(node: any): void {
    this.selected.emit({
      id: node.idtClass || node.idtPortfolio,
      type: node.idtClass ? 'class' : 'portfolio',
    });
  }

  /**
   * Handle changes to the slider value. Retriggers the search with the correct filter value.
   *
   * @param e the event triggering this function
   */
  sliderChanged(e: MatSlideToggleChange): void {
    this.buildDataSource(e.checked);

    this.showInactiveChanged.emit(e.checked);
  }

  /**
   * Fetches the portfolios and classes.
   *
   * @param showAll whether to filter show all or active only
   */
  buildDataSource(showAll: boolean): void {
    const filter = new DataFilter();

    if (!showAll) {
      filter.equals('invested', 'true', 'boolean');
    }

    this.portfolioService.findWithClasses(filter).subscribe((p) => {
      this.dataSource.data = [{ name: 'all', portfolios: p }];

      // Expand the "all" section
      this.treeControl.expand(this.dataSource.data[0]);
    });
  }
}
