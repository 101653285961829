import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Observable, of, zip } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { OpportunityService } from '../../../opportunity/services/opportunity.service';
import { ContactChannel, ContactChannelEnum, ContactChannelTypeEnum } from '../../model/contact-channel.model';
import { ContactJobViewTO } from '../../model/contact-job.model';
import { ContactService } from '../../services/contact.service';

/**
 * Component to show personal info of contact
 */
@Component({
    selector: 'app-contact-personal-info',
    templateUrl: './contact-personal-info.component.html',
    styleUrls: ['./contact-personal-info.component.scss'],
    standalone: false
})
export class ContactPersonalInfoComponent {
  /**
   * The id of the contact.
   */
  private _idtContact!: number;

  /**
   * Indicates if there is an opportunity under a blackout period.
   */
  hasBlackoutOpportunity$: Observable<boolean> = of(false);

  /**
   * Setter for input field for the id of the contact. Reloads the data when changed.
   */
  @Input()
  set idtContact(value: number) {
    if (value !== this._idtContact) {
      this._idtContact = value;

      this.hasBlackoutOpportunity$ = this.opportunityService.hasBlackoutOpportunity(value);

      this.loading.emit(true);

      zip(this.loadChannels(), this.loadJobs())
        .pipe(finalize(() => this.loading.emit(false)))
        .subscribe();
    }
  }

  /**
   * Getter for the id of the contact.
   */
  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * Contact emails.
   */
  emailChannels: ContactChannel[] = [];

  /**
   * Contact phones.
   */
  phoneChannels: ContactChannel[] = [];

  /**
   * Contact faxes.
   */
  faxChannels: ContactChannel[] = [];

  /**
   * The current job of the contact.
   */
  currentJob?: ContactJobViewTO;

  /**
   * Contact jobs.
   */
  jobs: ContactJobViewTO[] = [];

  /**
   * Controls whether previous jobs menu is open.
   */
  previousJobsOpen = false;

  /**
   * Event emitted when loading data from server.
   */
  @Output()
  loading = new EventEmitter<boolean>(true);

  /**
   * Any error messages to show for each email.
   */
  errors: Record<string, string> = {};

  constructor(
    private contactService: ContactService,
    private router: Router,
    private trackerService: TrackerService,
    private opportunityService: OpportunityService,
  ) {}

  /**
   * Load all contact emails and phones.
   */
  loadChannels(): Observable<ContactChannel[]> {
    this.faxChannels = [];
    this.emailChannels = [];
    this.phoneChannels = [];

    return this.contactService.getChannels(this.idtContact).pipe(
      tap((channels) => {
        this.emailChannels = channels.filter((c) => c.channel === ContactChannelEnum.EMAIL);
        this.phoneChannels = channels.filter((c) => c.channel === ContactChannelEnum.PHONE && c.type !== ContactChannelTypeEnum.FAX);
        this.faxChannels = channels.filter((c) => c.type === ContactChannelTypeEnum.FAX);
      }),
    );
  }

  /**
   * Load all contact jobs.
   */
  loadJobs(): Observable<ContactJobViewTO[]> {
    this.currentJob = undefined;
    this.jobs = [];

    return this.contactService.getJobs(this.idtContact).pipe(
      tap((jobs) => {
        if (jobs.length > 0 && jobs[0].endDate == null) {
          this.currentJob = jobs.shift();
        }

        this.jobs = jobs;
      }),
    );
  }

  /**
   * Open the dropdown list of previous jobs.
   */
  openPreviousJobs(): void {
    this.trackerService.event('contact', 'open_previous_jobs');
    this.previousJobsOpen = true;
  }

  /**
   * Send user to the corresponding company page.
   *
   * @param idtCompanyContact the id of the company
   * @param role whether the job is current or previous
   */
  navigateCompany(idtCompanyContact: number, role: string) {
    this.trackerService.event('contact', 'navigate_job', { idtCompanyContact, role });
    this.router.navigate(['/contact', idtCompanyContact]);
  }

  /**
   * Navigate to the contact's email.
   */
  clickEmail(): void {
    this.trackerService.event('contact', 'click_about_email');
  }

  /**
   * Navigate to the contact's phone number.
   */
  clickPhone(): void {
    this.trackerService.event('contact', 'click_about_phone');
  }

  /**
   * Sets the error message to be shown for a specific email.
   *
   * @param email the email address with the error
   * @param errorMessage the error message
   */
  setError(email: string, errorMessage: string) {
    this.errors[email] = errorMessage;
  }
}
