import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BusinessModule } from 'app/modules/common/business/business.module';
import { MaterialModule } from 'app/modules/common/material-module';
import { PortfolioPerformanceComponent } from 'app/modules/routes/home/components/portfolio-performance/portfolio-performance.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FrameworkModule } from '../../common/framework/framework.module';
import { HomeComponent } from './components/home/home.component';
import { PortfolioDpeConfigurationDialogComponent } from './components/portfolio-dpe-configuration-dialog/portfolio-dpe-configuration-dialog.component';
import { PortfolioPerformanceItemComponent } from './components/portfolio-performance-item/portfolio-performance-item.component';

@NgModule({
  declarations: [HomeComponent, PortfolioPerformanceComponent, PortfolioPerformanceItemComponent, PortfolioDpeConfigurationDialogComponent],
  imports: [FrameworkModule, AngularCommonModule, MaterialModule, BusinessModule, ReactiveFormsModule, NgxSkeletonLoaderModule],
  exports: [HomeComponent],
})
export class HomeModule {}
