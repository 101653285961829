import { Pipe, PipeTransform } from '@angular/core';

/**
 * Removes the underline between words
 */
@Pipe({
    name: 'removeUnderline',
    standalone: false
})
export class RemoveUnderlinePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.split('_').join(' ');
    }
    return '';
  }
}
