import { Pipe, PipeTransform } from '@angular/core';
import { OpportunityPropabilityTimingEnum } from '../model/opportunity.model';

/**
 * Pipe for transforming opportunity probability timing enum values to a displayable text.
 */
@Pipe({
    name: 'probabilityTiming',
    standalone: false
})
export class ProbabilityTimingPipe implements PipeTransform {
  transform(value: OpportunityPropabilityTimingEnum, verbose: boolean = false): string {
    switch (value) {
      case OpportunityPropabilityTimingEnum.LONG: {
        return verbose ? 'more than 12 months' : '> 12 months';
      }
      case OpportunityPropabilityTimingEnum.MEDIUM: {
        return verbose ? 'between 6 and 12 months' : '6 - 12 months';
      }
      case OpportunityPropabilityTimingEnum.SHORT: {
        return verbose ? 'less than 6 months' : '< 6 months';
      }
    }
  }
}
