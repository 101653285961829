<div class="abs-margin-right tw-mt-[36px] tw-flex tw-items-center tw-justify-end @xs:tw-mt-0 @xs:tw-h-[36px]">
  <button mat-icon-button [matMenuTriggerFor]="menu" color="primary" class="abs-container-xs">
    <mat-icon fontIcon="mdi-filter-variant" />
  </button>

  <mat-menu #menu>
    @for (transactionType of transactionTypes; track transactionType.idtClientTransactionType) {
      <button mat-menu-item (click)="setTransactionType(transactionType)">
        <div class="tw-flex">
          <span>{{ transactionType.type }}</span>
          <span class="tw-flex-1"></span>
          <span>&nbsp;({{ transactionType.count }})</span>
        </div>
      </button>
    }
  </mat-menu>

  @if (!sidenav) {
    <button mat-icon-button color="primary" (click)="expandAccounts()" matTooltip="Expand" class="abs-container-gt-xs">
      <mat-icon fontIcon="mdi-book-open" />
    </button>
  }
</div>

<div class="tw-flex tw-flex-1 tw-flex-col @xs:tw-mx-1">
  <form [formGroup]="filterForm" class="abs-margin-top form-field-filter abs-container-gt-xs tw-flex">
    <mat-form-field class="tw-flex-1" appearance="fill">
      <mat-label>Account or Portfolio</mat-label>
      <input matInput type="text" formControlName="accountNamePortfolioTicker" />
    </mat-form-field>
    <mat-form-field class="abs-margin-half-left tw-flex tw-max-w-[150px] tw-flex-1">
      <mat-label>Type</mat-label>
      <mat-select formControlName="idtClientTransactionType" multiple>
        @for (transactionType of transactionTypes; track transactionType.idtClientTransactionType) {
          <mat-option [value]="transactionType.idtClientTransactionType">
            {{ transactionType.type }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="abs-margin-half-left">
      <mat-label>Start</mat-label>
      <input matInput [matDatepicker]="start" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="start" />
      <mat-datepicker #start disabled="false" />
      <mat-error>Invalid Date</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="abs-margin-half-left">
      <mat-label>End</mat-label>
      <input matInput [matDatepicker]="end" formControlName="endDate" disable="true" />
      <mat-datepicker-toggle matSuffix [for]="end" />
      <mat-datepicker #end disabled="false" />
      <mat-error>Invalid Date</mat-error>
    </mat-form-field>

    @if (!!idtClient) {
      <button
        mat-icon-button
        color="primary"
        (click)="createTransaction()"
        class="abs-margin-half-top"
        *hasPermission="'CREATE_TRANSACTION'"
        matTooltip="New transaction"
      >
        <mat-icon fontIcon="mdi-plus-circle" />
      </button>
    }
  </form>

  <app-transaction-list-header />
  @if (clientTransactionData?.hasData) {
    <app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
      <cdk-virtual-scroll-viewport class="abs-scroll-overlay tw-flex tw-flex-1 tw-flex-col" itemSize="44">
        <app-transaction-list-item
          *cdkVirtualFor="let transaction of clientTransactionData; trackBy: trackTransaction"
          (click)="openTransactionDetails(transaction)"
          [transaction]="transaction"
        />
      </cdk-virtual-scroll-viewport>
    </app-custom-list>
  } @else {
    <app-no-data entity="Transaction" />
  }
</div>
@if (clientTransactionData?.cachedData?.length) {
  <div class="tw-flex tw-flex-none tw-flex-col">
    <mat-divider />
    <div class="footer tw-flex tw-flex-none">
      @if (clientTransactionTotals$ | async; as clientTransactionTotals) {
        <span class="tw-flex-1">{{ formatTotalTransactionsByStatus(clientTransactionTotals.transactionsByStatus) }}</span>
        <div class="abs-margin-half-left tw-flex tw-max-w-[10%] tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
          <app-positive-negative [value]="clientTransactionTotals.transactionBalance">
            <span matTooltip="Including pending transactions">
              {{ clientTransactionTotals.transactionBalance | nearUnitNumber: '0.0-0' }}
            </span>
          </app-positive-negative>
        </div>
        <div class="abs-margin-left tw-max-w-[3%] tw-flex-1"></div>
      }
    </div>
  </div>
}
