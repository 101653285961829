import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, signal } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PortfolioPerformanceTO } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { compareWithNullsLast } from 'app/modules/common/framework/utils/order-utils';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';

interface PortfolioPerformanceWithTotal {
  total: number;
  portfolios: PortfolioPerformanceTO[];
  hasEstimates: boolean;
  missingReturns: boolean;
}

/**
 * Component for the list of portfolios with performance data.
 */
@Component({
    selector: 'app-portfolio-performance',
    templateUrl: './portfolio-performance.component.html',
    styleUrl: './portfolio-performance.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortfolioPerformanceComponent {
  /**
   * Input date to use as reference.
   */
  asOf = input.required<DateTime>();

  /**
   * Effect to trigger search on date changes.
   */
  private asOfEffect = effect(
    () => {
      if (this.asOf()) {
        this.loadData();
      }
    },
    { allowSignalWrites: true },
  );

  /**
   * Whether loading data from the server.
   */
  loading = signal(true);

  /**
   * Whether there are estimated values.
   */
  isEstimated = computed(() => {
    return this.data().some((a) => a.aumEstimated);
  });

  /**
   * The list of portfolios with performance data.
   */
  data = signal<PortfolioPerformanceTO[]>([]);

  /**
   * The list of portfolios with performance data grouped by product line.
   */
  groupedData = computed(() => {
    const groups = groupBy(this.data(), (d) => d.productLine);

    const groupsWithTotal: Record<string, PortfolioPerformanceWithTotal> = {};

    for (const [key, value] of Object.entries(groups)) {
      groupsWithTotal[key] = {
        total: value.reduce((total, b) => b.aum + total, 0),
        portfolios: value,
        hasEstimates: value.some((b) => b.aumEstimated),
        missingReturns: value.some((b) => b.missingReturns),
      };
    }

    return groupsWithTotal;
  });

  /**
   * The total AUM for all portfolios.
   */
  totalAum = computed(() => {
    return this.data().reduce((total, p) => p.aum + total, 0);
  });

  /**
   * Sort column and direction.
   */
  sort: Sort = {
    active: 'aum',
    direction: 'asc',
  };

  constructor(
    private portfolioService: PortfolioService,
    private trackerService: TrackerService,
  ) {}

  /**
   * Load the data from the server.
   */
  private loadData() {
    this.portfolioService
      .getPerformanceData(this.asOf())
      .pipe(trackLoading(this.loading))
      .subscribe((data) => {
        this.data.set(data);
      });
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioPerformanceWithTotal>, b: KeyValue<string, PortfolioPerformanceWithTotal>): number {
    // Notice the key will always be a string, so we must compare to string 'null'
    if (a.key === 'null') {
      return 1;
    }

    if (b.key === 'null') {
      return -1;
    }

    return a.value.portfolios[0].productLineOrder! - b.value.portfolios[0].productLineOrder!;
  }

  /**
   * Handle sort changes.
   *
   * @param sort the new sort property and direction
   */
  sortChange(sort: Sort): void {
    this.sort = sort;

    this.data.set([
      ...this.data().sort((a, b) => {
        return compareWithNullsLast(
          a[this.sort.active as keyof PortfolioPerformanceTO] as number,
          b[this.sort.active as keyof PortfolioPerformanceTO] as number,
          this.sort.direction,
        );
      }),
    ]);

    this.trackerService.event('portfolio_performance', 'sort', { property: this.sort.active, direction: this.sort.direction });
  }
}
