import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { Account } from '../../account/model/account.model';
import { ClientAllocation } from '../model/client-allocation.model';
import {
  Client,
  ClientAndAccountOutputDTO,
  ClientTransactionFilterForm,
  ClientTransactionTotals,
  ClientTransactionTypeWithCount,
  ClientTransactionViewTO,
  ClientViewTO,
} from '../model/client.model';
import { ConsultantAllocation } from '../model/consultant-allocation.model';
import { ClientConsultantViewTO } from '../model/consultant.model';

/**
 * Service to call the api for a Client CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the data of a specific client
   * @param id client id
   * @returns Client
   */
  getInfoById(id: number): Observable<ClientViewTO> {
    return this.http.get<ClientViewTO>(`${environment.apiUrl}/client/${id}/info`);
  }

  /**
   * Gets a list of Consultants
   * @param id client id
   * @returns Consultant
   */
  findConsultantsByIdtClient(id: number): Observable<ClientConsultantViewTO[]> {
    return this.http.get<ClientConsultantViewTO[]>(`${environment.apiUrl}/client/${id}/consultants`);
  }

  /**
   * Gets a list of Transactions
   *
   * @param idtContact client id
   * @param limit the size of the page
   * @param filterForm filter to apply to get the transactions
   * @returns ClientTransaction list of client transactions
   */
  findTransactions(idtContact: number, limit: number, filterForm: ClientTransactionFilterForm): PageableDataSource<ClientTransactionViewTO> {
    const self = this;

    return new (class extends PageableDataSource<ClientTransactionViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<ClientTransactionViewTO>> {
        const options = self.getFormatedTransactionParams(filterForm);

        return self.http.get<Pageable<ClientTransactionViewTO>>(
          `${environment.apiUrl}/client/${idtContact}/transactions?page=${page}&size=${limit}&filter=${options}`,
        );
      }
    })(limit);
  }

  /**
   * Get the sum of client transactions.
   *
   * @param idtContact the id of the client
   * @param filterForm filter to apply to get the sum of transactions
   * @returns the sum of client transactions
   */
  getTransactionsSum(idtContact: number, filterForm: ClientTransactionFilterForm): Observable<ClientTransactionTotals> {
    const options = this.getFormatedTransactionParams(filterForm);

    return this.http.get<ClientTransactionTotals>(`${environment.apiUrl}/client/${idtContact}/transactions-sum?filter=${options}`);
  }

  /**
   * Formats the params used in client transactions request
   *
   * @param filterForm filter to apply to get the transactions
   * @returns the formated object of http params or an emprty object
   */
  private getFormatedTransactionParams(filterForm: ClientTransactionFilterForm): string {
    const filter: string[] = [];

    if (filterForm.accountNamePortfolioTicker) {
      filter.push(`accountName+portfolioTicker|like|%${filterForm.accountNamePortfolioTicker}%|string`);
    }

    if (filterForm.idtClientTransactionType && filterForm.idtClientTransactionType.length > 0) {
      filter.push(`idtClientTransactionType|in|${filterForm.idtClientTransactionType.join(';')}|long`);
    }

    if (filterForm.startDate) {
      const startDate = filterForm.startDate.toISODate();
      filter.push(`transactionDate|ge|${startDate}|local-date`);
    }

    if (filterForm.endDate) {
      const endDate = filterForm.endDate.toISODate();
      filter.push(`transactionDate|le|${endDate}|local-date`);
    }

    const params = filter.join(',');

    return params ? encodeURIComponent(params) : '';
  }

  /**
   * Finds the types of transaction for this client
   *
   * @param idtContact the client id
   * @returns the list of transaction type
   */
  findTransactionTypes(idtContact: number): Observable<ClientTransactionTypeWithCount[]> {
    return this.http.get<ClientTransactionTypeWithCount[]>(`${environment.apiUrl}/client/${idtContact}/transaction-types`);
  }

  /**
   * Find clients matching the provided filters.
   *
   * @param sort the sort column and direction
   * @param filter the filters to apply
   * @returns the list of clients wrapped in an observable
   */
  findClients(sort: string, filter: string): Observable<Client[]> {
    return this.http.get<Client[]>(`${environment.apiUrl}/client`, { params: { sort, filter } });
  }

  /**
   * Create a new client with an account and an initial transaction.
   *
   * @param client the client data
   * @param account the account data
   * @param transaction the transaction data
   * @param consultants the consultants for the client/portfolio
   * @returns an observable that emits the client and account data when the server responds
   */
  create(
    client: Client,
    account: Account,
    transaction: ClientTransactionViewTO,
    consultant: { idtConsultant: number; idtExternalRep: number },
  ): Observable<ClientAndAccountOutputDTO> {
    return this.http.post<ClientAndAccountOutputDTO>(`${environment.apiUrl}/client`, {
      client,
      account,
      transaction,
      consultant,
    });
  }

  /**
   * Get client allocations by portfolio.
   * @param idtPortfolio portfolio id
   * @returns allocations
   */
  getAllocations(date: DateTime, idtPortfolio?: number): Observable<ClientAllocation[]> {
    const params = { idtPortfolio: idtPortfolio ? idtPortfolio + '' : '', date: date.toISODate() };
    return this.http.get<ClientAllocation[]>(`${environment.apiUrl}/client/allocation`, { params });
  }

  /**
   * Get client consultant allocations by portfolio.
   * @param idtPortfolio portfolio id
   * @returns consultant allocations
   */
  getConsultantAllocations(date: DateTime, idtPortfolio?: number): Observable<ConsultantAllocation[]> {
    const params = { idtPortfolio: idtPortfolio ? idtPortfolio + '' : '', date: date.toISODate() };
    return this.http.get<ConsultantAllocation[]>(`${environment.apiUrl}/client/consultant/allocation`, { params });
  }
}

/**
 * Resolve service to get a client based on the route id.
 */
export const clientResolver: ResolveFn<ClientViewTO> = (route: ActivatedRouteSnapshot) => {
  return inject(ClientService).getInfoById(Number(route.paramMap.get('id')));
};
