import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractContextMenu } from 'app/modules/common/framework/context-menu/abstract-context-menu';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactTypeEnum, ContactViewTO } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';

/**
 * Component for contact's context menu.
 */
@Component({
    selector: 'app-contact-context-menu',
    templateUrl: './contact-context-menu.component.html',
    styleUrls: ['./contact-context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactContextMenuComponent extends AbstractContextMenu<ContactViewTO> {
  /**
   * Apply the text overflow class to everywhere this component is used.
   */
  @HostBinding('class') class = 'abs-text-overflow-ellipsis';

  constructor(
    private contactService: ContactService,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  /**
   * Define the menu options for the given contact.
   */
  protected override loadOptions(): void {
    this.options.set([
      {
        icon: 'mdi-application',
        text: 'Overview',
        routerLink: ['/contact', this.id],
        type: 'link',
      },
      {
        icon: 'mdi-pencil',
        text: 'Contact Card',
        routerLink: ['', { outlets: { sidenav: ['contact', this.id, 'info'] } }],
        type: 'link',
      },
      {
        icon: 'mdi-book-open',
        text: 'Logs',
        routerLink: ['', { outlets: { sidenav: ['contact', this.id, 'logs'] } }],
        type: 'link',
      },
      {
        icon: 'mdi-book-open',
        text: 'Documents',
        routerLink: ['', { outlets: { sidenav: ['doc', 'contact', this.id] } }],
        type: 'link',
      },
      {
        icon: 'mdi-book-open',
        text: 'Accounts',
        routerLink: ['', { outlets: { sidenav: ['contact', this.id, 'accounts'] } }],
        type: 'link',
      },
      {
        icon: 'mdi-cash-100',
        text: 'Opportunities',
        routerLink: ['', { outlets: { sidenav: ['contact', this.id, 'opportunities'] } }],
        type: 'link',
      },
      {
        icon: 'mdi-bell',
        text: 'Tasks',
        type: 'link',
        routerLink: ['', { outlets: { sidenav: ['task'] } }],
        queryParams: { 'sidenav.idtContact': this.id! },
      },
      {
        icon: 'mdi-swap-horizontal',
        text: 'Cash Flow',
        type: 'link',
        routerLink: ['/cash-flow'],
        queryParams: { idtContact: this.id! },
      },
    ]);
  }

  /**
   * Open the context menu. Loads the contact data if not yet loaded.
   *
   * @param event the event
   */
  override loadEntity(): void {
    if (!this.entity() && this.id) {
      this.contactService
        .getInfoById(this.id)
        .pipe(
          catchError((err) => {
            // In case of error loading the data close and show error snackbar
            this.menu.close();
            this.snackBar.open(err.error.detail, 'Close', { duration: 10000 });

            return throwError(() => err);
          }),
        )
        .subscribe((contact) => {
          this.entity.set(contact);

          if (contact.inactive) {
            this.options.set([]);
            return;
          }

          // If person, add option to see groups and campaigns
          if (contact.type === ContactTypeEnum.PERSON) {
            this.options.update((v) => {
              return [
                ...v,
                {
                  icon: 'mdi-account-group',
                  text: 'Distribution Groups',
                  routerLink: ['', { outlets: { sidenav: ['contact', this.id, 'mailing'] } }],
                  type: 'link',
                },
              ];
            });
          } else {
            // Rename the "Contact Card" to "Company Card"
            const newOptions = [...this.options()];
            newOptions.find((v) => v.text === 'Contact Card')!.text = 'Company Card';
            this.options.set(newOptions);
          }
        });
    }
  }
}
