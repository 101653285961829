import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OpportunityClosedStatusEnum, OpportunityStatusEnum } from '../../model/opportunity.model';

/**
 * Component to show opportunity status with proper colors.
 */
@Component({
    selector: 'app-opportunity-status',
    templateUrl: './opportunity-status.component.html',
    styleUrls: ['./opportunity-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OpportunityStatusComponent {
  /**
   * The status.
   */
  status = input.required<OpportunityStatusEnum | OpportunityClosedStatusEnum>();
}
