import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Component to show a dialog to confirm or cancel an operation.
 */
@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent {
  /**
   * The message to display.
   */
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { message: string; type: string }) {
    this.message = data.message;
  }
}
