<div class="tw-flex tw-flex-1">
  <div class="tw-flex">
    <div class="abs-margin-right tw-flex tw-items-center tw-justify-start">
      <app-contact-image [idtContact]="idtContact" [showEdit]="true" />
    </div>

    <mat-divider [vertical]="true" />
  </div>

  <div class="tw-flex tw-flex-1 tw-flex-col">
    @if (hasBlackoutOpportunity$ | async) {
      <app-alert-box><span class="tw-uppercase">Do not contact this person regarding the pending RFP</span></app-alert-box>
    }

    <span class="abs-padding">
      <span>Current Role:&nbsp;</span>

      @if (currentJob) {
        <span>{{ currentJob.businessRole || 'Not informed' }} at&nbsp;</span>
        <a class="abs-link abs-bold" (click)="navigateCompany(currentJob.idtCompanyContact, 'current')">
          <span>{{ currentJob.company }}</span>
        </a>
      } @else {
        <span>Not informed</span>
      }
    </span>

    @if (jobs.length > 0) {
      <span class="abs-padding tw-flex tw-items-center tw-justify-start">
        <span class="abs-text-overflow-ellipsis">
          <span>Previously: {{ jobs[0].businessRole }} at&nbsp;</span>
          <a class="abs-link abs-bold" (click)="navigateCompany(jobs[0].idtCompanyContact, 'previous')">
            <span>{{ jobs[0].company }}</span>
          </a>
        </span>
        <button mat-icon-button [hidden]="jobs.length <= 1" (click)="openPreviousJobs()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
          <mat-icon fontIcon="mdi-chevron-down" />
        </button>
      </span>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="previousJobsOpen"
        [cdkConnectedOverlayHasBackdrop]="true"
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        (backdropClick)="previousJobsOpen = !previousJobsOpen"
      >
        <div class="mat-elevation-z8 abs-padding tw-flex tw-flex-col">
          @for (job of jobs.slice(1); track job) {
            <span class="abs-padding">
              <span>{{ job.businessRole }} at&nbsp;</span>
              <a class="abs-link abs-bold" (click)="navigateCompany(job.idtCompanyContact, 'previous')">
                <span>{{ job.company }}</span>
              </a>
            </span>
          }
        </div>
      </ng-template>
    }

    @if (jobs.length > 0) {
      <mat-divider />
    }

    <div class="abs-scroll-auto tw-flex tw-flex-1">
      <app-custom-list [selectable]="false" mode="dense" class="tw-max-w-[50%] tw-flex-1">
        @for (email of emailChannels; track email) {
          <div class="tw-flex tw-flex-col">
            <app-custom-list-item>
              <a mat-icon-button class="abs-margin-right tw-flex-none" [attr.href]="'mailto:' + email.value" (click)="clickEmail()">
                <mat-icon fontIcon="mdi-email-arrow-right" />
              </a>
              <span>{{ email.value }}</span>
              <app-contact-last-email-error [email]="email.value" (errorChanged)="setError(email.value, $event)" />
              <i [class.hidden]="email.type !== 'PERSONAL'">&nbsp;(Personal)</i>
            </app-custom-list-item>
            @if (errors[email.value]) {
              <mat-error class="custom-error">{{ errors[email.value] }}</mat-error>
            }
          </div>
        }
      </app-custom-list>
      <app-custom-list [selectable]="false" mode="dense" class="tw-flex tw-max-w-[50%] tw-flex-1">
        @for (phone of phoneChannels; track phone) {
          <app-custom-list-item class="!tw-flex tw-justify-end">
            <a mat-icon-button class="abs-margin-right tw-flex-none" [attr.href]="'tel:' + phone.value" (click)="clickPhone()">
              <mat-icon fontIcon="mdi-phone-forward" />
            </a>
            <span>{{ phone.value }}</span>
            <i [class.hidden]="phone.type !== 'PERSONAL'">&nbsp;(Personal)</i>
          </app-custom-list-item>
        }
        @for (fax of faxChannels; track fax) {
          <app-custom-list-item class="!tw-flex tw-justify-end">
            <mat-icon fontIcon="mdi-fax" class="abs-margin-right" />
            <span>{{ fax.value }}</span>
            <!-- Hidden element to keep aligned to other fields -->
            <i class="hidden">&nbsp;(Personal)</i>
          </app-custom-list-item>
        }
      </app-custom-list>
    </div>
  </div>
</div>
