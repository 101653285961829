@if (!loading() && isEstimated()) {
  <app-alert-box [showIcon]="false">Contains estimated values, denoted with an *</app-alert-box>
}

<div class="tw-flex tw-p-1 tw-font-medium">
  Total:
  @if (loading()) {
    <div class="tw-w-1/2">
      <ngx-skeleton-loader [count]="1" />
    </div>
  } @else {
    {{ totalAum() | currency }}{{ isEstimated() ? '*' : '' }}
  }
</div>

<div
  class="header scrollable-header tw-flex"
  matSort
  (matSortChange)="sortChange($event)"
  [matSortActive]="sort.active"
  [matSortDirection]="sort.direction"
>
  <div class="tw-w-[18px]"></div>
  <div class="tw-w-[50px]">AS OF</div>
  <div class="tw-flex-1"></div>
  <div class="tw-w-[60px] tw-text-right" mat-sort-header="aum" arrowPosition="before">AUM&nbsp;</div>
  <div class="tw-w-[50px] tw-text-right" mat-sort-header="mtd" arrowPosition="before">MTD&nbsp;</div>
  <div class="tw-w-[50px] tw-text-right" mat-sort-header="qtd" arrowPosition="before">QTD&nbsp;</div>
  <div class="tw-w-[50px] tw-text-right" mat-sort-header="ytd" arrowPosition="before">YTD&nbsp;</div>
</div>
<mat-divider />

<div class="abs-scroll-auto tw-gutter stable-gutter tw-flex-1">
  <!-- Loaidng skeleton -->
  @if (loading()) {
    @for (i of [].constructor(40); track i; let index = $index) {
      @if ($index % 10 === 0) {
        <div class="tw-mt-1 tw-w-1/4"><ngx-skeleton-loader [count]="1" /></div>
      } @else {
        <div class="tw-flex">
          <div class="tw-w-[18px]"></div>
          <div class="tw-flex-1">
            <div class="tw-w-3/4"><ngx-skeleton-loader [count]="1" /></div>
          </div>
          <div class="tw-w-[50px] tw-text-right"><ngx-skeleton-loader [count]="1" /></div>
          <div class="tw-w-[50px] tw-text-right"><ngx-skeleton-loader [count]="1" /></div>
          <div class="tw-w-[50px] tw-text-right"><ngx-skeleton-loader [count]="1" /></div>
          <div class="tw-w-[50px] tw-text-right"><ngx-skeleton-loader [count]="1" /></div>
        </div>
      }
    }
  } @else {
    @for (productLine of groupedData() | keyvalue: sortGroups; track productLine.key) {
      <div class="tw-mt-1 tw-flex tw-p-0.5 tw-font-medium">
        <div class="tw-flex-1">
          {{ productLine.key !== 'null' ? productLine.key : 'None' }}
        </div>
        <div class="tw-w-[60px] tw-text-right">
          @if (!productLine.value.missingReturns) {
            <span class="tw-font-medium tw-text-black">{{ productLine.value.total | millions | number: '1.1-1' }}M</span>
            <span [ngClass]="{ 'abs-hidden': !productLine.value.hasEstimates }">*</span>
          } @else {
            <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing returns in ABSolute" />
          }
        </div>

        <div class="tw-w-[154px]"></div>
      </div>

      @for (portfolio of productLine.value.portfolios; track portfolio.idtPortfolio) {
        <app-portfolio-performance-item [portfolio]="portfolio" />
      }
    }
  }
</div>
