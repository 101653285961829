import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Message for no data
 */
@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoDataComponent {
  @Input()
  entity: string = '';
}
