import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-positive-negative',
    templateUrl: './positive-negative.component.html',
    styleUrls: ['./positive-negative.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PositiveNegativeComponent {
  @Input({ required: true })
  value!: number;
}
