import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { OpportunityTO, RfpStatusEnum } from '../../../model/opportunity.model';

@Component({
    selector: 'app-opportunity-rfp',
    templateUrl: './opportunity-rfp.component.html',
    styleUrls: ['./opportunity-rfp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OpportunityRfpComponent implements OnInit {
  /**
   * An existing opportunity in case of edition.
   */
  opportunity = input<OpportunityTO>();

  /**
   * Status options for RFP files.
   */
  statusOptions = Object.values(RfpStatusEnum);

  /**
   * The current date for new files.
   */
  currentDate = new Date();

  formGroup = this.fb.group({
    rfp: new FormControl<boolean>(false, { nonNullable: true }),
    rfpStatus: new FormControl<RfpStatusEnum | null>(null),
    rfpDueDate: new FormControl<DateTime | null>(null),
    blackout: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.opportunity()) {
      this.formGroup.patchValue(
        {
          ...this.opportunity(),
          rfpDueDate: this.opportunity()!.rfpDueDate ? DateTime.fromISO(this.opportunity()!.rfpDueDate!) : null,
        },
        { emitEvent: false },
      );
    }
  }

  /**
   * Get the current form values.
   *
   * @returns get the current form values
   */
  getValues() {
    return {
      ...this.formGroup.getRawValue(),
      rfpDueDate: this.formGroup.controls.rfpDueDate.value?.toISODate(),
    };
  }
}
