import { Component, Input } from '@angular/core';
import { ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';

/**
 * Component for general info of client
 */
@Component({
    selector: 'app-client-general-info',
    templateUrl: './client-general-info.component.html',
    styleUrls: ['./client-general-info.component.scss'],
    standalone: false
})
export class ClientGeneralInfoComponent {
  @Input()
  contactData!: ContactViewTO;

  isOpen = false;

  /**
   * Call the specific number
   */
  callTel(phone: string) {
    window.location.href = `tel:${phone}`;
  }

  /**
   * Send email to
   */
  sendEmail(email: string) {
    window.location.href = `mailto:${email}`;
  }
}
