import { Component, ElementRef, Inject, QueryList, ViewChildren, signal } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContactConsultantTO, ContactConsultantWithPortfolios } from '../../../client/model/client-balance.model';
import { ContactService } from '../../services/contact.service';
import { ConsultantForm, ContactConsultantFormComponent } from '../contact-create-edit/contact-consultant-form/contact-consultant-form.component';

@Component({
    selector: 'app-contact-consultant-edit',
    templateUrl: './contact-consultant-edit.component.html',
    styleUrls: ['./contact-consultant-edit.component.scss'],
    standalone: false
})
export class ContactConsultantEditComponent {
  /**
   * The id of the contact.
   */
  idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    consultants: new FormArray<ConsultantForm>([], this.hasDuplicatedPortfolio()),
  });

  /**
   * Get the formArray with the data.
   */
  get formArray() {
    return this.formGroup.controls.consultants;
  }

  private consultants?: ContactConsultantWithPortfolios[];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * Reference to the form components created for each consultant.
   */
  @ViewChildren(ContactConsultantFormComponent, { read: ElementRef })
  formComponents!: QueryList<ElementRef>;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean },
    private dialogRef: MatDialogRef<ContactConsultantEditComponent>,
  ) {
    this.idtContact = data.idtContact;
    this.getConsultants(data.includeNew);
  }

  /**
   * Builds the validator function used to prevent selecting the same portfolio for multiple consultants.
   *
   * @returns the function to be used for validation
   */
  private hasDuplicatedPortfolio(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formArray = control as FormArray<ConsultantForm>;

      let hasDuplicate = false;

      formArray.getRawValue().forEach((item: any, index: number, arr: any[]) => {
        const otherArr = arr.slice();
        otherArr.splice(index, 1);

        const duplicated = otherArr
          .flatMap((c) => c.idtPortfolios)
          .some((idtPortfolio: number) => item.idtPortfolios.some((pp: number) => pp === idtPortfolio));

        formArray.at(index).controls.idtPortfolios.setErrors(duplicated ? { duplicated } : null);

        if (duplicated) {
          hasDuplicate = true;
        }
      });

      return hasDuplicate ? { error: 'Has Duplicate' } : null;
    };
  }

  /**
   * Get the consultants from the server.
   */
  private getConsultants(includeNew: boolean): void {
    this.contactService
      .getConsultants(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((consultants) => {
          this.consultants = consultants.reduce((array: ContactConsultantWithPortfolios[], current: ContactConsultantTO) => {
            const existing = array.find(
              (c) => c.consultant?.idtContact === current.consultant?.idtContact && c.externalRep?.idtContact === current.externalRep?.idtContact,
            );

            if (existing) {
              if (current.idtPortfolio) {
                existing.idtPortfolios.push(current.idtPortfolio);
              }
            } else {
              const idtPortfolios = [];

              const { idtPortfolio, ...cc } = current;

              if (idtPortfolio) {
                idtPortfolios.push(idtPortfolio);
              }

              array.push({
                idtPortfolios,
                ...cc,
              });
            }

            return array;
          }, []);

          this.consultants.forEach((c) => {
            this.addContactConsultantForm(c);
          });
        }),
        tap(() => {
          if (includeNew) {
            this.addContactConsultantForm();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Adds a new contact consultant form group in the form array
   */
  addContactConsultantForm(contactConsultant?: ContactConsultantWithPortfolios): void {
    const form = ContactConsultantFormComponent.getForm(contactConsultant);
    this.formArray.push(form);

    // If creating a new consultant, after the form component is created, scroll it into the visible area
    if (!contactConsultant) {
      setTimeout(() => {
        this.formComponents.last.nativeElement.scrollIntoView();
      });
    }
  }

  /**
   * Remove am entity from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    const idtPortfolios = this.formArray.getRawValue().flatMap((c: any) => c.idtPortfolios);
    if (new Set(idtPortfolios).size < idtPortfolios.length) {
      this.dialogService.showError("It's not allowed to assign the same portfolio to multiple consultants");
      return false;
    }

    return this.formGroup.valid;
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      this.saving.set(true);

      // Build each contact consultant entity, for each portfolio
      const consultants = this.formArray.value.map((consultant) => {
        return {
          idtContact: this.idtContact,
          idtConsultant: consultant.consultant?.idtContact,
          idtConsultantCompany: consultant.externalRep?.idtContact,
          idtBackupConsultant: consultant.backupConsultant?.idtContact,
          idtPortfolios: consultant.idtPortfolios || [],
        };
      });

      this.contactService
        .updateConsultants(this.idtContact, consultants)
        .pipe(
          trackLoading(this.saving),
          catchError((err) => {
            this.dialogService.showError(err.error?.message || "Couldn't save the contact consultants");
            return throwError(() => err);
          }),
          tap(() => {
            this.dialogService.showSuccess('Contact consultants saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
