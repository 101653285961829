import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { DpeDeliveryOptionsEnum } from '../user.model';
import { UserService } from '../user.service';

/**
 * Component for the dialog to configure user preferences.
 */
@Component({
  selector: 'app-user-preference-dialog',
  templateUrl: './user-preference-dialog.component.html',
  styleUrl: './user-preference-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserPreferenceDialogComponent implements OnInit {
  /**
   * Form control for the options on how to receive the DPE.
   */
  dpeControl = new FormControl<DpeDeliveryOptionsEnum>(DpeDeliveryOptionsEnum.NONE, { nonNullable: true });

  private idtUser?: number;

  loading = signal(false);

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<UserPreferenceDialogComponent>,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.userService
      .getUserPreferences()
      .pipe(trackLoading(this.loading))
      .subscribe((pref) => {
        this.idtUser = pref.idtUser;
        this.dpeControl.setValue(pref.dpeDeliveryOption);
      });
  }

  /**
   * Save the selected values.
   */
  save() {
    if (!this.loading()) {
      this.userService
        .updateUserPreferences({
          idtUser: this.idtUser!,
          dpeDeliveryOption: this.dpeControl.value,
        })
        .pipe(trackLoading(this.loading))
        .subscribe(() => {
          this.dialogService.showSuccess('User preferences saved successfully');
          this.dialogRef.close();
        });
    }
  }
}
