import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to join multi word string replacing spaces with "-", also transforms all character to lowercase.
 */
@Pipe({
    name: 'kebabcase',
    standalone: false
})
export class KebabCasePipe implements PipeTransform {
  transform(value: string): string;
  transform(value: null | undefined): null;
  transform(value: string | null | undefined): string | null;
  transform(value: string | null | undefined): string | null {
    if (value) {
      return value.toLowerCase().replace(/ /g, '-');
    }

    return null;
  }
}
