import { Component, Inject, signal } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { DateTime } from 'luxon';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContactJobInputDTO, ContactJobViewTO } from '../../model/contact-job.model';
import { ContactService } from '../../services/contact.service';
import { ContactJobForm, ContactJobFormComponent } from '../contact-create-edit/contact-job-form/contact-job-form.component';

/**
 * Component to edit a contacts job list.
 */
@Component({
    selector: 'app-contact-job-edit',
    templateUrl: './contact-job-edit.component.html',
    styleUrls: ['./contact-job-edit.component.scss'],
    standalone: false
})
export class ContactJobEditComponent {
  /**
   * The id of the contact.
   */
  idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    jobs: new FormArray<ContactJobForm>([]),
  });

  /**
   * Get the formArray of jobs.
   */
  get formArray() {
    return this.formGroup.controls.jobs;
  }

  private jobs?: (ContactJobViewTO | undefined)[];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean },
    private dialogRef: MatDialogRef<ContactJobEditComponent>,
  ) {
    this.idtContact = data.idtContact;
    this.getJobs(data.includeNew);
  }

  /**
   * Get the jobs from the server and build the formArray accordingly.
   */
  private getJobs(includeNew: boolean): void {
    this.contactService
      .getJobs(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((jobs) => {
          this.jobs = jobs;

          for (const contactJob of this.jobs) {
            this.addJobForm(contactJob);
          }
        }),
        tap(() => {
          if (includeNew) {
            this.addJobFormAtStart();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Get a job data from the list of already saved jobs.
   *
   * @param index the index of the job in the list
   * @returns the job found or null
   */
  getJob(index: number): ContactJobViewTO | undefined {
    if (this.jobs) {
      return this.jobs[index];
    }

    return undefined;
  }

  /**
   * Add a new job to the list.
   *
   * @param job optional initial job values
   */
  addJobForm(job?: ContactJobViewTO): void {
    this.formArray?.push(ContactJobFormComponent.getContactJobForm(job));
  }

  /**
   * Add an empty job form at the beggining of the list.
   */
  addJobFormAtStart(): void {
    this.jobs?.unshift(undefined);
    this.formArray?.insert(0, ContactJobFormComponent.getContactJobForm());
  }

  /**
   * Remove a job from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.jobs?.splice(index, 1);
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Sends request to save the new jobs data.
   */
  save(): void {
    if (!this.saving() && this.validate()) {
      this.saving.set(true);

      const jobs = this.formArray.getRawValue();

      // Format the job dates and clear current flag
      const jobsInput: ContactJobInputDTO[] = jobs.map((job) => {
        return {
          ...job,
          idtCompany: job.idtCompany!,
          startDate: job.startDate instanceof DateTime ? job.startDate.toISODate() : job.startDate,
          endDate: job.endDate instanceof DateTime ? job.endDate.toISODate() : job.endDate,
        };
      });

      this.contactService
        .saveJobs(this.idtContact, jobsInput)
        .pipe(
          trackLoading(this.saving),
          catchError((err) => {
            this.dialogService.showError(err.error?.message || "Couldn't save the contact jobs");
            return throwError(() => err);
          }),
          tap(() => {
            this.dialogService.showSuccess('Contact jobs saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }
}
