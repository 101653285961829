/**
 * Prepare sort mode treating nulls values.
 *
 * @param currentValue the current value
 * @param lastValue the last value
 * @param direction the sort direction
 * @returns the sort value
 */
export function compareWithNullsLast(currentValue: number, lastValue: number, direction: 'asc' | 'desc' | '') {
  // Nulls always at the end
  if (currentValue == null) {
    return 1;
  }
  if (lastValue == null) {
    return -1;
  }
  if (direction === 'desc') {
    return currentValue - lastValue;
  }
  return lastValue - currentValue;
}
