import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { PortfolioAllocation } from '../../model/portfolio-allocation.model';
import { PortfolioService } from '../../services/portfolio.service';

interface PortfolioAllocationWithTotal {
  total: number;
  portfolios: PortfolioAllocation[];
  hasEstimates: boolean;
  missingReturns: boolean;
}

/**
 * Component that represents a list of all system portfolios and its allocations.
 */
@Component({
    selector: 'app-portfolio-allocation',
    templateUrl: './portfolio-allocation.component.html',
    styleUrls: ['./portfolio-allocation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortfolioAllocationComponent {
  constructor(
    private portfolioService: PortfolioService,
    private trackerService: TrackerService,
  ) {}

  /*
   * Firm totalization item.
   */
  total: PortfolioAllocation | undefined;

  /**
   * The selected portfolio.
   */
  @Input()
  selectedPortfolio?: PortfolioAllocation;

  /**
   * Emits the select event.
   */
  @Output()
  selectPortfolio = new EventEmitter<PortfolioAllocation | undefined>();

  /**
   * Emits when selected date is changed.
   */
  @Output()
  dateChanged = new EventEmitter<DateTime>();

  loading = signal(true);

  /**
   * Whether there are estimated balance values.
   */
  isEstimated = computed(() => {
    return this.portfolios().some((a) => a.estimated);
  });

  asOf = input.required<DateTime>();

  private asOfEffect = effect(
    () => {
      if (this.asOf()) {
        this.getAllocations();
      }
    },
    { allowSignalWrites: true },
  );

  portfolios = signal<PortfolioAllocation[]>([]);

  /**
   * The list of portfolios grouped by product line.
   */
  groupedData = computed(() => {
    const groups = groupBy(this.portfolios(), (d) => d.productLine);

    const groupsWithTotal: Record<string, PortfolioAllocationWithTotal> = {};

    for (const [key, value] of Object.entries(groups)) {
      groupsWithTotal[key] = {
        total: value.reduce((total, b) => b.totalPortfolio + total, 0),
        portfolios: value,
        hasEstimates: value.some((b) => b.estimated),
        missingReturns: value.some((b) => b.missingReturns),
      };
    }

    return groupsWithTotal;
  });

  /**
   * The selected group by.
   */
  groupBy = signal<'productLine' | null>('productLine');

  private getAllocations() {
    this.portfolioService
      .findAllocations(this.asOf())
      .pipe(trackLoading(this.loading))
      .subscribe((allocations) => {
        this.total = allocations[0];
        this.portfolios.set(allocations);
      });
  }

  /**
   * Select portfolio and emit an event to the parent component.
   * @param idtPortfolio portfolio id.
   */
  select(portfolio?: PortfolioAllocation): void {
    if (this.selectedPortfolio?.idtPortfolio === portfolio?.idtPortfolio) {
      this.selectPortfolio.emit(undefined);
    } else {
      this.selectPortfolio.emit(portfolio);
    }
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioAllocationWithTotal>, b: KeyValue<string, PortfolioAllocationWithTotal>): number {
    // Notice the key will always be a string, so we must compare to string 'null'
    if (a.key === 'null') {
      return 1;
    }

    if (b.key === 'null') {
      return -1;
    }

    return a.value.portfolios[0].productLineOrder! - b.value.portfolios[0].productLineOrder!;
  }

  /**
   * Change groupBy
   *
   * @param groupBy the selected groupBy
   */
  changeGroupBy(groupBy: 'productLine' | null) {
    this.groupBy.set(groupBy);
    this.trackerService.event('portfolio_allocation', 'groupBy', { groupBy });
  }
}
