import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicContact, ContactRelationshipTO, ContactRelationshipType, ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith } from 'rxjs/operators';

/**
 * Exported formGroup type.
 */
export type ContactRelationshipFormGroup = FormGroup<{
  idtContactTo: FormControl<number | null>;
  idtContactRelationshipType: FormControl<number | null>;
}>;

/**
 * Component for the form to configure a contact relationship.
 */
@Component({
    selector: 'app-contact-relationship-form',
    templateUrl: './contact-relationship-form.component.html',
    styleUrls: ['./contact-relationship-form.component.scss'],
    standalone: false
})
export class ContactRelationshipFormComponent implements OnInit {
  /**
   * The  form group instance to save the values to.
   */
  @Input()
  formGroup!: ContactRelationshipFormGroup;

  /**
   * Emits the remove event to delete a job form from array.
   */
  @Output()
  remove = new EventEmitter<number>();

  /**
   * The index of form array.
   */
  @Input()
  index!: number;

  @Input()
  initialContactTo?: BasicContact;

  /**
   * Form control for relationship type input to search
   */
  relationshipTypeSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * List of all possible contact relationship types.
   */
  allRelationshipTypes: ContactRelationshipType[] = [];

  /**
   * Filtered list of relationship types.
   */
  filteredTypes$?: Observable<ContactRelationshipType[]>;

  /**
   * Form control for the contact search input.
   */
  contactSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * List of contacts after filtering.
   */
  filterdContacts$: ReplaySubject<BasicContact[]> = new ReplaySubject<BasicContact[]>();

  /**
   * Builds the form group required for this component to store it's values.
   *
   * @returns a form group for a single contact relationship
   */
  static buildForm(initialValue?: ContactRelationshipTO): ContactRelationshipFormGroup {
    return new FormGroup({
      idtContactTo: new FormControl<number | null>(initialValue?.idtContactTo ?? null, Validators.required),
      idtContactRelationshipType: new FormControl<number | null>(initialValue?.idtContactRelationshipType ?? null, Validators.required),
    });
  }

  constructor(
    private contactService: ContactService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    if (this.initialContactTo) {
      this.filterdContacts$.next([this.initialContactTo]);
    }

    this.contactService.getRelationshipTypes().subscribe((cr) => {
      this.allRelationshipTypes = cr;

      this.filteredTypes$ = this.relationshipTypeSearchControl.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map((search: string) => this.allRelationshipTypes.filter((cr) => cr.relationship.toLocaleLowerCase().includes(search.toLocaleLowerCase()))),
        startWith(this.allRelationshipTypes),
      );
    });

    this.contactSearchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(1000),
        filter((search: string) => search.length > 2),
        mergeMap((search: string) => this.contactService.searchIndexedContacts(search)),
      )
      .subscribe((contacts) => {
        this.filterdContacts$.next(contacts);
      });
  }

  /**
   * Emits an event to trigger removing an element from the collection.
   *
   * @param index the index of the entry to remove
   */
  removeForm(index: number): void {
    this.remove.emit(index);
  }

  /**
   * Compare the selected contact with a contact option.
   *
   * @param c1 a contact option
   * @param c2 the selected contact
   * @returns if both represent the same contact
   */
  compareContacts(c1: ContactViewTO, c2: ContactViewTO): boolean {
    return c1 && c2 && c1.idtContact === c2.idtContact;
  }
}
