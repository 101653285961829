@if (mailingGroupData$ | async; as mailingGroupData) {
  <div class="tab-tools abs-margin-right tw-flex tw-items-center tw-justify-end">
    <div [matTooltip]="hasEmail() ? 'Add to group' : 'An email address is needed to add this contact to a group'">
      <button
        mat-icon-button
        color="primary"
        [routerLink]="['', { outlets: { sidenav: ['contact', idtContact, 'mailing', 'add'] } }]"
        [queryParams]="{ 'sidenav.fromSidenav': sidenav }"
        [disabled]="!hasEmail()"
      >
        <mat-icon fontIcon="mdi-plus-circle" />
      </button>
    </div>
    <button mat-icon-button color="primary" (click)="openDeleteDialog()" matTooltip="Remove all" [disabled]="mailingGroupData.length === 0">
      <mat-icon fontIcon="mdi-delete-sweep" />
    </button>
    @if (!sidenav) {
      <button mat-icon-button color="primary" [routerLink]="['', { outlets: { sidenav: ['contact', idtContact, 'mailing'] } }]" matTooltip="Expand">
        <mat-icon fontIcon="mdi-book-open" />
      </button>
    }
  </div>
  <div class="abs-padding tw-flex tw-flex-1 tw-flex-col">
    @if (mailingGroupData.length > 0) {
      <app-custom-list [selectable]="false" mode="dense" class="abs-scroll-auto !tw-flex tw-flex-1 tw-flex-col">
        @for (mailingGroup of mailingGroupData; track mailingGroup) {
          <app-custom-list-item>
            <div class="abs-overflow-hidden abs-margin-right tw-flex tw-flex-1 tw-flex-col">
              <span class="abs-text-overflow-ellipsis" [matTooltip]="mailingGroup.description">
                {{ mailingGroup.description }}
              </span>
              <div class="abs-color-faded tw-flex">
                <span class="abs-text-overflow-ellipsis">{{ mailingGroup.portfolio || '-' }}</span>
                @if (mailingGroup.portfolioClass) {
                  <span style="white-space: nowrap">&nbsp;- {{ mailingGroup.portfolioClass }}</span>
                }
              </div>
            </div>
            <div class="abs-margin-right abs-align-center investment-tag tw-flex tw-max-w-[80px] tw-flex-1 tw-items-center tw-justify-center">
              @if (mailingGroup.contactInvested) {
                <div class="abs-background-positive abs-padding-half-horizontal">Contact Invested</div>
              }
              @if (!mailingGroup.contactInvested && mailingGroup.companyInvested) {
                <div class="abs-background-positive abs-padding-half-horizontal">Company Invested</div>
              }
              @if (!mailingGroup.contactInvested && !mailingGroup.companyInvested && mailingGroup.consulting) {
                <div class="abs-background-warn abs-padding-half-horizontal">Consultant Invested</div>
              }
            </div>
            <div class="tw-flex tw-max-w-[120px] tw-flex-1 tw-flex-col">
              @if (mailingGroup.sentAt) {
                <span>Sent {{ mailingGroup.sentAt | timeAgo }}</span>
              } @else {
                <span>Never sent</span>
              }
              <span>{{ mailingGroup.opens || 0 | limitNumber }} opens {{ mailingGroup.clicks || 0 | limitNumber }} clicks</span>
            </div>
            <div>
              <button
                mat-icon-button
                color="primary"
                (click)="deleteFromSingleGroup(mailingGroup.idtEmailGroup)"
                matTooltip="Remove from group"
                matTooltipPosition="left"
                [matTooltipShowDelay]="1000"
              >
                <mat-icon fontIcon="mdi-delete-outline" />
              </button>
            </div>
            <mat-divider />
          </app-custom-list-item>
        }
      </app-custom-list>
    } @else {
      <div>Not a member of any group</div>
    }
  </div>
}
