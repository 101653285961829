<div class="tab-tools tw-flex tw-items-center tw-justify-end">
  <button class="tw-right-[155px]" mat-icon-button color="primary" matTooltip="Group by" [matMenuTriggerFor]="groupingMenu">
    <mat-icon fontIcon="mdi-google-circles-communities" />
  </button>
</div>

@if (!loading() && isEstimated()) {
  <app-alert-box [showIcon]="false">Contains estimated values, denoted with an *</app-alert-box>
}

<app-custom-list class="abs-scroll-auto-y tw-flex tw-flex-1 tw-flex-col" [ngClass]="{ 'abs-scroll-hidden': loading() }">
  <!-- Skeleton items while loading -->
  <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()" />

  @if (!loading()) {
    <!-- Totalization line -->
    @if (total) {
      <app-custom-list-item [selected]="!selectedPortfolio" (click)="select()">
        <div class="tw-flex tw-flex-1">
          <div class="tw-flex tw-flex-1 tw-flex-col">
            <span class="abs-bold">TOTAL</span>
            <span class="abs-color-faded">{{ total.firmClients }} clients</span>
          </div>
          <div class="tw-flex tw-flex-1 tw-items-center tw-justify-end">
            @if (total.firmMissingReturns) {
              <mat-icon
                fontIcon="mdi-alert-outline"
                class="abs-color-warn tw-mr-0.5"
                matTooltip="Not full value due to missing returns in ABSolute"
              />
            }
            <div class="tw-flex tw-flex-col tw-items-end">
              <span>{{ total.totalFirm | currency }}{{ isEstimated() ? '*' : '' }}</span>
              <span class="abs-color-faded">{{ 1 | percent: '.2' }}</span>
            </div>
          </div>
        </div>
        <mat-divider />
      </app-custom-list-item>
    }

    @if (groupBy() === 'productLine') {
      @for (productLine of groupedData() | keyvalue: sortGroups; track productLine.key) {
        <div class="abs-subheader tw-sticky tw-top-0 tw-z-10 tw-mb-1 tw-mt-3 tw-flex tw-justify-between tw-bg-white tw-p-0.5 tw-px-1 tw-font-medium">
          <span>
            {{ productLine.key !== 'null' ? productLine.key : 'None' }}
          </span>

          @if (productLine.value.missingReturns) {
            <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing returns in ABSolute" />
          } @else {
            <span class="tw-font-medium tw-text-black">
              {{ productLine.value.total | currency }}{{ productLine.value.hasEstimates ? '*' : '' }}
            </span>
          }
        </div>

        <!-- Portfolios -->
        @for (portfolio of productLine.value.portfolios; track portfolio.idtPortfolio) {
          <app-portfolio-allocation-item [portfolio]="portfolio" [idtPortfolio]="selectedPortfolio?.idtPortfolio" (selectEmitter)="select($event)" />
        }
      }
    } @else {
      @for (portfolio of portfolios(); track portfolio.idtPortfolio) {
        <app-portfolio-allocation-item
          [portfolio]="portfolio"
          [idtPortfolio]="selectedPortfolio?.idtPortfolio"
          (selectEmitter)="select($event)"
          [ungrouped]="true"
        />
      }
    }
  }
</app-custom-list>

<mat-menu #groupingMenu>
  @if (groupBy() != null) {
    <button mat-menu-item (click)="changeGroupBy(null)">Ungroup</button>
  }
  @if (groupBy() !== 'productLine') {
    <button mat-menu-item (click)="changeGroupBy('productLine')">Group by product line</button>
  }
</mat-menu>
