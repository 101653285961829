<div class="abs-padding tw-flex tw-flex-col tw-@container" style="padding-right: 0" [ngClass]="{ inactive: account.inactive }">
  <div class="tw-flex">
    @if (groupBy === 'contactName' || !isConsultant) {
      <app-portfolio-context-menu class="abs-bold tw-flex tw-flex-1" [displayText]="account.portfolioTicker" [id]="account.idtPortfolio" />
    } @else {
      <app-contact-context-menu class="abs-bold tw-flex tw-flex-1" [displayText]="account.contactName" [id]="account.idtContact" />
    }
  </div>

  @if (account.missingEstimate) {
    <app-alert-box [showIcon]="false" class="abs-margin-bottom">Missing estimates in ABSolute</app-alert-box>
  }

  <div class="tw-flex tw-flex-wrap tw-items-end tw-justify-start">
    <div class="account-name abs-color-faded abs-text-overflow-ellipsis">
      <app-account-balance-info-tooltip [data]="account" />

      @if (account.ocio) {
        <mat-chip class="mini abs-margin-left">OCIO</mat-chip>
      }
    </div>

    <div class="data tw-flex tw-items-end tw-justify-start">
      <div class="mat-caption date tw-flex tw-flex-col">
        @if (account.inactive) {
          <div>Inactive since&nbsp;</div>
        }
        <div>{{ (account.inactive ? account.lastReportingMonth : account.referenceDate) | date: 'MM/dd/yy' }}</div>
      </div>

      <!-- Desktop -->
      <div class="balance abs-align-right tw-hidden @xs:tw-block">
        @if (!account.inactive) {
          <span>{{ account.endBalance | number: '0.0-0' }}</span>
          <span [ngClass]="{ 'abs-hidden': !account.endBalance || !account.estimated }">*</span>
        }
      </div>

      <!-- Mobile -->
      @if (!account.inactive) {
        <div class="balance abs-align-right @xs:tw-hidden">
          <span>{{ account.endBalance | nearUnitNumber: '0.0-0' }}</span>
          <span [ngClass]="{ 'abs-hidden': !account.endBalance || !account.estimated }">*</span>
        </div>
      }

      <app-performance-value class="performance-value tw-flex-1" [value]="account.mtd" [estimated]="account.estimated" [showType]="false" />
      <app-performance-value class="performance-value tw-flex-1" [value]="account.qtd" [estimated]="account.estimated" [showType]="false" />
      <app-performance-value class="performance-value tw-flex-1" [value]="account.ytd" [estimated]="account.estimated" [showType]="false" />
      <app-contact-balance-itd class="performance-value tw-flex-1" [account]="account" [estimated]="account.estimated" />
      <app-contact-balance-itd class="performance-value tw-flex-1" [account]="account" [estimated]="account.estimated" [cumulative]="true" />

      <div (click)="toggleShowIndex()" class="abs-clickable tw-flex tw-max-w-[18px] tw-flex-1 tw-items-end tw-justify-end" matRipple>
        <mat-icon [fontIcon]="showIndex() ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
      </div>
    </div>
  </div>

  <!-- Index data, hidden until expanded -->
  @if (showIndex()) {
    @if (indexData(); as indexData) {
      <div class="tw-flex">
        <div class="blue-data abs-align-right abs-text-overflow-ellipsis tw-hidden tw-flex-1 @xs:tw-flex" [matTooltip]="indexData.name">
          {{ indexData.name }}
        </div>
        <div class="index-data abs-align-right abs-text-overflow-ellipsis tw-flex">
          <div class="blue-data abs-align-right abs-text-overflow-ellipsis index-name tw-flex-1 @xs:tw-hidden" [matTooltip]="indexData.name">
            {{ indexData.name }}
          </div>
          <div class="performance-value tw-flex-1">
            <span class="blue-data">{{ (indexData.mtd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            <span class="blue-data">{{ (indexData.qtd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            <span class="blue-data">{{ (indexData.ytd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            <span class="blue-data">{{ (indexData.itd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            <span class="blue-data">{{ (indexData.itdCumulative | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[18px]"></div>
        </div>
      </div>
      <div class="tw-flex">
        <div class="abs-align-right abs-text-overflow-ellipsis tw-hidden tw-flex-1 @xs:tw-flex">Excess</div>
        <div class="index-data abs-align-right tw-flex">
          <div class="abs-align-right abs-text-overflow-ellipsis index-name tw-flex-1 @xs:tw-hidden">Excess</div>
          <div class="performance-value tw-flex-1">
            @if (account.mtd != null && indexData.mtd != null) {
              <span>{{ account.mtd - indexData.mtd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            @if (account.qtd != null && indexData.qtd != null) {
              <span>{{ account.qtd - indexData.qtd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            @if (account.ytd != null && indexData.ytd != null) {
              <span>{{ account.ytd - indexData.ytd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            @if (account.itd != null && indexData.itd != null) {
              <span>{{ account.itd - indexData.itd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="performance-value tw-flex-1">
            @if (account.itdCumulative != null && indexData.itdCumulative != null) {
              <span>{{ account.itdCumulative - indexData.itdCumulative | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[18px]"></div>
        </div>
      </div>
    }
  }
</div>

<mat-divider />
