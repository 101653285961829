import { ChangeDetectionStrategy, Component, input } from '@angular/core';

/**
 * Component for showing alert-box messages.
 */
@Component({
    selector: 'app-alert-box',
    templateUrl: './alert-box.component.html',
    styleUrls: ['./alert-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AlertBoxComponent {
  /**
   * Whether to show the alert icon.
   */
  showIcon = input<boolean>(true);

  /**
   * Whether to use normal or double padding for bigger highlight.
   */

  spacing = input<'normal' | 'double'>('normal');
}
