import { NgIfContext } from '@angular/common';
import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../business/auth/services/auth.service';

/**
 * Directive to show/hide elements based on permissions.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]',
    standalone: false
})
export class HasPermissionDirective<T = unknown> {
  private permission!: string;
  private isHidden = true;

  private elseTemplateRef: TemplateRef<any> | null = null;
  private elseViewRef: EmbeddedViewRef<any> | null = null;

  constructor(private auth: AuthService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  /**
   * The permission to check. On changes, verify again and rerender if necessary.
   */
  @Input()
  set hasPermission(val: string) {
    this.permission = val;
    this.updateView();
  }

  /**
   * Sets the template to be rendered in case the user doesn't have the permission.
   */
  @Input()
  set hasPermissionElse(templateRef: TemplateRef<NgIfContext<T>> | null) {
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;
    this.updateView();
  }

  /**
   * Check if the user has the permission and rerender if necessary.
   */
  private updateView(): void {
    if (this.auth.hasPermission(this.permission)) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else if (this.elseTemplateRef) {
      this.viewContainer.clear();

      if (!this.elseViewRef) {
        this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }

      this.isHidden = true;
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }
}
