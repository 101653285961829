import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { distinctUntilChanged, tap } from 'rxjs';
import { User } from '../user.model';
import { UserService } from '../user.service';

/**
 * Component to display a select input for users.
 */
@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrl: './user-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserSelectComponent implements OnInit {
  /**
   * The form control for the element.
   */
  control = input.required<FormControl<number | null>>();

  /**
   * The label to show in the select.
   */
  label = input.required<string>();

  /**
   * List of filtered users.
   */
  filteredUsers = signal<User[]>([]);

  /**
   * The search control for the search input field.
   */
  userSelectSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * All possible Users.
   */
  allUsers: User[] = [];

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private fgd?: FormGroupDirective,
    private destroyRef?: DestroyRef,
  ) {}

  ngOnInit() {
    // Make sure this component is change detected after submitting the parent form (if there is one)
    this.fgd?.ngSubmit.subscribe(() => {
      this.cdr.markForCheck();
    });

    this.userService
      .getEmployees()
      .pipe(
        tap((users) => {
          this.allUsers = users;
          this.filteredUsers.set(this.allUsers);
        }),
      )
      .subscribe(() => {
        this.userSelectSearchControl.valueChanges
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            distinctUntilChanged(),
            tap((search: string) => {
              if (search.length === 0) {
                this.filteredUsers.set(this.allUsers);
              } else if (this.allUsers) {
                this.filteredUsers.set(
                  this.allUsers.filter(
                    (u) =>
                      u.initials.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                      u.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
                  ),
                );
              }
            }),
          )
          .subscribe();
      });
  }
}
