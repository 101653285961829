import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { asapScheduler } from 'rxjs';

@Component({
    selector: 'app-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss'],
    standalone: false
})
export class SearchFieldComponent implements OnInit {
  /**
   * Element reference the search input.
   */
  @ViewChild('searchField', { static: true })
  searchElement?: ElementRef;

  /**
   * The input form control.
   */
  @Input({ required: true })
  control!: FormControl<string>;

  /**
   * Wheter to focus on this input when it is rendered.
   */
  @Input()
  withInitialFocus = true;

  ngOnInit(): void {
    if (this.withInitialFocus) {
      asapScheduler.schedule(() => {
        this.searchElement?.nativeElement.focus();
      });
    }
  }
}
