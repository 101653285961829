import { ChangeDetectionStrategy, Component, computed, DestroyRef, EventEmitter, input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, tap } from 'rxjs';
import { ContactViewTO } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';

/**
 * Component for a contact email field that validates if email is duplicated. Also checks if last message sent to this email failed for some reason.
 */
@Component({
    selector: 'app-contact-email-field',
    templateUrl: './contact-email-field.component.html',
    styleUrl: './contact-email-field.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactEmailFieldComponent implements OnInit {
  /**
   * The field's form control.
   */
  control = input.required<FormControl<string>>();

  /**
   * The contact id to ignore from the search of duplicated contacts.
   */
  idtContact = input<number | null>();

  /**
   * The contacts that have the same email as the typed email.
   */
  contactsWithSameEmail = signal<ContactViewTO[]>([]);

  /**
   * Whether the email is duplicated, computed from the list of contacts with the same email.
   */
  duplicatedEmail = computed(() => {
    return this.contactsWithSameEmail().length > 0;
  });

  /**
   * Whether searching for duplicated emails.
   */
  searchingDuplicatedEmails = signal(false);

  /**
   * The searched email.
   */
  searchedEmail = signal('');

  /**
   * Output to return error messages for failing emails.
   */
  @Output()
  errorChanged = new EventEmitter<string>();

  /**
   * Output to emit when the duplicated email search is occuring.
   */
  @Output()
  searchingDuplicatedEmailsChanged = new EventEmitter<boolean>();

  constructor(
    private contactService: ContactService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.configureDuplicatedEmailSearch();

    if (this.control().value) {
      this.searchedEmail.set(this.control().value);
      this.searchDuplicatedEmails(this.control().value);
    }
  }

  /**
   * Configure email field to search for contacts with the same email.
   */
  private configureDuplicatedEmailSearch() {
    this.control()
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.control().valid),
        tap(() => {
          // Mark as loading, event though the request is still not made, to clear the current icon, whether it is valid or invalid icon and replace it with the loading indicator
          this.searchingDuplicatedEmails.set(true);
          this.searchingDuplicatedEmailsChanged.emit(true);
        }),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((email) => {
        this.searchedEmail.set(email);
        this.searchDuplicatedEmails(email);
      });
  }

  /**
   * Search for all contacts using the provided email address, store result in to variable.
   *
   * @param email the email to search for
   */
  private searchDuplicatedEmails(email: string) {
    this.contactService
      .findByAnyEmail(email, this.idtContact())
      .pipe(
        finalize(() => {
          this.searchingDuplicatedEmails.set(false);
          this.searchingDuplicatedEmailsChanged.emit(false);
        }),
      )
      .subscribe((contacts) => {
        this.contactsWithSameEmail.set(contacts);
      });
  }
}
