import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { AccountBalanceTO } from '../../../client/model/client-balance.model';

interface GroupedAccounts {
  accounts: AccountBalanceTO[];
  balance: number;
  hasEstimates: boolean;
}

/**
 * Grouping options.
 */
export enum AccountBalanceGroupBy {
  portfolioTicker = 'portfolioTicker',
  contactName = 'contactName',
}

/**
 * Components to list the balances grouped by accounts
 */
@Component({
    selector: 'app-contact-account-balance-list',
    templateUrl: './contact-account-balance-list.component.html',
    styleUrls: ['./contact-account-balance-list.component.scss'],
    standalone: false
})
export class ContactAccountBalanceListComponent implements OnChanges {
  /**
   * The list of balances
   */
  accounts: AccountBalanceTO[] = [];

  /**
   * The accounts when grouped.
   */
  groupedAccounts: Record<string, GroupedAccounts> = {};

  /**
   * Sets the balances formated from client balance.
   */
  @Input({ required: true })
  balanceData!: AccountBalanceTO[];

  /**
   * How to group results.
   */
  @Input()
  groupBy?: AccountBalanceGroupBy;

  /**
   * Output to allow two way binding to the groupBy property.
   */
  @Output()
  groupByChange = new EventEmitter<AccountBalanceGroupBy | undefined>();

  @Input({ required: true })
  refDate!: DateTime;

  /**
   * Whether the contact is a consultant.
   */
  @Input()
  isConsultant = false;

  /**
   * Whether any of the displayed accounts has estimates for performance numbers.
   */
  hasEstimates: boolean = false;

  /**
   * Reference to the accordion.
   */
  @ViewChild('accordion', { static: false })
  accordion?: MatAccordion;

  /**
   * The sort property and direction.
   */
  sort?: Sort;

  constructor(
    private trackerService: TrackerService,
    private router: Router,
  ) {}

  ngOnChanges(): void {
    this.hasEstimates = this.balanceData.some((a) => a.estimated);

    if (this.groupBy) {
      const groupedAccounts = groupBy(this.balanceData, (c) => c[this.groupBy!]);

      this.groupedAccounts = {};

      for (const [key, value] of Object.entries(groupedAccounts)) {
        this.groupedAccounts[key] = {
          balance: value.reduce((total, b) => b.endBalance + total, 0),
          accounts: value,
          hasEstimates: value.some((b) => b.estimated),
        };
      }

      // If there is only one group, expand it.
      if (Object.keys(this.groupedAccounts).length === 1) {
        // Wait for the accordion to be rendered
        setTimeout(() => {
          this.accordion?.openAll();
        });
      }
    } else {
      this.accounts = [...this.balanceData];
      this.sortAccounts();
    }
  }

  /**
   * Open the details of an account in the sidebar.
   * @param classes the clicked portfolio class
   */
  openAccountDetails(classes: AccountBalanceTO): void {
    this.trackerService.event('contact', 'open_account_details', { account: classes.accountName });
    this.router.navigate(['', { outlets: { sidenav: ['account', classes.idtAccount] } }]);
  }

  /**
   * Sort displayed groups of accounts by the invested amount.
   *
   * @param a a group of accounts
   * @param b a group of accounts to compare to
   * @returns a negative value if the first group has a larger invested amount, zero if equal, and positive otherwise
   */
  sortGroups(a: KeyValue<string, GroupedAccounts>, b: KeyValue<string, GroupedAccounts>): number {
    return b.value.balance - a.value.balance;
  }

  /**
   * Sort accounts to be presented. Nulls always at the end
   */
  sortAccounts(): void {
    if (!this.sort) {
      this.accounts.sort((current, last) => last.endBalance - current.endBalance);
    } else {
      this.accounts.sort((current, last) => {
        let currentValue = current[this.sort!.active as keyof AccountBalanceTO] as number | string;
        const lastValue = last[this.sort!.active as keyof AccountBalanceTO] as number | string;

        // Nulls always at the end
        if (currentValue == null) {
          return 1;
        }

        if (lastValue == null) {
          return -1;
        }

        // If a string property
        if (typeof lastValue === 'string') {
          currentValue = currentValue as string;

          if (this.sort?.direction === 'asc') {
            return currentValue.localeCompare(lastValue);
          }

          return lastValue.localeCompare(currentValue as string);
        }

        // Otherwise it is a number property
        if (this.sort?.direction === 'desc') {
          return (currentValue as number) - lastValue;
        }

        return lastValue - (currentValue as number);
      });
    }
  }

  /**
   * Verifies if the provided group of accounts has missing estimates.
   *
   * @param groupedAccounts the accounts group
   * @returns true, if there are missing estimates in the group
   */
  isMissingEstimates(groupedAccounts: GroupedAccounts): boolean {
    return groupedAccounts.accounts.some((a) => a.missingEstimate);
  }

  trackAccount(index: number, item: AccountBalanceTO): number {
    return item.idtAccount;
  }

  /**
   * Handle sort changes.
   *
   * @param sort the new sort property and direction
   */
  sortChange(sort: Sort): void {
    this.trackerService.event('connected_accounts', 'sort', { property: sort.active, direction: sort.direction });
    this.groupBy = undefined;
    this.groupByChange.emit(this.groupBy);

    this.sort = sort;
    this.sortAccounts();
  }
}
