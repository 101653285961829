import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  effect,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, finalize, mergeMap, of, tap, throwError } from 'rxjs';
import { ContactIndexedDTO, ContactTypeEnum, ContactViewTO } from '../../../model/contact.model';
import { ContactService } from '../../../services/contact.service';
import { ContactNewBasicData } from '../contact-new-basic-data-form/contact-new-basic-data-form.component';
import { ContactNewComponent } from '../contact-new/contact-new.component';

/**
 * Component to associating a new person contact to a company.
 */
@Component({
    selector: 'app-contact-new-associate-employees',
    templateUrl: './contact-new-associate-employees.component.html',
    styleUrl: './contact-new-associate-employees.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactNewAssociateEmployeesComponent implements OnInit {
  ContactTypeEnum = ContactTypeEnum;

  /**
   * The original contact id to be associated with this company.
   */
  idtContact = input.required<number>();

  /**
   * The selected company.
   */
  selectedEmployees = signal<(ContactIndexedDTO | ContactViewTO)[]>([]);

  /**
   * Emits true, when the server is processing a request.
   */
  @Output()
  processing = new EventEmitter<boolean>();

  /**
   * Wheter currently selecting employees.
   */
  selecting = signal(false);

  /**
   * Effect to clear input after selecting an option.
   */
  private selectingEffect = effect(() => {
    if (this.selecting()) {
      // Focus on the search input
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      });
    } else {
      this.searchField.setValue('');
    }
  });

  /**
   * The contact lists to be displayed as options to avoid creating duplicated ones.
   */
  contactData$?: Observable<ContactIndexedDTO[]>;

  /**
   * Field used to search for conctact
   */
  searchField = new FormControl<string>('', { nonNullable: true });

  /**
   * Reference to the search input element.
   */
  @ViewChild('searchInput', { static: false })
  searchInput?: ElementRef;

  constructor(
    private contactService: ContactService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<ContactNewComponent>,
    private destroyRef: DestroyRef,
    private trackerService: TrackerService,
  ) {}

  /**
   * On component initialization see if the current page is a contact, and get the company
   */
  ngOnInit(): void {
    this.setupSearch();
  }

  /**
   * Configure contacts search.
   */
  private setupSearch(): void {
    this.contactData$ = this.searchField.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => {
        this.processing.emit(true);
      }),
      mergeMap((term) => {
        if (term.length > 2) {
          this.trackerService.event('contact_new', 'associate_employees_search_existing', { term });
          return this.contactService.searchIndexedUnassociatedContacts(term);
        }

        return of([]);
      }),
      tap(() => {
        this.processing.emit(false);
      }),
    );
  }

  /**
   * Select a company as the contact's company.
   *
   * @param contact the company contact to select
   */
  selectContact(contact: ContactIndexedDTO | ContactViewTO): void {
    // Avoid duplicated selection
    if (!this.selectedEmployees().some((e) => e.idtContact === contact.idtContact)) {
      this.selectedEmployees.update((v) => [contact, ...v]);
    }

    this.selecting.set(false);
    this.trackerService.event('contact_new', 'associate_employees_select_contact');
  }

  /**
   * Create a new company and select it as the created person's company.
   *
   * @param data the new company data
   */
  createNewContact(data: ContactNewBasicData): void {
    this.processing.emit(true);

    this.contactService
      .create({
        type: ContactTypeEnum.PERSON,
        ...data,
      })
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error creating contact');

          return throwError(() => err);
        }),
        finalize(() => {
          this.processing.emit(false);
        }),
      )
      .subscribe((contact) => {
        this.selectContact(contact);
        this.trackerService.event('contact_new', 'associate_employees_create_new');
      });
  }

  /**
   * Remove a single contact from the list of selected employees.
   *
   * @param idtContact the contact id
   */
  removeContact(idtContact: number): void {
    this.selectedEmployees.update((v) => v.filter((vv) => vv.idtContact !== idtContact));
    this.trackerService.event('contact_new', 'associate_employees_remove_selected');
  }

  /**
   * After completing the process, close the dialog an navigate to contact's page and edition sidenav.
   */
  end(): void {
    this.dialogRef.close({
      idtContact: this.idtContact(),
      new: true,
    });
  }

  /**
   * Save the association.
   */
  save(): void {
    if (this.selectedEmployees().length > 0) {
      this.processing.emit(true);

      this.contactService
        .saveCompanyEmployees(
          this.idtContact(),
          this.selectedEmployees().map((e) => e.idtContact),
        )
        .pipe(
          catchError((err) => {
            this.dialogService.showError(err.error?.message || 'Error saving contacts');

            return throwError(() => err);
          }),
          finalize(() => {
            this.processing.emit(false);
          }),
        )
        .subscribe(() => {
          this.trackerService.event('contact_new', 'associate_employees_save');
          this.end();
        });
    } else {
      this.end();
    }
  }
}
