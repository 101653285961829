/**
 * Fees and flows, global configuration data.
 */
export interface FeesAndFlowsGlobalConfigurationTO {
  idtFeesAndFlowGlobal: number;
  year: number;
  firstYearPrimaryPercentage: number;
  firstYearBackupPercentage: number;
  firstYearMaintenancePercentage: number;
  secondYearPrimaryPercentage: number;
  secondYearBackupPercentage: number;
  secondYearMaintenancePercentage: number;
  thirdYearPrimaryPercentage: number;
  thirdYearBackupPercentage: number;
  thirdYearMaintenancePercentage: number;
}

/**
 * Fees and flows summary for an employee.
 */
export interface FeesAndFlowSummaryViewTO {
  idtUser: number;
  initials: string;
  name: string;
  year: number;
  aumPrimary: number;
  aumBackup: number;
  aumMaintenance: number;
  aumTotal: number;
  estimatedAumPrimary: boolean;
  estimatedAumBackup: boolean;
  estimatedAumMaintenance: boolean;
  estimatedAumTotal: boolean;
  chargedFeesPrimary: number;
  chargedFeesBackup: number;
  chargedFeesMaintenance: number;
  chargedFeesTotal: number;
  inflowsPrimary: number;
  inflowsBackup: number;
  inflowsMaintenance: number;
  inflowsTotal: number;
  outflowsPrimary: number;
  outflowsBackup: number;
  outflowsMaintenance: number;
  outflowsTotal: number;
  feeSharePrimary: number;
  feeShareBackup: number;
  feeShareMaintenance: number;
  feeShareTotal: number;
  feeSharePercentPrimary: number;
  feeSharePercentBackup: number;
  feeSharePercentMaintenance: number;
  feeSharePercentTotal: number;
}

/**
 * Role options for employees.
 */
export enum FeesAndFlowsRoleEnum {
  PRIMARY = 'PRIMARY',
  PRIMARY_2 = 'PRIMARY_2',
  BACKUP = 'BACKUP',
  MAINTENANCE = 'MAINTENANCE',
}

/**
 * Model for the data sent from the server when loading fees and flows for accounts.
 */
export interface FeesAndFlowsAccountViewTO {
  year: number;
  idtAccount: number;
  idtPortfolio: number;
  portfolio: string;
  account: string;
  idtContact: number;
  contact: string;
  idtConsultantCompany: number;
  consultantCompany: string;
  aum: number;
  estimated: boolean;
  inflows: number;
  outflows: number;
  managementFee: number;
  chargedManagementFee: number;
  configured: boolean;
}

/**
 * Model for the data sent from the server when loading fees and flows for accounts with employee data.
 */
export interface FeesAndFlowsOverridePerEmployeeViewTO extends FeesAndFlowsAccountViewTO {
  idtUser: number;
  initials: string;
  name: string;
  role: FeesAndFlowsRoleEnum;
  feeShare: number;
  feeSharePercent: number;
}

/**
 * Data format for saving override changes.
 */
export interface FeesAndFlowsOverrideInputDTO {
  year: number;
  idtContact: number;
  idtPortfolio: number;
  idtUserPrimary?: number;
  idtUserPrimary2?: number;
  idtUserBackup?: number;
  idtUserMaintenance?: number;
  primaryPercent?: number;
  primary2Percent?: number;
  backupPercent?: number;
  primaryOverrideFirstYear?: number;
  primaryOverrideSecondYear?: number;
  primaryOverrideThirdYear?: number;
  comments?: string;
}

/**
 * Contact override data.
 */
export interface FeesAndFlowsOverrideTO {
  year: number;
  idtContact: number;
  idtPortfolio: number;
  primaryPercent: number;
  primary2Percent: number;
  backupPercent: number;
  primaryOverrideFirstYear: number;
  primaryOverrideSecondYear: number;
  primaryOverrideThirdYear: number;
  comments: string;
}

/**
 * Contact fees and flows assigned employees.
 */
export interface FeesAndFlowsEmployeeTO {
  year: number;
  idtContact: number;
  idtPortfolio: number;
  idtUser: number;
  role: FeesAndFlowsRoleEnum;
}

export interface FeesAndFlowsAnnualByPortfolioViewTO {
  idtPortfolio: number;
  portfolio: string;
  inflows: number;
  outflows: number;
  year: number;
  netFlow: number;
}

export interface FeesAndFlowsAnnualByMonthViewTO {
  date: string;
  inflows: number;
  outflows: number;
  year: number;
  netFlow: number;
}
