import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-custom-list',
    templateUrl: './custom-list.component.html',
    styleUrls: ['./custom-list.component.scss'],
    standalone: false
})
export class CustomListComponent {
  /**
   * Whether the list should add select actions.
   */
  @Input()
  selectable = true;

  /**
   * Which style should be applied to the list.
   */

  @Input()
  mode: 'normal' | 'dense' | 'table' = 'normal';

  /**
   * Add selectable class to host element when configured.
   */
  @HostBinding('class.selectable')
  get allowSelection() {
    return this.selectable;
  }

  /**
   * Add dense class to host element when configured.
   */
  @HostBinding('class.dense')
  get isDense() {
    return this.mode === 'dense';
  }

  /**
   * Add table class to host element when configured.
   */
  @HostBinding('class.table')
  get isTable() {
    return this.mode === 'table';
  }
}
