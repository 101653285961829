import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactConsultantWithPortfolios } from 'app/modules/common/business/client/model/client-balance.model';
import { ConsultantUsageDTO } from 'app/modules/common/business/consultant/model/consultant.model';
import { ConsultantService } from 'app/modules/common/business/consultant/services/consultant.service';
import { PortfolioTO } from 'app/modules/common/business/portfolio/model/portfolio.model';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { ContactIndexedDTO, ContactTypeEnum, ContactViewTO } from '../../../model/contact.model';
import { ContactService } from '../../../services/contact.service';

/**
 * The form group definition.
 */
export type ConsultantForm = FormGroup<{
  consultant: FormControl<ContactViewTO | ContactIndexedDTO | null | undefined>;
  externalRep: FormControl<ContactViewTO | ContactIndexedDTO | null | undefined>;
  idtPortfolios: FormControl<number[]>;
  backupConsultant: FormControl<ContactViewTO | ContactIndexedDTO | null | undefined>;
}>;

/**
 * Component for the form to add or update a single contact to consultant relationship.
 */
@Component({
    selector: 'app-contact-consultant-form',
    templateUrl: './contact-consultant-form.component.html',
    styleUrls: ['./contact-consultant-form.component.scss'],
    standalone: false
})
export class ContactConsultantFormComponent implements OnInit, AfterViewInit {
  /**
   * The contact social media form
   */
  @Input()
  consultantForm!: ConsultantForm;

  /**
   * The index of form array.
   */
  @Input()
  index!: number;

  /**
   * The id of the contact being edited.
   */
  @Input()
  idtContact!: number;

  /**
   * Emits the remove event to delete a social media form from array.
   */
  @Output()
  remove = new EventEmitter<number>();

  /**
   * Form control for the filter input for searching for portfolios.
   */
  searchPortfolioControl = new FormControl<string>('', { nonNullable: true });

  /**
   * Filtered protfolios.
   */
  portfolios$?: Observable<PortfolioTO[]>;

  /**
   * All available portfolios.
   */
  allPortfolios: PortfolioTO[] = [];

  /**
   * The consultant usage counts wrapped in an observable.
   */
  consultantUsage$?: Observable<ConsultantUsageDTO>;

  /**
   * The form control for the consultant company search field.
   */
  companySearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The options to select a consultant company.
   */
  consultantCompanyOptions = signal<ContactIndexedDTO[]>([]);

  /**
   * The form control for the consultant search field.
   */
  consultantSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The options to select a external rep.
   */
  consultantOptions$?: Observable<ContactIndexedDTO[]>;

  /**
   * Form control for the search of backup consultant options.
   */
  backupConsultantSearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * List of backup consultant options.
   */
  backupOptions$?: Observable<ContactIndexedDTO[]>;

  @ViewChild('consultantCompanyInput')
  consultantCompanyInput!: ElementRef<HTMLInputElement>;

  /**
   * Gets the form group of contact social media
   * @param contactSocialMedia the social media of contact to set the fields of form
   * @returns the contact social media form group
   */
  static getForm(consultant?: ContactConsultantWithPortfolios): ConsultantForm {
    return new FormGroup({
      consultant: new FormControl<ContactViewTO | ContactIndexedDTO | undefined>({
        value: consultant?.consultant,
        disabled: !consultant?.externalRep,
      }),
      externalRep: new FormControl<ContactViewTO | ContactIndexedDTO | undefined>(consultant?.externalRep, { validators: [Validators.required] }),
      idtPortfolios: new FormControl<number[]>(consultant?.idtPortfolios || [], { nonNullable: true }),
      backupConsultant: new FormControl<ContactViewTO | ContactIndexedDTO | undefined>({
        value: consultant?.backupConsultant,
        disabled: !consultant?.externalRep,
      }),
    });
  }

  constructor(
    private portfolioService: PortfolioService,
    private consultantService: ConsultantService,
    private destroyRef: DestroyRef,
    private contactService: ContactService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setupPortfolioSearch();

    this.backupOptions$ = this.setupConsultantSearch(this.backupConsultantSearchControl, this.consultantForm.controls.backupConsultant);
    this.consultantOptions$ = this.setupConsultantSearch(this.consultantSearchControl, this.consultantForm.controls.consultant);

    const idtConsultantCompany = this.consultantForm.controls.externalRep.value?.idtContact;

    if (idtConsultantCompany) {
      this.consultantUsage$ = this.consultantService.getAccountsAndOpportunitiesCount(this.idtContact, idtConsultantCompany);
    }
  }

  ngAfterViewInit(): void {
    this.setupCompanySearch();
    this.setupCompanyBehavior();
  }

  /**
   * Emits the event to remove a job form from form array.
   */
  removeForm(): void {
    this.remove.emit(this.index);
  }

  /**
   * Sets up the portfolio autocomplete search mechanism.
   */
  private setupPortfolioSearch(): void {
    // Load all portfolios from the server
    this.portfolioService.findAll('shortName,ASC', new DataFilter().equals('inactive', false, 'boolean')).subscribe((p) => {
      this.allPortfolios = p;

      // Setup the filter to search for portfolios locally
      this.portfolios$ = this.searchPortfolioControl.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map((search: string) => {
          if (search.length === 0) {
            return this.allPortfolios;
          } else if (this.allPortfolios) {
            return this.allPortfolios?.filter((p) => p.shortName.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
          }

          return [];
        }),
        startWith(this.allPortfolios),
      );
    });
  }

  /**
   * Setup search behavior for the consultant company field.
   */
  private setupCompanySearch() {
    fromEvent(this.consultantCompanyInput.nativeElement, 'input')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          return this.contactService
            .searchIndexedContacts(this.consultantCompanyInput?.nativeElement.value, ContactTypeEnum.COMPANY)
            .subscribe((options) => {
              this.consultantCompanyOptions.set(options);
            });
        }),
        tap(() => this.cdr.markForCheck()),
      )
      .subscribe();
  }

  /**
   * Configures the search behavior for the consultant fields.
   * Enables/disables the fields depending if a consultant company is selected.
   *
   * @param searchControl the form control for the search field to configure
   * @param valueControl the form control that holds the selected value
   */
  private setupConsultantSearch(
    searchControl: FormControl<string>,
    valueControl: FormControl<ContactViewTO | ContactIndexedDTO | null | undefined>,
  ): Observable<ContactIndexedDTO[]> {
    // Enable the field when a consultant company is selected, disable and clear otherwise
    this.consultantForm.controls.externalRep.valueChanges.subscribe((v) => {
      valueControl.setValue(undefined);

      if (v) {
        valueControl.enable();
      } else {
        valueControl.disable();
      }
    });

    return searchControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      debounceTime(500),
      mergeMap((search) => {
        const selectedCompany = this.consultantForm.controls.externalRep.value!;

        if (search.length < 2) {
          return of([]);
        } else {
          return this.contactService.searchIndexedContactsFromCompany(search, selectedCompany.idtParentCompany ?? selectedCompany.idtCompany);
        }
      }),
    );
  }

  /**
   * Configure behavior to se the company as the main consultant company.
   */
  private setupCompanyBehavior() {
    this.consultantForm.controls.consultant.valueChanges.subscribe((consultant) => {
      if (consultant) {
        this.contactService.getInfoById(consultant.idtCompanyContact!).subscribe((cons) => {
          this.consultantForm.controls.externalRep.setValue(cons, { emitEvent: false });
        });
      }
    });
  }

  /**
   * Compares equality for externalRep conttacts.
   */
  contactCompare(c1: ContactIndexedDTO, c2: ContactIndexedDTO): boolean {
    return !!c1 && !!c2 ? c1.idtContact === c2.idtContact : c1 === c2;
  }

  /**
   * Get the proper display value for companies.
   *
   * @param c the contact object
   * @returns the name of the contact or empty string
   */
  displayCompany(c: ContactIndexedDTO | null): string {
    return c?.name ?? '';
  }
}
