import { KeyValue } from '@angular/common';

/**
 * Interface portfolios should have in order to be sorted by product line.
 */
interface PortfolioWithProductLine {
  productLine?: string;
  productLineOrder?: number;
}

/**
 * Comparator function to be used when sorting portfolios grouped by product line.
 *
 * @param a key value object where the key is the product line and the object is a list of portfolios
 * @param b another key value object where the key is the product line and the object is a list of portfolios
 * @returns number indicating which object should be first
 */
export function compareProductLine(a: KeyValue<string, PortfolioWithProductLine[]>, b: KeyValue<string, PortfolioWithProductLine[]>) {
  // Notice the key will always be a string, so we must compare to string 'null'
  if (a.key === 'null') {
    return 1;
  }

  if (b.key === 'null') {
    return -1;
  }

  return a.value[0].productLineOrder! - b.value[0].productLineOrder!;
}
