import { Component, Inject, signal } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { tap } from 'rxjs/operators';
import { ContactSocialMedia, ContactSocialMediaTypeEnum } from '../../model/contact-social-media.model';
import { ContactService } from '../../services/contact.service';

/**
 * Component for editing a contact's social media data.
 */
@Component({
    selector: 'app-contact-social-edit',
    templateUrl: './contact-social-edit.component.html',
    styleUrls: ['./contact-social-edit.component.scss'],
    standalone: false
})
export class ContactSocialEditComponent {
  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    website: new FormControl<string>('', { nonNullable: true }),
    linkedin: new FormControl<string>('', { nonNullable: true }),
  });

  private socialNetworks?: ContactSocialMedia[];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number },
    private dialogRef: MatDialogRef<ContactSocialEditComponent>,
    private observableUtils: ObservableUtilsService,
  ) {
    this.idtContact = data.idtContact;
    this.getSocialMedias();
  }

  /**
   * Get the social media data from the server and build the formArray accordingly.
   */
  getSocialMedias(): void {
    if (this.idtContact) {
      this.contactService.getSocialMedia(this.idtContact).subscribe((socialNetworks) => {
        this.socialNetworks = socialNetworks;

        this.formGroup.controls.linkedin.setValue(this.socialNetworks.find((s) => s.type === ContactSocialMediaTypeEnum.LINKEDIN)?.value || '');
        this.formGroup.controls.website.setValue(this.socialNetworks.find((s) => s.type === ContactSocialMediaTypeEnum.WEBSITE)?.value || '');
      });
    }
  }

  /**
   * Get a ingle social media data from the list of already saved jobs.
   *
   * @param index the index of the entry in the list
   * @returns the entry found or null
   */
  getSocialMedia(index: number): ContactSocialMedia | null {
    if (this.socialNetworks) {
      return this.socialNetworks[index];
    }

    return null;
  }

  /**
   * Saves
   */
  save(): void {
    if (!this.saving() && this.validate()) {
      this.contactService
        .saveSocialMedia(this.idtContact, this.formGroup.getRawValue())
        .pipe(
          trackLoading(this.saving),
          this.observableUtils.catchErrorAndNotify("Couldn't save the contact social media"),
          tap(() => {
            this.dialogService.showSuccess('Contact social media saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }
}
