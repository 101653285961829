import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactTypeEnum } from '../../model/contact.model';

/**
 * Dialog input data object.
 */
interface DialogData {
  idtContact: number;
  name?: string;
  type?: ContactTypeEnum;
}

@Component({
    selector: 'app-contact-search-dialog',
    templateUrl: './contact-search-dialog.component.html',
    styleUrl: './contact-search-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactSearchDialogComponent {
  /**
   * Whether loading/processing data.
   */
  loading = signal(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
